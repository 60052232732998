<template>
  <LayoutUser>
    <div class="sign-up-view container">
        <div class="form card grid gap-1-5">
          <UiHeadline size="m">Signup</UiHeadline>

          <Form :schema="formSchema" v-model="model" @submit="submit" :errors="errors" :submitting="submitting"></Form>

        <div class="text-center">Have an account already? <router-link class="strong" to="/login">Login</router-link></div>
        </div>
    </div>
  </LayoutUser>
</template>

<script setup>
  import { ref } from "vue"
  import { useStore } from "vuex"
  import { useRouter } from "vue-router"
  import S2 from "@/components/S2"
  import DonationForm from "@/components/DonationForm"
  import { LayoutUser } from "@lib/components"

  const store = useStore()
  const router = useRouter()
  const model = ref({
    email: "",
    password: "",
    repeatPassword: ""
  })
  const submitting = ref(false)
  const errors = ref(null)

  const submit = () => {
    if (submitting.value) return
    submitting.value = true;
    store.dispatch("auth/register", {
      data: {
        username: model.value.username,
        email: model.value.email,
        password: model.value.password,
        repeatPassword: model.value.password,
        agreementCheckbox: model.value.agreementCheckbox
      },
    })
    .then((res) => {
      submitting.value = false;
      router.push('/')
    })
    .catch((err) => {
      errors.value = err.response.data.errors;
      submitting.value = false;
    });
  }

  const formSchema = ref(`{
  "rows": [
    {
      "columns": [
        {
          "fields": [
            {
              "type": "text",
              "label": "Username",
              "name": "username",
              "placeholder": "",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "email",
              "label": "E-Mail address",
              "name": "email",
              "placeholder": "your@email.com",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "password",
              "label": "Password",
              "name": "password",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "checkbox",
              "label": "view.login.signUp.label",
              "name": "agreementCheckbox",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "submit",
              "label": "Sign Up"
            }
          ]
        }
      ]
    }
  ]
}
`);
</script>

<style scoped>
  .sign-up-view {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 64px;
  }

  .sign-up-view .form {
    width: 100%;
    max-width: 500px;
    margin: auto;
  }

  .sign-up-view .submit {
    margin-top: 24px;
  }
</style>