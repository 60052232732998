<template>
	<div :class="classes">
      <slot></slot>
    </div>
</template>

<script setup>
	import { computed, inject } from "vue"

	const props = defineProps({
		type: {
			type: String,
			default: "success"
		},
		size: {
			type: String,
			default: "m"
		},
	})

	const classes = computed(() => [
		"ui-badge",
		`ui-badge-${props.type}`,
		`ui-badge-${props.size}`
	])

	const t = inject('$t');
</script>

<style setup>
	.ui-badge {
		width: fit-content;
		padding: 4px 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		background: #87F57D;
		color: #000;
		font-size: 14px;
	}

	.ui-badge span {
		font-size: 16px;
		font-weight: 500;
		line-height: normal;
		letter-spacing: -.5px;
	}

	.ui-badge-danger {
		background: #FF2C46;
	}

	.ui-badge-success {
		background: #87F57D;
	}

	.ui-badge-primary {
		background: #2652e9;
		color: #fff;
	}

	.ui-badge-white {
		background: #fff;
		color: #000;
	}

	.ui-badge-s {
		height: 24px;
		padding: 0 6px;
	}

	.ui-badge-s span {
		font-size: 14px;
	}

	/*.ui-badge-danger span {
		color: #930e1e;
	}

	.ui-badge-success span {
		color: #218f16;
	}*/
</style>