<template>
	<!-- Back:Mobile -->
	<div class="mobile">
		<UiBack route="/settings" text="Back" fixed></UiBack>
	</div>
		
	<UiCard>
		<!-- Headline -->
		<UiHeadline class="mb-8" size="m">Import New Trading Account</UiHeadline>
		<template v-if="$route.name === 'dashboard_settings_import_account'">
			<Form :schema="form" v-model="model"></Form>

			<UiButton class="mt-8" @click="submit" :loading="submitting">Next</UiButton>
		</template>
		<template v-if="$route.name === 'dashboard_settings_import_account_trade_data'">
			<UiUploadFile v-model="files"></UiUploadFile>
			<Form class="mt-4" :schema="uploadSettingsForm" v-model="uploadSettingsModel"></Form>
			<UiButton class="mt-8" @click="upload" :loading="uploading" v-if="filesAttached">Upload trade data</UiButton>
		</template>
	</UiCard>
</template>

<script setup>
	import { ref, computed, watch } from "vue"
	import { useStore } from "vuex"
	import { useRouter, useRoute } from "vue-router"
	import moment from "moment"

	const store = useStore()
	const router = useRouter()
	const route = useRoute()
	const model = ref({
		broker: "tradovate"
	})
	const uploadSettingsModel = ref({
		timezone: 'America/Chicago'
	})
	const files = ref(new FormData())
	const submitting = ref(false)
	const uploading = ref(false)

	const filesAttached = computed(() => files.value.has('files'))

	const form = ref(`{
	  "rows": [
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "select",
	              "label": "Select Broker",
	              "name": "broker",
	              "placeholder": "",
	              "required": false,
	              "options": [{"value": "tradovate","label": "Tradovate"}]
	            }
	          ]
	        }
	      ]
	    }
	  ]
	}`)

	const availableTimezones = moment.tz.names().map(t => ({
		value: t,
		label: t,
	}))

	const uploadSettingsForm = ref(`{
	  "rows": [
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "select",
	              "label": "Select Timezone",
	              "name": "timezone",
	              "placeholder": "",
	              "required": false,
	              "options": ${JSON.stringify(availableTimezones)}
	            }
	          ]
	        }
	      ]
	    }
	  ]
	}`)

	const submit = () => {
		if (submitting.value) return
		submitting.value = true
		store.dispatch('dashboard/addTradingAccount', {
			...model.value
		}).then(res => {
			submitting.value = false
			router.push(`/settings/import/${res.data.tradingAccount.id}`)
		}).catch(() => {
			submitting.value = false
		})
	}

	const upload = () => {
		if (uploading.value) return
		uploading.value = true
		store.dispatch('dashboard/uploadTradeDataToTradingAccount', {
			timezone: uploadSettingsModel.value.timezone,
			accountId: route.params.accountId,
			files: files.value
		}).then(res => {
			uploading.value = false
			router.push(`/trading/accounts/${route.params.accountId}`)
		}).catch(() => {
			uploading.value = false
		})
	}
</script>