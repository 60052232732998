<template>
	<div class="dontation-form">
		<div class="donate-title">Select a package</div>
      <ul class="donate">
        <li v-for="item in 4">
          <h3>Food support</h3>
          <h4>$99</h4>
        </li>
      </ul>

      <div class="row gap-1">
	      <div class="form-field col-6">
	      	<label>First name</label>
	      	<input>
	      </div>
	      <div class="form-field col-6">
	      	<label>Last name</label>
	      	<input>
	      </div>
      </div>

      <div>
	      <div class="form-field">
	      	<label>E-Mail address</label>
	      	<input placeholder="your@email.com">
	      </div>
      </div>

      <div>
	      <div class="form-field">
	      	<label>Phone</label>
	      	<input>
	      </div>
      </div>

      <div class="btn btn-l btn-full submit">Dontate $99 Now</div>
	</div>
</template>

<style scoped>
	.dontation-form {
		display: grid;
		gap: 16px;
	}

	.dontation-form .donate {
		width: 100%;
		margin-bottom: 32px;
	}

	.dontation-form .submit {
		margin-top: 32px;
	}
</style>