<template>
    <div style="display: flex;justify-content: space-between;align-items: center;">
    	<div>
    		<br>
    		<p>
				Please take a moment to read and sign the Funded Trader Contract. By signing this agreement, you acknowledge and agree to the terms and conditions outlined within. It is important to carefully review the document before proceeding.<br>
				<a :href="account.agreementLink" target="_blank">View Funded Trader Contract</a>
			</p>
			<br>
			<div style="max-width: 512px;">
				<Form :schema="formSchema" v-model="model" @submit="submit" :errors="errors"></Form>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed } from 'vue'
	import { useStore } from 'vuex'
	import { useRoute } from 'vue-router'

	const route = useRoute()
	const store = useStore()

	const model = ref({
		idNumber: "",
		ssn: "",
		signature: "",
		cancelUrl: `https://${window.location.host}/trading/accounts/${route.params.accountId}?activation_fee=canceled`,
		successUrl: `https://${window.location.host}?activation_fee=paid`
	})
	const submitting = ref(false)
  	const errors = ref(null)

  	const account = computed(() => store.getters['dashboard/account'])

	const submit = () => {
	    if (submitting.value) return
	    store.dispatch("dashboard/signTradingAccountContract", {
	    	accountId: route.params.accountId,
	    	data: {
	    		...model.value,
	    	}
	    })
	    .then((res) => {
	      submitting.value = false;
	      window.location = res.data.url;
	    })
	    .catch((err) => {
	      errors.value = err.response.data.errors;
	      submitting.value = false;
	    });
	  }

		const formSchema = ref(`{
		  "rows": [
		    {
		      "columns": [
		        {
		          "fields": [
		            {
		              "type": "text",
		              "label": "State or Passport ID Number",
		              "name": "idNumber",
		              "required": true
		            }
		          ]
		        }
		       ]},
		       {
		      "columns": [
		        {
		          "fields": [
		            {
		              "type": "text",
		              "label": "SSN (for US citizen)",
		              "name": "ssn",
		              "required": true
		            }
		          ]
		        }
		       ]},
		       {
		      "columns": [
		        {
		          "fields": [
		            {
		              "type": "text",
		              "label": "Signature (Full Name)",
		              "name": "signature",
		              "required": true
		            }
		          ]
		        }
		       ]},
		       {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "submit",
	              "label": "Sign Contract"
	            }
	          ]
	        }
	      ]
	    }
	       ]
	   }`
        );
</script>