<template>
  <div>
    <form @submit.prevent>
      <div v-for="(row, rowIndex) in parsedSchema.rows" :key="rowIndex" class="form-row">
        <div v-for="(column, colIndex) in row.columns" :key="colIndex" class="form-column">
          <div v-for="(field, fieldIndex) in column.fields" :key="fieldIndex" class="form-field" :class="`form-field-${field.type}`">
            <template v-if="field.type === 'textarea'">
              <label :for="field.name">{{ field.label }}</label>
              <textarea
                :id="field.name"
                :name="field.name"
                :placeholder="field.placeholder"
                v-model="formData[field.name]"
                :required="field.required"
                :class="field.classes"
              ></textarea>
            </template>
            <template v-else-if="field.type === 'select'">
              <label :for="field.name">{{ field.label }}</label>
              <select
                :id="field.name"
                :name="field.name"
                v-model="formData[field.name]"
                :required="field.required"
                :class="field.classes"
              >
                <option
                  v-for="(option, optionIndex) in field.options"
                  :key="optionIndex"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </template>
            <template v-else-if="field.type === 'submit'">
              <button
                class="btn btn-l btn-full submit"
                :type="field.type"
                :id="field.name"
                :name="field.name"
                :placeholder="field.placeholder"
                :class="field.classes"
              >
              {{ field.label }}
              </button>
            </template>
            <template v-else-if="field.type === 'checkbox'">
              <label :for="field.name">
                <input
                  :type="field.type"
                  :id="field.name"
                  :name="field.name"
                  :placeholder="field.placeholder"
                  v-model="formData[field.name]"
                  :required="field.required"
                  :class="field.classes"
                />
                {{ field.label }}
              </label>
            </template>
            <template v-else>
              <label :for="field.name">{{ field.label }}</label>
              <input
                :type="field.type"
                :id="field.name"
                :name="field.name"
                :placeholder="field.placeholder"
                v-model="formData[field.name]"
                :required="field.required"
                :class="field.classes"
              />
            </template>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, watch, watchEffect, computed } from 'vue';

export default {
  props: {
    schema: String,
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const parsedSchema = computed(() => JSON.parse(props.schema));
    const formData = ref({ ...props.model });
    
    watchEffect(() => {
      emit('update:modelValue', formData.value);
    });

    watch(() => props.model, (newModel) => {
      formData.value = { ...newModel };
    });

    const submitForm = () => {
      emit('submit')
    };

    return {
      parsedSchema,
      formData,
      submitForm
    };
  }
};
</script>

<style>
/* Add your CSS styles for the form elements here */
.form-row {
  margin-bottom: 10px;
}

.form-column {
  flex: 1;
}

.form-field {
  margin-bottom: 10px;
  width: 100%;
}

textarea {
  width: 100%;
  height: 100px; /* You can adjust the height as needed */
}

@media (min-width: 768px) {
  .form-row {
    display: flex;
    gap: 1rem;
  }
}
</style>
