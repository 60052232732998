<template>
	<LayoutUser>
		<div class="trading-desk">
			<UiRow gap="2" style="height: 100%;">
				<!-- Account -->
				<div style="width: 100%;max-width: 256px; height: 100%;">
					<UiGridTile color="white" full grow>
						<UiCol gap="2">
							<router-link :to="`/trading-desk/${desk.id}`" v-for="desk in desks">
								<UiGridTile color="white" full>
									<UiRow align="center" gap="2">
										<UiAvatar :imgUrl="desk.manager.profileImageUrl" size="48px"></UiAvatar>
										<div>
											<div>{{ desk.name }}</div>
											<small><label>10 Members</label></small>
										</div>
									</UiRow>
								</UiGridTile>
							</router-link>
						</UiCol>
					</UiGridTile>
				</div>
				<UiCol gap="2" style="height: 100%;width: 100%;flex: inherit;" v-if="desk && desk.streamUrl">
					<div>
						<div class="video-container">
						  <iframe
						   				:src="desk.streamUrl" 
						          title="YouTube video player" 
						          frameborder="0" 
						          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
						          allowfullscreen>
						  </iframe>
						</div>
					</div>
					<UiRow gap="2" style="height: 100%;max-height: 256px;">
						<UiGridTile full grow>
							<div style="margin-bottom: 8px;">Desk managed by</div>
							<UiRow align="center" gap="2" v-if="desk && desk.manager">
								<UiAvatar :imgUrl="desk.manager.profileImageUrl" size="40px"></UiAvatar>
								<div style="display: flex;gap: 4px;">
									<UiGridTile color="white">
										{{ desk.manager.fullname }}
									</UiGridTile>
									<UiGridTile color="white">
										@{{ desk.manager.username }}
									</UiGridTile>
								</div>
							</UiRow>
							<UiButton size="s" round style="margin-top: 16px;">Become a Member</UiButton>
						</UiGridTile>
						<UiGridTile full grow>
							<div>Description</div>
						</UiGridTile>
					</UiRow>
				</UiCol>
				<div :style="desk && desk.streamUrl ? 'width: 720px;height: 100%;' : 'width: 100%;height: 100%;'">
					<div class="chat-col" full grow v-if="desk">
						<Chat :chat-id="desk.chatId" style="height: calc(100vh - 73px - 67px);"></Chat>
						<ChatMessageBox :chat-id="$store.state.chat.supportChatId" chat-type="support"></ChatMessageBox>
					</div>
				</div>
			</UiRow>
		</div>
		<UiModal title="Join Now" v-if="false && desk.manager">
			<div style="margin-top: 16px;">You need to join {{ desk.manager.firstname }} Trading Desk to continue watching his Room.</div>
			<UiButton full style="margin-top: 32px;">Join Free</UiButton>
		</UiModal>
		<UiModal title="Coming Soon" @close="onCloseComingSoon" v-if="false">
			<div style="margin-top: 32px;">
				<UiHeadline size="m" style="margin-bottom: 16px;">How does our trading desk work?</UiHeadline>
				<p>
					First of all, there is not just one desk, we have more experienced traders (Senior Traders), who manage the corresponding desks. You can choose for yourself which desk you would like to join. There are desks that focus on certain products, and others that apply specific strategies.<br><br>

					<small>Note: Not all desks will be available during our test phase.</small>
				</p>
			</div>
			<!-- <UiButton full style="margin-top: 32px;">Join Free</UiButton> -->
		</UiModal>
	</LayoutUser>
</template>

<script setup>
	import { computed, watch } from "vue"
	import { useStore } from "vuex"
	import { useRouter, useRoute } from "vue-router"
	import { LayoutUser } from "@lib/components"
	import Chat from "@/components/Chat"
	import ChatMessageBox from "@/components/ChatMessageBox"

	const store = useStore()
	const router = useRouter()
	const route = useRoute()

	const desks = computed(() => store.state.dashboard.desks)
	const desk = computed(() => store.getters["dashboard/desk"])

	const onCloseComingSoon = () => {
		router.push({name:"dashboard",query:{origin:"trading_desk_coming_soon"}})
	}

	const fetchDesks = async () => {
		return new Promise(resolve => {
			store.dispatch("dashboard/getDesks").then((res) => {}).catch((err) => {})
		})
	}

	fetchDesks()

	watch(() => route.params.deskId, () => {
		store.dispatch("dashboard/setDesk", route.params.deskId)
	},{
		immediate: true
	})
</script>

<style scoped>
.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.chat-col {
	display: flex;
	flex-flow: column;
	height: inherit;
	border-bottom-left-radius: 16px;
  background: aliceblue;
}

.darkmode .chat-col {
	background: #191b1f;
}

.trading-desk {
	  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding-top: 73px;
}
</style>