<template>
	<div class="container">
		<UiRow>
			<UiSidebar :items="navigationItems" showIndex></UiSidebar>
			<div style="width: 100%;">
				<router-view></router-view>
			</div>
		</UiRow>
	</div>
</template>

<script setup>
	import { ref, getCurrentInstance } from "vue"
	import { useStore } from "vuex"
	import { SidebarLayout } from "@lib/components"
	
	const instance = getCurrentInstance();
    const t = instance.appContext.config.globalProperties.$t;

	const store = useStore()

	const signOut = () => {
		store.dispatch("auth/signOut").then(() => {
			window.location = "/"
		})
	}

	const navigationItems = ref([
		{
          name: 'Mindset and Mental Preparation',
          icon: "folder",
      	  key: "settings",
          route: "/settings/profile"
        },
        {
          name: 'Market Analysis',
          icon: "folder",
      	  key: "settings",
          route: "/settings/subscriptions"
        },
        {
          name: 'Risk Assessment and Management',
          icon: "folder",
      	  key: "settings",
          route: "/settings/subscriptions"
        },
        {
          name: 'Strategy Review and Planning',
          icon: "folder",
      	  key: "settings",
          route: "/settings/subscriptions"
        },
        {
          name: 'Tool and System Checks',
          icon: "folder",
      	  key: "settings",
          route: "/settings/subscriptions"
        },
        {
          name: 'Compliance and Regulations',
          icon: "folder",
      	  key: "settings",
          route: "/settings/subscriptions"
        },
        {
          name: 'Personal Well-being',
          icon: "folder",
      	  key: "settings",
          route: "/settings/subscriptions"
        },
        {
          name: 'Review of Previous Trading Day',
          icon: "folder",
      	  key: "settings",
          route: "/settings/subscriptions"
        },
        // {
        //   name: t('view.settings.sidebar.configuration'),
        //   icon: "folder",
      	//   key: "settings",
        //   route: "/settings/configuration"
        // },
	])
</script>