<template>
  <UiGridTile>
    This feature is only available in our Pro Plan and for Live Traders.
  </UiGridTile>
  <template v-if="false">
	<div style="margin-top: 59px;">
      <div>
        <!-- Value -->
        <div style="color: #000;font-size: 28px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -1.68px;">
        	<UiRow align="center" gap="2">
        	<!-- Form -->
		  	<div class="form-row">
				  <div class="form-field">
				      <label>Account Size</label>
				      <input
				        type="number"
				        placeholder="0.00"
				        v-model="account.balance"
				        disabled
				      />
				      <!-- <p v-if="error" style="color: #FF2C46;margin-top: 4px">{{ error }}</p> -->
				  </div>
			  </div>
			  <UiLocked locked text="Scaling not available">
			  	<UiGridTile>Request Account Scaling</UiGridTile>
			  </UiLocked>
			</UiRow>
			  ${{ account.balance }}
    	</div>
      </div>
    </div>

	<div style="margin-top: 59px;display:grid;grid-template-columns: 1fr 1fr 1fr 1fr;gap: 19px;">
      <div v-for="stat in riskParameters">
        <!-- Value -->
        <div style="color: #000;font-size: 28px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -1.68px;">
        	<!-- Form -->
		  	<div class="form-row">
				  <div class="form-field">
				      <label>{{ stat.title }}</label>
				      <input
				        type="number"
				        placeholder="0.00"
				        v-model="stat.value"
				      />
				      <!-- <p v-if="error" style="color: #FF2C46;margin-top: 4px">{{ error }}</p> -->
				  </div>
			  </div>
        	{{ stat.value }}
    	</div>

        <!-- Info -->
        <div style="color: #000;font-size: 17px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: 0.17px;margin-top: 3px;" v-if="stat.value">
          {{ (100 / account.balance * stat.value).toFixed(2) }}%
        </div>
      </div>
    </div>

    <UiLocked locked text="Not available">
    	<UiButton style="margin-top: 71px;">Submit Request</UiButton>
    </UiLocked>
  </template>
</template>

<script setup>
	import { ref } from "vue"
	import { useStore } from "vuex"
	import { useRoute } from "vue-router"

	const store = useStore()
	const route = useRoute()
	const riskParameters = ref()
	const account = ref({})

	store.dispatch("dashboard/getTradingAccount", route.params.accountId).then((res) => {
      account.value = res.data
    })

	store.dispatch("dashboard/getTradingAccountRiskParameters", route.params.accountId).then((res) => {
      riskParameters.value = res.data.riskParameters
    })
</script>