<template>
	<LayoutUser class="trading-desk">
		<div class="container-l" v-if="account">
			<UiRow gap="2" style="height: 100%;">
				<!-- Account -->
				<div style="width: 512px; height: 100%;">
					<UiGridTile color="white" full grow>
						<UiCol gap="2">
							<!-- <router-link :to="`/trading-desk/${desk.id}`" v-for="desk in desks">
								<UiGridTile full>
									<UiRow align="center" gap="2">
										<UiAvatar :imgUrl="desk.manager.profileImageUrl" size="40px"></UiAvatar>
										<div>{{ desk.name }}</div>
									</UiRow>
								</UiGridTile>
							</router-link> -->
						</UiCol>
					</UiGridTile>
				</div>
				<UiCol gap="2" style="height: 100%;width: 100%;flex: inherit;padding-top: 8px;">
					<UiHeadline size="m" bold>Plan</UiHeadline>
					<div>
						<UiRow gap="4">
							<UiGridTile full>
								<a @click="showGeneralPlan = true">General Plan</a>
							</UiGridTile>
							<UiGridTile full>
								<a @click="showGeneralPreTradingCheckList = true">
									Pre Trading Checklist
								</a>
							</UiGridTile>
							<UiGridTile full>
								<a @click="showStrategies = true">
									Strategies
								</a>
							</UiGridTile>
							<UiGridTile full>
								<a @click="showImprovementPlan = true">
									Improvement Plan
								</a>
							</UiGridTile>
						</UiRow>
					</div>
					<UiHeadline size="m" bold>Performance</UiHeadline>
					<div>
						<UiGridTile full grow v-if="performance">
						  	<div>Your Performance</div>
						  	<UiRow gap="2">
									<UiGridTile full grow>
										<div style="margin-bottom: 8px;">Stats</div>
										<UiCol gap="1">
											<UiGridTile color="white">
												<UiRow justify="between">
													Win-Loss Ratio (W/L Ratio) <a>{{ performance.winLossRatio }}</a>
												</UiRow>
											</UiGridTile>
											<UiGridTile color="white">
												<UiRow justify="between">
													Profit Factor <a>{{ performance.profitFactor }}</a>
												</UiRow>
											</UiGridTile>
											<UiGridTile color="white">
												<UiRow justify="between">
													Trade Expectancy <a>{{ performance.tradeExpectancy }}</a>
												</UiRow>
											</UiGridTile>
											<UiGridTile color="white">
												<UiRow justify="between">
													Average Profit per Trade <a>{{ performance.averageProfitPerTrade }}</a>
												</UiRow>
												<UiRow justify="between">
													Average Loss per Trade <a>{{ performance.averageLossPerTrade }}</a>
												</UiRow>
											</UiGridTile>
											<UiGridTile color="white">
												<UiRow justify="between">
													Trade Duration <a>{{ (performance.averageTradeDuration / 60).toFixed(2) }}m</a>
												</UiRow>
											</UiGridTile>

											<!-- Win-Loss -->
											<div><small>Win-Loss</small></div>
											<UiGridTile color="white">
												<UiRow justify="between">
													Winnings Trades <a>{{ performance.wins }}</a>
												</UiRow>
												<UiRow justify="between">
													Losing Trades <a>{{ performance.losses }}</a>
												</UiRow>
											</UiGridTile>
											<UiGridTile color="white">
												<UiRow justify="between">
													Win % <a>{{ performance.winrate }}%</a>
												</UiRow>
												<UiRow justify="between">
													Losing % <a>{{ performance.losingrate }}%</a>
												</UiRow>
											</UiGridTile>
											<UiGridTile color="white">
												<UiRow justify="between">
													Biggest Win <a>{{ performance.biggestWiningTrade }}</a>
												</UiRow>
												<UiRow justify="between">
													Biggest Loss <a>{{ performance.biggestLosingTrade }}</a>
												</UiRow>
											</UiGridTile>

											<!-- Day -->
											<div><small>Day</small></div>
											<UiGridTile color="white">
												<UiRow justify="between">
													Total Days <a>{{ performance.totalDays }}</a>
												</UiRow>
											</UiGridTile>
											<UiGridTile color="white">
												<UiRow justify="between">
													Best Weekday <a>{{ performance.bestDayOfWeek }}</a>
												</UiRow>
												<UiRow justify="between">
													Worst Weekday <a>{{ performance.worstDayOfWeek }}</a>
												</UiRow>
											</UiGridTile>
											<!-- Product -->
											<div><small>Product</small></div>
											<UiGridTile color="white">
												<UiRow justify="between">
													Most Traded <a>{{ performance.mostTradedSymbol }}</a>
												</UiRow>
											</UiGridTile>
											<UiGridTile color="white">
												<UiRow justify="between">
													Best Performing <a>{{ performance.bestPerformingProduct }}</a>
												</UiRow>
												<UiRow justify="between">
													Worst Performing <a>{{ performance.worstPerformingProduct }}</a>
												</UiRow>
											</UiGridTile>

											<!-- Trading Hours -->
											<div><small>Trading Hours (Not available)</small></div>
											<UiGridTile color="white">
												<UiRow justify="between">
													Most Traded <a>{{ performance.mostTradedHour }}</a>
												</UiRow>
											</UiGridTile>
											<UiGridTile color="white">
												<UiRow justify="between">
													Best Performing <a>{{ performance.bestPerformingTradingHour }}</a>
												</UiRow>
												<UiRow justify="between">
													Worst Performing <a>{{ performance.worstPerformingTradingHour }}</a>
												</UiRow>
											</UiGridTile>
										</UiCol>
									</UiGridTile>
									<UiGridTile full grow>
										<div style="margin-bottom: 8px;">Stats (30 day)</div>
										<div><small>Not enough data yet</small></div>
									</UiGridTile>
									<UiGridTile full grow>
										<div style="margin-bottom: 8px;">AI</div>
										<div><small>No Tips</small></div>
									</UiGridTile>
								</UiRow>
								<div>Charts</div>
								<div><small>Not enough data</small></div>
						</UiGridTile>
					</div>

					<UiHeadline size="s" bold style="margin-top: 24px;">Risk Management</UiHeadline>
					<UiRow gap="2" style="height: 100%;max-height: 256px;">
						<UiGridTile full grow>
							<div style="margin-bottom: 8px;">Accounts Levels</div>
							<UiCol gap="1">
								<UiGridTile color="white" v-for="item in account.statsEval">
									<UiRow justify="between">
										{{ item.title }} <a>{{ item.value }}</a>
									</UiRow>
								</UiGridTile>
							</UiCol>
						</UiGridTile>
						<UiGridTile full grow>
							<div style="margin-bottom: 8px;">Risk Parameters</div>
							<UiCol gap="1">
								<UiGridTile color="white" v-for="item in account.riskSettings">
									<UiRow justify="between">
										{{ item.title }} <a>{{ item.value }}</a>
									</UiRow>
								</UiGridTile>
							</UiCol>
						</UiGridTile>
						<UiGridTile full grow>
							<div style="margin-bottom: 8px;">Daily Check</div>
							<UiCol gap="1">
								<UiGridTile color="white" v-for="item in account.stats">
									<UiRow justify="between">
										{{ item.title }} <a>{{ item.value }}</a>
									</UiRow>
								</UiGridTile>
							</UiCol>
						</UiGridTile>
					</UiRow>

					<UiGridTile full>
							<div style="margin-bottom: 8px;">Summary</div>
							<UiCol gap="1">
								<UiGridTile color="white">
									<UiRow justify="between">
										Possible losing Days <a>{{ sumPossibleLosingDays }}</a>
									</UiRow>
								</UiGridTile>
							</UiCol>
						</UiGridTile>

						<UiGridTile full grow color="white">
							<UiHeadline size="s" bold style="margin-top: 24px;">Trading Days</UiHeadline>
							<TradingDays :account-id="route.params.accountId" @select="onSelectDate" style="width: 100%;"></TradingDays>
						</UiGridTile>
				</UiCol>
				<div style="width: 720px;height: 100%;">
					<div class="chat-col" full grow v-if="desk">
						<Chat :chat-id="desk.chatId" style="height: calc(100vh - 73px - 67px);"></Chat>
						<ChatMessageBox :chat-id="$store.state.chat.supportChatId" chat-type="support"></ChatMessageBox>
					</div>
				</div>
			</UiRow>
		</div>
		<UiModal title="Join Now" v-if="false && desk.manager">
			<div style="margin-top: 16px;">You need to join {{ desk.manager.firstname }} Trading Desk to continue watching his Room.</div>
			<UiButton full style="margin-top: 32px;">Join Free</UiButton>
		</UiModal>
		<UiModal title="Coming Soon" @close="onCloseComingSoon" v-if="false">
			<div style="margin-top: 32px;">
				<UiHeadline size="m" style="margin-bottom: 16px;">How does our trading desk work?</UiHeadline>
				<p>
					First of all, there is not just one desk, we have more experienced traders (Senior Traders), who manage the corresponding desks. You can choose for yourself which desk you would like to join. There are desks that focus on certain products, and others that apply specific strategies.<br><br>

					<small>Note: Not all desks will be available during our test phase.</small>
				</p>
			</div>
			<!-- <UiButton full style="margin-top: 32px;">Join Free</UiButton> -->
		</UiModal>
	</LayoutUser>
	<UiModal title="General Plan" v-if="showGeneralPlan" @close="showGeneralPlan = false">
		<TradingPlanModule style="margin-top: 24px;"></TradingPlanModule>
	</UiModal>
</template>

<script setup>
	import { ref, computed, watch } from "vue"
	import { useStore } from "vuex"
	import { useRouter, useRoute } from "vue-router"
	import { LayoutUser } from "@lib/components"
	import Chat from "@/components/Chat"
	import ChatMessageBox from "@/components/ChatMessageBox"
	import TradingDays from "@/components/TradingDays"
	import TradingPlanModule from "@/components/modules/TradingPlanModule"

	const store = useStore()
	const router = useRouter()
	const route = useRoute()
	const syncing = ref(false)
	const syncingStatus = ref(null)
	const performance = ref(null)
	const showGeneralPlan = ref(false)

	const desks = computed(() => store.state.dashboard.desks)
	const desk = computed(() => store.getters["dashboard/desk"])

	const sumPossibleLosingDays = computed(() => account.stats)

	const onCloseComingSoon = () => {
		router.push({name:"dashboard",query:{origin:"trading_desk_coming_soon"}})
	}

	const fetchDesks = async () => {
		return new Promise(resolve => {
			store.dispatch("dashboard/getDesks").then((res) => {}).catch((err) => {})
		})
	}

	fetchDesks()

	watch(() => route.params.deskId, () => {
		store.dispatch("dashboard/setDesk", route.params.deskId)
	})

	const account = computed(() => store.getters["dashboard/account"])

	store.dispatch("dashboard/setAccount", route.params.accountId)

	if (!store.state.dashboard.accounts.length) store.dispatch("dashboard/getTradingAccounts")

	store.dispatch("dashboard/getTradingAccountPerformance", route.params.accountId).then((res) => {
		performance.value = res.data.performance
  }).catch((err) => {

  })

	const fetchAccount = async () => {
		return new Promise(resolve => {
			syncingStatus.value = 'Fetching Account'
			store.dispatch("dashboard/getTradingAccount", route.params.accountId).then((res) => {

		      resolve()
		    }).catch((err) => {
		    	error.value = err.response.data.key
		    })
		})
	}

	const fetch = () => {
			fetchAccount()

			syncing.value = true
			syncingStatus.value = 'Syncing with Broker'
	    store.dispatch("dashboard/syncTradingAccount", route.params.accountId).then((res) => {
	      fetchAccount().then(() => {
	      	syncingStatus.value = "Account up to date"
	      	syncing.value = false
	      })
	    }).catch(() => {
	    	syncingStatus.value = "Update failed, please try again in 10 minutes"
	    	syncing.value = false
	    })
	  }

	  fetch()

	const onSelectDate = (date) => {
		router.push(`/trading/accounts/${route.params.accountId}/days/${date}`)
	}
</script>

<style scoped>
.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.chat-col {
	display: flex;
	flex-flow: column;
	height: inherit;
	border-bottom-left-radius: 16px;
  background: aliceblue;
}

.darkmode .chat-col {
	background: #191b1f;
}

.table {

}

th, td {
	font-size: 15px;
}
</style>