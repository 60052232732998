<template>
	<div class="chat-message-box">
		<textarea 
		v-model="message"
		@keydown.enter.prevent 
		@keyup.enter="onSendMessage"
		:disabled="!$store.state.chat.socketConnected"
		placeholder="Send a message"></textarea>
	</div>
</template>

<script setup>
	import { ref, computed, defineEmits } from "vue"
	import { useStore } from "vuex"
	import socket from "@lib/services/socket";
	import cookie from "@lib/services/cookie";

	const store = useStore()
	const message = ref("")
	const props = defineProps({
		chatId: String,
		chatType: {
			type: String,
			default: 'user'
		}
	})
	const emit = defineEmits(['sent'])

	const memberForMessage = computed(() => {
		const { id, name, username, profileImageUrl, title } = store.state.user.user

		return {
			id,
			name,
			username,
			profileImageUrl,
			title
		}
	})

    const onSendMessage = () => {
    	if (!message.value) return
    	socket.emit("chat-message", {
	        chat: {
	          id: props.chatId,
	        },
	        message: {
	          member: memberForMessage.value,
	          message: message.value,
	          // imageUrl,
	          sentAt: new Date(),
	          type: props.chatType,
	        },
	        sessionId: cookie.get("session_id"),
	      });

    	store.dispatch("chat/addMessage", {
			id: null,
			member: memberForMessage.value,
			message: message.value,
			sentAt: new Date()
		}).then(res => {
			
		})

      message.value = ""
	}
</script>

<style scoped>
	.chat-message-box {
		background: var(--chat-message-box_background);
	}
	.chat-message-box textarea {
		background: var(--chat-message-box_background);
		font-family: "Euclid Circular A";
	    padding: 10px 14px;
	    font-weight: 500;
	    line-height: 1.25;
	    font-size: 1rem;
	    border: none;
	    border-top: var(--chat-message-box_border-top);
	    outline: none;
	    resize: none;
	    color: var(--chat-message-box_color);
	}
</style>