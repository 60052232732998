<template>
	<UiHeadline style="margin-bottom: 71px;">Let's complete your setup</UiHeadline>

	<!-- Step 1 -->
	<UiCard v-if="currentStep == 0">
		<UiCol gap="4">
			<div>
				<!-- Step 1 -->
				<UiHeadline size="m" style="margin-bottom: 16px;">
					1. Go to <a href="https://trader.tradovate.com" target="_blank">tradovate.com</a> and enter your platform credentials
				</UiHeadline>

				<!-- Credentials -->
				<TradingPlatformCredentials/>
			</div>

			<div>
				<!-- Step 2 -->
				<UiHeadline size="m" style="margin-bottom: 16px;">
					2. Sign the "Uniform Subscriber Agreement"
				</UiHeadline>
			</div>

			<div>
				<!-- Step 3 -->
				<UiHeadline size="m" style="margin-bottom: 16px;">
					3. Select the exchanges you want to trade
				</UiHeadline>

				<div class="card flex flex-wrap justify-content-center gap-3">
			        <div class="flex align-items-center" v-for="exchange in exchanges">
			            <Checkbox v-model="selectedExchanges" inputId="ingredient1" :name="exchange.name" :value="exchange.value" />
			            <label for="ingredient1" class="ml-2"> {{ exchange.name }} </label>
			        </div>
			    </div>
			</div>

			<!-- Error: Agreement -->
      		<p style="color: #FF2C46;margin-top: 4px;" v-if="errors && errors.agreement">{{ $t(errors.agreement) }}</p>

			<div>
				<UiButton size="l" @click="onConfirmStep1" :loading="confirmationLoading">Done</UiButton>
			</div>
		</UiCol>
	</UiCard>

	<!-- Step 2 -->
	<UiCard v-if="currentStep == 1">
		<UiCol gap="4">
			<div>
				<!-- Step 1 -->
				<UiHeadline size="m" style="margin-bottom: 16px;">
					You're all set, you can start trading now
				</UiHeadline>
			</div>

			<div>
				<UiButton size="l" to="/">Back to Dashboard</UiButton>
			</div>
		</UiCol>
	</UiCard>
</template>

<script setup>
	import { ref, computed, watch } from "vue"
	import { useRouter, useRoute } from "vue-router"
	import { useStore } from "vuex"
	import Checkbox from 'primevue/checkbox'
	import TradingPlatformCredentials from "@/components/TradingPlatformCredentials"

	const router = useRouter()
	const route = useRoute()
	const store = useStore()
	const model = ref({
		trading_products: null,
		trading_plan: null,
		risk_manager: null,
		reason: null,
	})
	const startStep = ref(0)
	const currentStep = ref(0)
	const confirmationLoading = ref(false)
	const errors = ref(null)
	const selectedExchanges = ref([1])
	const exchanges = ref([
		{
			name: "CME",
			value: 1
		},
		{
			name: "NYMEX",
			value: 9
		},
		{
			name: "CBOT",
			value: 5
		},
		{
			name: "COMEX",
			value: 7
		},
	])

	if (!route.query.step) router.push({query:{step:startStep.value}})

	const onConfirmStep1 = () => {
		if (confirmationLoading.value) return
			
		errors.value = null
		confirmationLoading.value = true

		store.dispatch("dashboard/confirmOnboardingStep1", {
			data: {
				exchanges: selectedExchanges.value
			}
		})
	    .then((res) => {
	    	store.dispatch("auth/authenticate")

	    	currentStep.value = 1

	    	confirmationLoading.value = false
	    })
	    .catch((err) => {
	    	errors.value = err.response.data.errors

	    	if (err.response.status == 409) {
	    		currentStep.value = 1
	    	}

	    	confirmationLoading.value = false
	    });
	}

	const validateStep = () => {

	}

	const nextStep = () => {
		validateStep()
		currentStep.value = currentStep.value + 1
		router.push({query:{step:currentStep.value}})
	}

	const skipStep = () => {
		currentStep.value = currentStep.value + 1
		router.push({query:{step:currentStep.value}})
	}

	const previousStep = () => {
		currentStep.value = currentStep.value - 1
		router.push({query:{step:currentStep.value}})
	}

	watch(() => route.query.step, () => {
		currentStep.value = Number(route.query.step)

		if (!route.query.step) router.push({query:{step:startStep.value}})
	},{
		immediate: true
	});

	const previousStepAvailable = computed(() => currentStep.value > 0)
	const nextStepAvailable = computed(() => (currentStep.value + 1) < onboardingSteps.value.length)
	const totalSteps = computed(() => onboardingSteps.value.length - 1)
	const progress = computed(() => (100 / totalSteps.value * currentStep.value))

	const onboardingSteps = ref([
		{
			key: 'intro',
			title: "Welcome to your new Trading Desk",
			description: "Please complete your account setup.",
			nextButton: "Complete Setup"
		},
		{
			key: 'trading_products',
			title: "What products will you trade?",
			form: `{
			  "rows": [
			    {
			      "columns": [
			        {
			          "fields": [
			            {
			              "type": "select_group",
			              "label": "Select Products/Symbols",
			              "name": "trading_products",
			              "placeholder": "",
			              "required": true,
			              "options": [{"value": "NQ","name": "NQ"},{"value": "ES","name": "ES"},{"value": "CL","name": "CL"},{"value": "GC","name": "GC"}]
			            }
			          ]
			        }
			      ]
			    }
			  ]
			}`
		},
		{
			key: 'trading_plan',
			title: "Do you need a trading plan?",
			form: `{
			  "rows": [
			    {
			      "columns": [
			        {
			          "fields": [
			            {
			              "type": "select_group",
			              "label": "",
			              "name": "trading_products",
			              "placeholder": "",
			              "required": true,
			              "options": [{"value": "yes","name": "Yes"},{"value": "no","name": "No"}]
			            }
			          ]
			        }
			      ]
			    }
			  ]
			}`
		},
		{
			key: 'coach',
			title: "Would you like coaching?",
			form: `{
			  "rows": [
			    {
			      "columns": [
			        {
			          "fields": [
			            {
			              "type": "select_group",
			              "label": "",
			              "name": "trading_products",
			              "placeholder": "",
			              "required": true,
			              "options": [{"value": "yes","name": "Yes"},{"value": "no","name": "No"}]
			            }
			          ]
			        }
			      ]
			    }
			  ]
			}`
		},
		{
			key: 'risk_manager',
			title: "Select your Risk Manager",
			form: `{
			  "rows": [
			    {
			      "columns": [
			        {
			          "fields": [
			            {
			              "type": "select_group",
			              "label": "",
			              "name": "trading_products",
			              "placeholder": "",
			              "required": true,
			              "options": [{"value": "jonas","name": "Jonas"},{"value": "frank","name": "Frank"}]
			            }
			          ]
			        }
			      ]
			    }
			  ]
			}`
		},
		{
			key: 'trading_desk',
			title: "Select your Trading Desk",
			form: `{
			  "rows": [
			    {
			      "columns": [
			        {
			          "fields": [
			            {
			              "type": "select_group",
			              "label": "",
			              "name": "trading_products",
			              "placeholder": "",
			              "required": true,
			              "options": [{"value": "mein","name": "Main Desk"},{"value": "frank","name": "Roderick Casilli's Desk","description": "Rod maintains a Live Trading Room and focuses on managing risk with you by trading small."}]
			            }
			          ]
			        }
			      ]
			    }
			  ]
			}`
		},
		{
			key: 'reason',
			title: "What are your main reasons to work with us?",
			form: `{
			  "rows": [
			    {
			      "columns": [
			        {
			          "fields": [
			            {
			              "type": "select_group",
			              "label": "",
			              "name": "trading_products",
			              "placeholder": "",
			              "required": true,
			              "options": [{"value": "funding","name": "Funding"},{"value": "improve","name": "Improve Trading"}]
			            }
			          ]
			        }
			      ]
			    }
			  ]
			}`
		},
		{
			key: 'reason',
			title: "Any Feedback for us?",
			canSkip: true,
			form: `{
			  "rows": [
			    {
			      "columns": [
			        {
			          "fields": [
			            {
			              "type": "textarea",
			              "label": "",
			              "name": "email",
			              "placeholder": "Your Feedback",
			              "required": true
			            }
			          ]
			        }
			      ]
			    }
			  ]
			}`
		},
		{
			key: 'risk_disclosure',
			title: "Disclosure",
			form: `{
			  "rows": [
			    {
			      "columns": [
			        {
			          "fields": [
			            {
			              "type": "paragraph",
			              "label": "Please Read",
			              "text": "Futures trading contains substantial risk and is not for every investor. An investor could potentially lose all or more than the investor’s initial investment. Only risk capital—money that can be lost without jeopardizing one’s financial security or lifestyle—should be used for trading and only those individuals with sufficient risk capital should consider trading. Nothing contained herein is a solicitation or an offer to buy or sell futures, options, or forex. Past performance is not necessarily indicative of future results.",
			              "name": "trading_products",
			              "placeholder": "",
			              "required": true,
			              "options": [{"value": "jonas","name": "Jonas"},{"value": "frank","name": "Frank"}]
			            }
			          ]
			        }
			      ]
			    }
			  ]
			}`,
			nextButton: "Confirm & Next"
		},
		{
			title: "Onboarding Complete",
			completeCta: {
				text: "Start Trading",
				route: "/"
			}
		},
	])
</script>