<template>
	<div class="map-wrapper">
		<div class="c1">
			<ProjectList></ProjectList>
		</div>
		<div ref="mapContainer" class="map-container"></div>
	</div>
</template>

<style scoped>
	.map-wrapper {
		top: 0;
		left: 0;
		position: fixed;
		display: flex;
		width: 100%;
		height: 100vh;
	}
	
	.map-wrapper .c1 {
		padding: 0 24px;
	}

	.map-container {
	/*  top: 0;
	  left: 0;
	  position: fixed;
	  flex: 1;
	  height: 100%;
	  width: 100%;*/
	  width: 100%;
	  height: 100%;
	}

	.map-wrapper .c1 {
		width: 100%;
		max-width: 662px;
	}

	.map-wrapper .project-list .c2 {
		display: none;
	}
</style>

<script setup>
	import ProjectList from "@/components/ProjectList"
	import { ref, onMounted } from "vue";
	import mapboxgl from "mapbox-gl";

	const mapContainer = ref();
	const map = ref();
	
	mapboxgl.accessToken = "pk.eyJ1Ijoiam9lbmFzam8iLCJhIjoiY2o2M3k2NW96MWdpcTJybndtbmQ2aWtpYyJ9.9f0O8JplL4G6An4-ci8dQw";

	onMounted(() => {
		map.value = new mapboxgl.Map({
		    container: mapContainer.value,
		    style: "mapbox://styles/mapbox/streets-v12",
		    center: [-71.224518, 42.213995],
		    zoom: 9,
		  });
	})
</script>