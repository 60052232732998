const isServiceHours = () => {
  const now = new Date();
  const utcOffset = now.getTimezoneOffset() * 60000; // Convert to milliseconds
  const ctOffset = -300 * 60000; // UTC offset for Central Time (CT) is -5 hours or -300 minutes

  // Calculate current time in CT
  const ctTime = new Date(now.getTime() + utcOffset + ctOffset);

  // Get current hour in CT
  const ctHour = ctTime.getHours();

  // Check if current hour is between 7am and 4pm
  return ctHour >= 7 && ctHour <= 16;
}

export {
  isServiceHours
}