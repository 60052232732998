<template>
	<div class="checklist" style="border-radius: 16px;background: #F6F7F7;flex: none;">
      <div style="display:block;grid-template-columns: 1fr 1fr 1fr;gap: 19px;">
        <router-link :to="item.path" style="border-radius: 16px;background: #F6F7F7;padding: 10px;cursor: pointer;display: block;color:#000;" v-for="item in checklist">
          <div style="display: flex;align-items: center;gap: 8px;">
            <div style="width: 36px;height: 36px;border-radius: 10px;background: #fff;"></div>
            <div style="font-size: 19px;font-weight: 500;line-height: normal;letter-spacing: -1.14px;">{{ item.name }}</div>
          </div>
        </router-link>
      </div>
    </div>
</template>

<script setup>
	import { ref, computed } from "vue"

  const checklist = ref([
    {
      name: "Mindset",
      path: "/checklist/mindset"
    },
    {
      name: "Balance & Positions",
      path: "/checklist/mindset"
    },
    {
      name: "Analyze Overnight session",
      path: "/checklist/mindset" 
    },
    {
      name: "Find out market sentiment",
      path: "/checklist/mindset" 
    },
    {
      name: "Economic calendar",
      path: "/checklist/mindset" 
    },
    {
      name: "News & Event",
      path: "/checklist/mindset" 
    },
    {
      name: "Levels",
      path: "/checklist/mindset" 
    },
    {
      name: "Technical Analysis",
      path: "/checklist/mindset" 
    },
  ])
</script>

<style scoped>
  .checklist a {
    color: var(--color-brand);
  }
</style>