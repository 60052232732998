import http from "@lib/services/http";
import cookie from "@lib/services/cookie";
import config from "@lib/store/modules/config";

const Api = {
  getModule(payload) {
    return http.post(`/v1/admin/list/${payload.moduleKey}`, payload.data);
  },
  searchModuleItems(payload) {
    return http.get(`/v1/admin/list/${payload.moduleKey}/search?search_term=${payload.searchTerm}`);
  },
  getAffiliates() {
    return http.get("/v1/admin/affiliates");
  },
  authAs(payload) {
    return http.post(`/v1/admin/users/${payload.userId}/auth`);
  },
};

const admin = {
  namespaced: true,
  state: {
    moduleItems: [],
    affiliates: [],
  },
  getters: {
    
  },
  mutations: {
    updateModule: (state, payload) => {
      state.moduleItems = payload.items
    },
    updateAffiliates: (state, payload) => {
      state.affiliates = payload.affiliates
    },
  },
  actions: {
    getModule: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.getModule(payload)
          .then((res) => {
            state.commit("updateModule", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    searchModuleItems: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.searchModuleItems(payload)
          .then((res) => {
            state.commit("updateModule", res.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getAffiliates: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.getAffiliates()
          .then((res) => {
            state.commit("updateAffiliates", res.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      }),
    authAs: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.authAs(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    switchToAdmin: (state, payload) => {
      cookie.set('access_token', cookie.get('admin_access_token'))
      cookie.set('admin_access_token', '')
      window.location.reload()
    }
  },
};

export default admin;