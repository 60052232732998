<template>
		<!-- Back:Mobile -->
	<div class="mobile">
		<UiBack route="/" text="Back to Dashboard" fixed></UiBack>
	</div>
	<div class="container" v-if="module">
		<UiHeadline style="margin-bottom: 42px;">{{ module.name }}</UiHeadline>

		<!-- <router-link to="/" style="width: 40px;height: 40px;border-radius: 999px;background: #AFAFAF80;cursor: pointer;display: block;"></router-link> -->
		<Form class="mb-16" v-if="form" :schema="form.schema" v-model="model" @submit="submit" :errors="errors" :submitting="submitting"></Form>

		<UiHeadline class="mb-2" size="m">List</UiHeadline>
		<SchemaTable :schema="tableSchema" :data="items.map(i => i.data)"></SchemaTable>

		<!-- <UiLocked :unlocked="$store.state.user.user.plans.includes('mastery')" style="margin-top: 72px;" v-if="module">
			<template v-for="field in module.module.fields">
				<template v-if="field.type === 'form'">
			      <div @click="activeModal = field.key" style="border-radius: 16px;background: #F6F7F7;padding: 10px;cursor: pointer;">
			      	<div style="font-size: 19px;font-weight: 500;line-height: normal;letter-spacing: -1.14px;">{{ field.name }}</div>
			      </div>
					<Modal v-if="activeModal === field.key" :title="field.name" @close="activeModal = null">
				    <Form v-model="model[field.key]" :schema="field.schema" @submit="update"></Form>
				  </Modal>
				</template>
			 </template>
	    </UiLocked> -->
	    <Toast />
	</div>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from 'vuex';
	import { useRoute } from 'vue-router';
	import { useToast } from 'primevue/usetoast';
	import Toast from "primevue/toast";
	import Modal from "@/components/Modal"
	import SchemaTable from "@/components/SchemaTable"

	const toast = useToast();
	const store = useStore()
	const route = useRoute()
	const model = ref(null)
	const module = ref(null)
	const show = ref(false)
	const activeModal = ref(null)
	const items = ref([])
	const errors = ref(null)
	const submitting = ref(false)

	const form = computed(() => {
		if (module.value) {
			return module.value.fields.find(f => f.key === 'form')
		} else {
			return null
		}
	})

	const tableSchema = computed(() => JSON.parse(module.value.tableSchema))

	import PsychologyAssessmentModule from "@/components/modules/PsychologyAssessmentModule"

	const fetch = () => {
		store.dispatch("dashboard/getModule", route.params.moduleId).then((res) => {
			items.value = res.data.items
			module.value = res.data.module
		})
	}

	fetch()

	const submit = () => {
		if (submitting.value) return

		submitting.value = true
		
		errors.value = null
		
		store.dispatch("dashboard/createModuleEntry", {
			moduleId: route.params.moduleId,
			data: {
				data: model.value,
				reference: module.value.key
			}
		}).then(() => {
			model.value = {}
			fetch()
			submitting.value = false
			toast.add({ severity: 'success', summary: 'Success', detail: '', life: 2000 });
		}).catch(err => {
			errors.value = err.response.data.errors;
			submitting.value = false
		})
	}
</script>