<template>
	<div class="time-ago">
		{{ _time }}
	</div>
</template>

<script setup>
	import { ref } from "vue";
	import moment from "moment";

	const props = defineProps({
		time: Date
	})

	const _time = ref(null);

	_time.value = moment(props.time).fromNow();
	setTimeout(() => {
	  _time.value = moment(props.time).fromNow();
	}, 60000);
</script>