<template>
	<SidebarLayout :items="items" title="Education">
	</SidebarLayout>
</template>

<script setup>
	import { ref } from "vue"
	import Presentation from "@/components/presentation/Presentation"
	import { SidebarLayout } from "@lib/components"

	const items = ref([
		{
			name: "Courses",
			path: "/education/courses",
			icon: "trading"
		},
	])
</script>