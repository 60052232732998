<template>
	<UiCard>
      <div>
        <!-- <img class="avatar" src="https://media.licdn.com/dms/image/C4E03AQH7JCkvreM4aw/profile-displayphoto-shrink_800_800/0/1660524148342?e=2147483647&v=beta&t=3IWf7kyoXxLk6oNV9wp0mXVbRNLNZajP9T9Vz8_9A8Y"> -->
        <div>
          <div>Jonas</div>
          <div>Level 1 | Day 34</div>
          <div>Beginner</div>
          <div>600p</div>
        </div>
        <br><br>
        Past 5 trading days
        <div style="display:flex;gap:.5rem;margin-top: .25rem;flex-wrap: wrap;">
          <div style="height: 32px;width: 32px;background: gray;color: white;display:flex;align-items: center;justify-content: center;font-size: 12px;" v-for="day in 30">
            <div></div>
          </div>
        </div>
      </div>
    </UiCard>
</template>