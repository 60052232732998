<template>
	<!-- Back:Mobile -->
	<div class="mobile">
		<UiBack route="/" text="Back to Dashboard" fixed></UiBack>
	</div>
	
	<UiHeadline style="margin-bottom: 42px;">{{ $t('view.my_profile.title') }}</UiHeadline>

	<UiAvatar :imgUrl="user.profileImageUrl" size="128px"></UiAvatar>

	<div style="display: flex;gap: 4px;">
		<div style="border-radius: 16px;background: #F6F7F7;padding: 10px;cursor: pointer;font-size: 19px;font-weight: 500;line-height: normal;letter-spacing: -1.14px;width: fit-content;">
			{{ user.firstname }} {{ user.lastname }}
		</div>
		<div style="border-radius: 16px;background: #F6F7F7;padding: 10px;cursor: pointer;font-size: 19px;font-weight: 500;line-height: normal;letter-spacing: -1.14px;width: fit-content;">
			@{{ user.username }}
		</div>
		<div style="border-radius: 16px;background: #F6F7F7;padding: 10px;cursor: pointer;font-size: 19px;font-weight: 500;line-height: normal;letter-spacing: -1.14px;width: fit-content;" @click="showXPLevelModal = true">
			XP {{ user.score }}
		</div>
	</div>

	<!-- Stats -->
    <div style="margin-top: 59px;display:grid;grid-template-columns: 1fr 1fr 1fr 1fr;gap: 19px;">
      <div v-for="stat in user.stats">
        <!-- Label -->
        <div style="color: #000;font-size: 16px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -0.32px;margin-bottom: 18px;">
        	{{ $t(stat.title) }}
        </div>

        <!-- Value -->
        <div style="color: #000;font-size: 28px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -1.68px;">{{ stat.value }}</div>

        <!-- Info -->
        <div style="color: #000;font-size: 17px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: 0.17px;margin-top: 3px;" v-if="stat.info" v-html="stat.info">
        </div>
      </div>
    </div>

    <!-- Awards -->
    <div style="margin-top: 71px;border-top: 1px solid;display: flex;gap: 32px;text-align: center;color: rgb(0, 0, 0);  font-size: 17px; font-style: normal; font-weight: 500; line-height: normal; letter-spacing: 0.17px;color: #000;">
    	<div @click="showJuniorTraderModal = true">
    		<img src="/img/medal-1.png" style="width: 96px;">
    		<div>Junior Trader</div>
    	</div>
    	<div @click="showLiveTraderModal = true" style="opacity: .25;">
    		<img src="/img/medal-2.png" style="width: 116px;">
    		<div>Live Trader</div>
    	</div>
    	<div @click="showSeniorTraderModal = true" style="opacity: .125;">
    		<img src="/img/medal-3.png">
    		<div>Senior Trader</div>
    	</div>
    </div>

    <!-- Certificates -->
    <UiHeadline size="m" style="margin-bottom: 16px;margin-top: 71px;">
      {{ $t('view.dashboard.certificates.title') }}
    </UiHeadline>

    <UiCol gap="2">
    	<template v-if="!certificates.length">
    		<CertificatePayout style="opacity: .2;"></CertificatePayout>
    		<div>Info: Once you reach funding you will receive this certificate.</div>
    	</template>
    	<template v-else>
    		<div v-for="certificate in certificates">
		      <CertificatePayout v-bind="certificate"></CertificatePayout>
		      <UiButton class="mt-2" color="black" target="_blank" :href="`/certificate/${certificate.certificationId}`">Download Certificate</UiButton>
		    </div>
	  	</template>
  	</UiCol>

    <UiModal title="Junior Trader" v-if="showJuniorTraderModal" @close="showJuniorTraderModal = false">
    	<br>
    	{{ $t('view.my_profile.level_junior.description') }}
    </UiModal>

    <UiModal title="Live Trader" v-if="showLiveTraderModal" @close="showLiveTraderModal = false">
    	<br>
    	{{ $t('view.my_profile.level_live.description') }}
    </UiModal>

    <UiModal title="Senior Trader" v-if="showSeniorTraderModal" @close="showSeniorTraderModal = false">
    	<br>
    	{{ $t('view.my_profile.level_senior.description') }}
    </UiModal>

    <UiModal :title="$t('view.my_profile.xp.title')" v-if="showXPLevelModal" @close="showXPLevelModal = false">
    	<br>
    	{{ $t('view.my_profile.xp.description') }}
    </UiModal>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"
	import CertificatePayout from "@/components/CertificatePayout"

	const store = useStore()
	const showJuniorTraderModal = ref(false)
	const showLiveTraderModal = ref(false)
	const showSeniorTraderModal = ref(false)
	const showXPLevelModal = ref(false)
	const certificates = ref([])

	const user = computed(() => store.state.user.user)

	store.dispatch("dashboard/getCertificates").then((res) => {
    certificates.value = res.data.certificates
  })
</script>