<template>
	<!-- Headline -->
	<UiHeadline style="margin-bottom: 42px;">Report</UiHeadline>

	<p>Coming Soon</p>

	<!-- <UiButton to="/report/new">Create Report</UiButton> -->
</template>

<script setup>
</script>