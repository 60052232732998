<template>
  <!-- Body -->
  <div class="body">
    <router-view/>
  </div>
  <div class="fixed-sidebar" v-if="$store.state.dashboard.showJournalSidebar">
    <UiNavigation title="Journal"></UiNavigation>
    <div class="journal-notes">
      <textarea>
        1/19/24

        Risk
        Size: MNQ 5-10

        Alerts: none

        Analysis:
        - Daily Uptrend
        - Strong Uptrend
        - Retested 17150 after break
        - Rejected at 17175

        Idea 1
        Idea: Rejection at 165
        Bias: Short
        Support: 16980
        Resistance: , 
        Target: 135
        Stop: 
        Invalid: 

        Idea 2
        Idea: Support at 150
        Bias: Long
        Support: 150
        Resistance: 165, 
        Target: 
        Stop: 
        Invalid: 16980

        Idea 3
        Idea: Break of 150
        Bias: Short
        Support: 135
        Resistance: , 
        Target: 135
        Stop: 165
        Invalid: 165
        Notes: 
        - Got stopped out at 165 with my 2 positions
        - Reentered after rejection of 165, now looking for a break of 60 to add 1 more contract
        - New target is 150, as its strong support and could hold
        - Moving stop to 164, trade idea does not look valid anymore as we got support at 160 now
        - Now looks like buyers are stronger and we might even break 165, but will not go for it
        - But will set an alert for my other zones: break of 160 and 170
        - I will stop trading the london session for now and only focus on the NY session as I have to apply different strategies and always switching does not work for me yet.
        - Today I applied the scalping strategy to the london what does not work due to low liquidity, price is moving slow and then today was trading in a range, here I should have applied my startegy where I hold on to trades longer. Very good observation.
        - I'm now scaling down and coming back for the NY session

        Active commenting:
      </textarea>
    </div>
  </div>
  <!-- Sidebar -->
  <div class="sidebar desktop" v-if="false">
    <UiCol gap="1">
      <a class="sidebar-item" to="https://purdia.com">
        <img src="https://cdn.purdia.com/images/logo-icon.svg" style="height: 30px;">
      </a>
      <router-link class="sidebar-item" :class="`sidebar-item-${item.key}`" :to="item.route" v-for="item in quickAccessSidebar">
        <UiRow gap="2" align="center">
          <div class="sidebar-icon">
            <Icon :icon="item.icon" size="20" v-if="item.icon" color="#fff"></Icon>
          </div> 
          <div>{{ item.name }}</div>
          <UiBadge v-if="item.isPro">Pro</UiBadge>
        </UiRow>
        <ul class="sidebar-item-children" v-if="item.children">
          <router-link class="sidebar-item" :class="`sidebar-item-${item.key}`" :to="item.route" v-for="item in item.children">
            <UiRow gap="2" align="center">
              <div class="sidebar-icon" v-if="item.icon">
                <Icon :icon="item.icon" size="20" color="#fff"></Icon>
              </div> 
              <div>{{ item.name }}</div>
              <UiBadge v-if="item.isPro">Pro</UiBadge>
            </UiRow>
          </router-link>
        </ul>
      </router-link>
    </UiCol>
  </div>
</template>

<script setup>
  import { ref, computed, watch, getCurrentInstance } from "vue"
  import { useRoute } from "vue-router"
  import { useStore } from "vuex"
  import SupportChat from "@/components/SupportChat"

  const instance = getCurrentInstance();
  const t = instance.appContext.config.globalProperties.$t;

  const route = useRoute()
  const store = useStore()

watch(
  () => store.state.dashboard.config.theme_mode,
  (newTheme, oldTheme) => {
    if (oldTheme) {
      document.body.classList.remove(oldTheme);
    }
    document.body.classList.add(newTheme);
  },
  {
    immediate: true
  }
);



const quickAccessSidebar = ref([
    {
      name: t('view.dashboard.sidebar.navigation.dashboard'),
      icon: "clipboard-list",
      key: "dashboard",
      route: "/"
    },
    {
      name: t('view.dashboard.sidebar.navigation.checkin'),
      icon: "clipboard-list",
      key: "checkin",
      route: "/checkin"
    },
    {
      name: t('view.dashboard.sidebar.navigation.trading'),
      icon: "trading",
      key: "trading",
      route: "/trading",
      children: [
        {
          name: t('view.dashboard.sidebar.navigation.platform_credentials'),
          icon: "key",
          key: "trading",
          route: "/trading/platform"
        },
      ]
    },
    {
      name: t('view.dashboard.sidebar.navigation.settings'),
      icon: "folder",
      key: "settings",
      route: "/settings",
      children: [
        {
          name: t('view.dashboard.sidebar.navigation.settings_accounts'),
          key: "settings",
          route: "/settings/profile"
        },
        {
          name: t('view.dashboard.sidebar.navigation.settings_subscriptions'),
          key: "settings",
          route: "/settings/subscriptions"
        },
        {
          name: t('view.dashboard.sidebar.navigation.settings_configuration'),
          key: "settings",
          route: "/settings/configuration"
        },
      ]
    },
    ...(store.state.dashboard.version == '0.2.0') ? [
      {
        name: "Trading Plan",
        icon: "trading",
        route: "/trading/plan",
        isPro: true
      },
      {
        name: "Journal",
        icon: "",
        route: "/trading/journal",
        isPro: true
      },
      {
        name: "Performance Analytics",
        icon: "",
        route: "/trading/performance",
        isPro: true
      },
      {
        name: "Trading Room",
        icon: "",
        route: "/trading/room",
        isPro: true
      },
      {
        name: "Risk Desk",
        icon: "",
        route: "/trading/risk",
        isPro: true
      },
    ] : []
  ])
</script>

<style scoped>
  .modules a {
    color: var(--color-brand);
  }
  .sidebar {
    background: #000;
    width: 256px;
    flex: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 16;
    padding: 4px;
  }
  .sidebar-icon {
    background: linear-gradient(315deg,#205dff 0%,#20c4ff 100%);
    box-shadow: 0 4px 8px #66f3;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
  .sidebar-item {
    background: transparent;
    padding: 16px;
    border-radius: 16px;
    color: #fff;
    display: inline-block;
    width: 100%;
  }
  .sidebar-item:hover {
    background: #121418;
  }
  .sidebar-item.router-link-exact-active {
    background: rgb(34 93 255 / 16%);
  }
  .sidebar-item-children {
    padding-left: 30px;
  }
  .sidebar-item-children .sidebar-item {
    padding: 12px 16px;
  }
  .sidebar-item-children .sidebar-item:hover {
    background: #2b2d31;
  }
  .sidebar-item-trading .sidebar-icon {
    background: linear-gradient(315deg,#4CAF50 0%,#8BC34A 100%);
  }
  .sidebar-item-settings .sidebar-icon {
    background: linear-gradient(315deg,#9E9E9E 0%,#cbcbcb 100%);
  }

  .fixed-sidebar {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 512px;
    background: #fff;
    z-index: 16;
    height: 100vh;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 4px;
    border-left: 1px solid rgb(231, 231, 231);
  }
  .fixed-sidebar-nav {

  }
  .journal-notes textarea {
    height: calc(100vh - 64px);
    overflow: auto;
    white-space: nowrap;
    background: #2f3842;
    color: #fff;
    padding: 8px;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
  }
</style>