<template>
  <div class="sign-up-view container">
      <div class="form card grid gap-1-5">
        Sign up
        <Form :schema="formSchema" v-model="model" @submit="submit"></Form>

      <div class="text-center" v-if="$route.query.login !== 'false'">Have an account already? <router-link class="strong" to="/login">Login</router-link></div>
      </div>
  </div>
</template>

<script setup>
  import { ref } from "vue"
  import { useStore } from "vuex"
  import { useRoute } from "vue-router"
  import S2 from "@/components/S2"
  import DonationForm from "@/components/DonationForm"

  const store = useStore()
  const route = useRoute()

  const model = ref({
    email: "",
  })
  const submitting = ref(false)
  const errors = ref(null)

  const submit = () => {
    if (submitting.value) return
    store.dispatch("auth/emailRegister", {...model.value, source: "uni_email_signup"})
    .then(() => {
      submitting.value = false;

      if (route.query.redirect) {
        window.location = route.query.redirect
      }
    })
    .catch((err) => {
      errors.value = err.response.data.errors;
      submitting.value = false;
    });
  }

  const submitText = route.query.btn ? route.query.btn : "Sign Up"

  const formSchema = ref(`{
  "rows": [
    {
      "columns": [
        {
          "fields": [
            {
              "type": "email",
              "label": "E-Mail address",
              "name": "email",
              "placeholder": "your@email.com",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "checkbox",
              "label": "I have read and understood the Privacy Policy and I agree to the Terms & Conditions",
              "name": "terms_conditions",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "submit",
              "label": "${submitText}"
            }
          ]
        }
      ]
    }
  ]
}
`);
</script>

<style scoped>
  .sign-up-view {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sign-up-view .form {
    width: 100%;
    max-width: 500px;
    margin: auto;
  }

  .sign-up-view .submit {
    margin-top: 24px;
  }
</style>