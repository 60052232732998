<template>
	<div class="sidebar-layout">
		<div class="sidebar" :class="{'expanded':_expanded}" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
			<router-link :class="{'is-pro-feature': item.proFeature}" :to="item.path" v-for="item in items">
	          <div style="display: flex;align-items: center;gap: 8px;">
	            <div class="nav-icon">
	            	<template v-if="item.icon">
	            		<Icon :icon="item.icon" :size="19"></Icon>
	            	</template>
	            	<template v-else>{{ item.name[0] }}</template>
	            </div>
	            <UiRow align="center" gap="1" style="font-size: 19px;font-weight: 500;line-height: normal;letter-spacing: -1.14px;" v-if="_expanded">
	            	<div class="item-name">{{ item.name }}</div>
	            	<div style="    font-size: 14px;
    color: rgb(255 255 255);
    background: linear-gradient(315deg,#9E9E9E 0%,#cbcbcb 100%);
    padding: 2px 5px;
    width: auto;
    display: inline-block;
    border-radius: 6px;" v-if="item.proFeature">Pro</div>
	            </UiRow>
	          </div>
	        </router-link>
		</div>
		<div style="width: 100%;">
			<slot></slot>
			<UiHeadline size="m" style="margin-bottom: 16px;" v-if="currentItem && currentItem.name">{{ currentItem.name }}</UiHeadline>
			<!--
			<UiHeadline style="margin-bottom: 42px;" v-if="currentItem && currentItem.name">{{ currentItem.name }}</UiHeadline>
			-->
			<router-view></router-view>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useRoute } from "vue-router"
	import { Icon } from "../../icons"

	const _expanded = ref(false)

	const props = defineProps({
		title: String,
		items: {
			type: Array,
			default: () => []
		},
		expanded: Boolean
	})

	if (props.expanded) {
		_expanded.value = true
	}

	const route = useRoute()

	const currentItem = computed(() => {
		return props.items.filter(i => i.path === route.path)[0]
	})

	const onMouseOver = () => {
		_expanded.value = true
	}

	const onMouseLeave = () => {
		if (props.expanded) return
		_expanded.value = false
	}
</script>

<style scoped>
	.sidebar {
		display: none;
		flex: none;
		background: #F6F7F7;
		border-radius: 16px;
		height: fit-content;
		position: sticky;
    	top: 77px;
	}
	.sidebar a {
		color: var(--color-brand);
		border-radius: 16px;
		padding: 10px 24px 10px 10px;
		cursor: pointer;
		display: block;
		color:#000;
		width: 100%;
	}
	.sidebar a:hover {
		background: #e5e6e6;
	}
	.sidebar a.router-link-exact-active {
		color: var(--color-brand);
		background: #e5e6e6;
	}
	.sidebar .nav-icon {
		width: 36px;height: 36px;border-radius: 10px;background: #fff;font-size: 19px;font-weight: 500;display: flex;justify-content: center;align-items: center;
	}
	.expanded {
		width: auto;
	}
	.darkmode .sidebar {
		background: #2F3235;
	}
	.darkmode .sidebar a {
		color: #fff;
	}
	.darkmode .sidebar .nav-icon {
		background: transparent;
		color: #fff;
	}
	@media (min-width: 768px) {
		.sidebar-layout {
			display: flex;
			gap: 71px;
		}
		
		.sidebar {
			display: block;
		}
	}
	.is-pro-feature .item-name {
		opacity: .5;
	}
</style>