<template>
  <div class="footer">
      <div class="container">
        <div class="col-1">
          <div>Trading University</div>
          <small>by Purdia Capital LLC</small>
          <br>
          <br>
          <small>651 N Broad St, Suite 201<br>Middletown, DE 19709 USA</small>
          <br>
          <small>info@purdia.com</small>
        </div>
        <div class="col-2">
          <!-- <ul class="links">
            <li><strong>Member</strong></li>
            <li><router-link to="/volunteer">Volunteer</router-link></li>
          </ul>
          <ul class="links">
            <li><strong>Links</strong></li>
            <li><router-link to="/projects">Projects</router-link></li>
            <li><router-link to="/about">About Us</router-link></li>
            <li><router-link to="/newsletter">Newsletter</router-link></li>
            <li><router-link to="/blog">Blog</router-link></li>
            <li><router-link to="/contact">Contact</router-link></li>
          </ul> -->
          <ul class="links">
            <li><strong>Legal</strong></li>
            <li><router-link to="/terms-and-conditions">Terms and Conditions</router-link></li>
            <li><router-link to="/privacy">Privacy Policy</router-link></li>
            <li><router-link to="/risk-disclosure">Risk Disclosure</router-link></li>
          </ul>
        </div>
      </div>
    </div>
</template>