<template>
	<UiBack class="mb-4" route="/settings/subscriptions" text="Subscriptions"></UiBack>

	<UiCard>
		<!-- Headline -->
		<UiHeadline class="mb-8" size="m">{{ $t('view.settings.subscription.title') }}</UiHeadline>

  	<SubscriptionListItem v-bind="subscription" v-if="!canceled"></SubscriptionListItem>

  	<div class="mt-8">
  		<InlineMessage severity="success" v-if="canceled">Your subscription has been successfully canceled.</InlineMessage>
  		<UiButton color="danger" @click="show = true" v-else>{{ $t('view.settings.subscription.cancel_subscription') }}</UiButton>
  	</div>
</UiCard>
<UiModal :title="$t('view.settings.subscription.cancelation_modal.title')" v-if="show" @close="show = false">
	<br>
	<p>
		Traders need an active subscription in order to trade their evaluation account. Canceling your subscription will revoke trading permission. This action cannot be undone.
	</p>
    	<UiRow gap="1" style="margin-top: 64px;">
    		<UiButton full color="black" @click="show = false">{{ $t('view.settings.subscription.cancelation_modal.quit') }}</UiButton>
    		<UiButton full color="inv" @click="cancelSubscription">{{ $t('view.settings.subscription.cancelation_modal.confirm') }}</UiButton>
    	</UiRow>
    </UiModal>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"
	import { useRoute } from "vue-router"
	import InlineMessage from "primevue/inlinemessage"
	import SubscriptionListItem from "@/components/subscription/SubscriptionListItem"

	const store = useStore()
	const route = useRoute()
	const show = ref(false)
	const canceled = ref(false)

	const subscription = computed(() => store.getters['dashboard/subscription'])

	store.dispatch("dashboard/getSubscriptions")
	store.dispatch("dashboard/setSubscription", route.params.subscriptionId)

	const cancelSubscription = () => {
		store.dispatch("dashboard/cancelSubscription", {
			subscriptionId: route.params.subscriptionId
		}).then(() => {
			show.value = false
			canceled.value = true

			store.dispatch("dashboard/setSubscription", route.params.subscriptionId)
			store.dispatch("dashboard/getSubscriptions")
		})
	}
</script>