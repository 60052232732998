<template>
	<div class="s-centered">
		<div class="container text-center">
			<template v-if="$route.query.status == 'success'">
				<h1>Email Verified Successfully!</h1>
				<br>
				<p>
					Your email has been successfully verified. You can now access all the features of our website.
				</p>
				<br>
				<br>
				<router-link class="btn" to="/login">Login</router-link>
			</template>
			<template v-else>
				<h1>Invalid or Expired Link</h1>
				<br>
				<p>
					The link you clicked on seems to be invalid or has expired.
				</p>
				<br>
				<br>
				<router-link class="btn" to="/login">Login</router-link>
			</template>
		</div>
	</div>
</template>