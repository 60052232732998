<template>
	<!-- Headline -->
	<UiHeadline style="margin-bottom: 42px;">Updates</UiHeadline>

	<UiCol gap="4">
		<div class="version" v-for="version in versions">
		    <UiHeadline size="m" bold>{{ version.title }}</UiHeadline>

		    <UiRow gap="4" style="margin-top: 16px">
		    	<div>
				    <UiGridTile>
				    	<UiHeadline size="s" bold>What's new?</UiHeadline>
					    <ul>
					        <li v-for="change in version.changes"><UiRow align="center" gap="2">- {{ change.title }} <UiBadge v-if="change.badge" size="s">{{ change.badge }}</UiBadge></UiRow></li>
					    </ul>
					</UiGridTile>
				</div>
				<iframe width="560" height="315" :src="version.updateVideoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen v-if="version.updateVideoUrl"></iframe>
			</UiRow>
		</div>
	</UiCol>

	<UiButton href="https://us10.list-manage.com/survey?u=c8e25d173ca3b3ba45928d986&id=d45e7b3ef3&e=*|UNIQID|*" target="_blank" style="margin-top: 71px;">Give Us Feedback</UiButton>
</template>

<script setup>
	import { ref } from "vue"

	const versions = ref([
		{
			title: "Version 2.0 (15-Jan-2024)",
			changes: [
				{
					title: "Improved user interface",
					badge: "New"
				},
				{
					title: "Trading Account Performance Chart",
					badge: "New"
				},
				{
					title: "Manage subscription inside your dashboard",
					badge: "New"
				},
			],
			updateVideoUrl: ""
		},
		{
			title: "Coming Soon - Version 2.1 (01-Feb-2024)",
			changes: [
				{
					title: "Trading Desk"
				},
			],
			updateVideoUrl: ""
		}
	])
</script>