<template>
  <LayoutUser>
    <div class="password-forgot-view container">
        <div class="form card grid gap-1-5">
          <UiHeadline size="m">Forgot Password</UiHeadline>
          <UiLabel>Enter your email below and we'll send you a link to get back into your account</UiLabel>

          <Form :schema="formSchema" v-model="model" @submit="submit" :errors="errors" :submitting="submitting"></Form>
          <div class="text-center">Remeber your password? <router-link to="/login"><strong>Login</strong></router-link></div>
        </div>
    </div>
  </LayoutUser>
</template>

<script setup>
  import { ref } from "vue"
  import { useStore } from "vuex"
  import { useRouter } from "vue-router"
  import S2 from "@/components/S2"
  import { LayoutUser } from "@lib/components"

  const store = useStore()
  const router = useRouter()
  const model = ref({
    email: ""
  })
  const submitting = ref(false)
  const errors = ref(null)

  const submit = () => {
    if (submitting.value) return
    submitting.value = true;
    store.dispatch("auth/resetPassword", model.value)
    .then((res) => {
      submitting.value = false;

      router.push('/password/forgot/success')
    })
    .catch((err) => {
      errors.value = err.response.data.errors;
      submitting.value = false;
    });
  }

  const formSchema = ref(`{
  "rows": [
    {
      "columns": [
        {
          "fields": [
            {
              "key": "email",
              "type": "email",
              "label": "E-Mail address",
              "name": "email",
              "placeholder": "",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "submit",
              "label": "Submit"
            }
          ]
        }
      ]
    }
  ]
}
`);
</script>

<style scoped>
  .password-forgot-view {
    min-height: calc(100vh - var(--user-layout_padding-top));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .password-forgot-view .form {
    width: 100%;
    max-width: 500px;
    margin: auto;
  }

  .password-forgot-view .submit {
    margin-top: 24px;
  }
</style>