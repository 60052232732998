<template>
	<UiRow gap="2">
		<UiCard style="max-width: 256px;" align="center" v-for="product in availableActions">
			<UiHeadline size="s">{{ product.title }}</UiHeadline>
			<UiRow class="mt-2" align="center" gap="1">
				<img src="/img/icons/dollar.png" style="height: 24px;">
				<strong>{{ product.price }}</strong>
			</UiRow>
			<UiButton class="mt-4" color="primary" :to="product.path" full v-if="product.path">Earn Credits</UiButton>
			<UiButton class="mt-4" color="primary" :href="product.link" target="_blank" full v-if="product.link">Earn Credits</UiButton>
		</UiCard>
	</UiRow>
</template>

<script setup>
	const availableActions = [
		{
			title: "Video Testimonial",
			price: 1,
			link: "https://testimonial.to/purdia-capital-llc"
		},
		{
			title: "Invite a Friend",
			price: 1,
			path: "/module/65bcc1dd8ee854f8b03d1209"
		},
	]
</script>