import http from "./http";
import cookie from "./cookie";

const urlParams = new URLSearchParams(window.location.search);

/* Tracking */
const uniqueId = () => {
  const dateString = Date.now().toString(36);
  const randomness = Math.random().toString(36).substr(2);
  return dateString + randomness;
};

let tracked = [];

const track = (event, id, data) => {
  tracked = [];
  let session_id = cookie.get("session_id");
  let campaign_id = cookie.get("tc_id"); //trackingCampaign_id
  let referral = cookie.get("rff");
  if (urlParams.get("c")) {
    campaign_id = urlParams.get("c");
    cookie.set("tc_id", campaign_id);
  }
  if (urlParams.get("t")) {
    session_id = urlParams.get("t");
    cookie.set("session_id", session_id);
  }
  const time = Date.now();
  if (session_id) {
    Date.now();
    tracked.push({
      event,
      id,
      session_id,
      time,
      referral,
      data,
    });
  } else {
    session_id = uniqueId();
    cookie.set("session_id", session_id);
    tracked.push({
      event,
      id,
      session_id,
      time,
      referral,
      data,
    });
  }

  http.post("/mylokals/v1/t", {
    e: tracked[0].event,
    r: tracked[0].id,
    t: tracked[0].time,
    a: process.env.VUE_APP_NAME,
    c: campaign_id ? campaign_id : "AB",
    d: tracked[0].data,
    s: tracked[0].session_id,
    rf: tracked[0].referral,
    m: {
      location: window.location.href,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      screen_width: window.screen.width,
    },
  });
};

const trackList = [
  "cta_lp-hero-start-eval",
  "cta_lp-the-eval-start-eval",
  "cta_lp-plans-start-advanced-eval",
  "cta_lp-plans-start-pro-eval",
  "cta_lp-play-intro-video",
  "cta_lp-view-all-reviews",
  "cta_lp-view-all-reviews-logo",
  "cta_app-start-new-eval",
];

export { track, trackList };

document.addEventListener("click", (event) => {
  if (trackList.includes(event.target.dataset.trackId)) {
    track("click", event.target.dataset.trackId);
  }
});
