<template>
  <div class="sign-up-view container">
      <div class="form card grid gap-1-5">
        <template v-if="!completed">
        Apply
      <div class="row gap-1">
        <div class="form-field col-6">
          <label>First name</label>
          <input>
        </div>
        <div class="form-field col-6">
          <label>Last name</label>
          <input>
        </div>
      </div>

      <div>
        <div class="form-field">
          <label>E-Mail address</label>
          <input placeholder="your@email.com">
        </div>
      </div>

      <div>
        <div class="form-field">
          <label>Password</label>
          <input>
        </div>
      </div>

      <div>
        <div class="form-field">
          <label>Confirm Password</label>
          <input>
        </div>
      </div>

      <div>
        <div class="form-field">
          <label>Your background</label>
          <textarea placeholder="Do you have any experience in the non-profit sector?"></textarea>
        </div>
      </div>

      <div>
        <div class="form-field">
          <label>Your why</label>
          <textarea placeholder="What drives you to join our cause?"></textarea>
        </div>
      </div>

      <label>
        <input type="checkbox">
        I have read and understood the <router-link to="/privacy" target="_blank">Privacy Policy</router-link> and I agree to the <router-link to="/terms" target="_blank">Terms & Conditions</router-link>
      </label>

      <div class="btn btn-l btn-full submit" @click="completed = true">Apply Now</div>

      <div class="text-center">Already a member? <router-link class="strong" to="/login">Login</router-link></div>
      </template>

      <div class="success" v-if="completed">
        <img src="/img/icons/icon-test.svg">
        <h3>Application sent</h3>
        <p>You will receive a response within 1-3 days.</p>
      </div>
      </div>
  </div>
</template>

<script setup>
  import { ref } from "vue"
  import S2 from "@/components/S2"
  import DonationForm from "@/components/DonationForm"

  const completed = ref(false)
</script>

<style scoped>
  .sign-up-view {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sign-up-view .form {
    width: 100%;
    max-width: 500px;
    margin: auto;
  }

  .sign-up-view .submit {
    margin-top: 24px;
  }

  .success {
    text-align: center;
  }

  .success h3 {
    font-weight: 500;
    line-height: 30px;
    font-size: 20px;
    color: #101828;
    margin-top: 20px;
  }

  .success p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: auto;
    margin-top: 8px;
    max-width: 405px;
  }

  .success img {
    height: 48px;
    width: 48px;
  }
</style>