<template>
  <div class="s-centered container">
      <div class="form card grid gap-1-5">
        <ListItem2 :item="item"></ListItem2>
        <div class="btn btn-l btn-full submit">Book Now</div>
      </div>
  </div>
</template>

<script setup>
  import { ref } from "vue"
  import S2 from "@/components/S2"
  import ListItem2 from "@/components/ListItem2"

  const item = ref({
      badge: "Free Session",
      date: "20m",
      title: "Coach Jonas",
      text: `<strong>What to Expect</strong> in Your First Free Coaching Session:
            <br><strong>- Uncover Your Unique Trading Personality:</strong> <small>Discover the trading style that best suits your personality.</small>
            <br><strong>- Diagnose Your Trading Challenges:</strong> <small>We'll dive deep into your trading history and habits to pinpoint specific areas where you're facing difficulties.</small>
            <br><strong>- Develop a Tailored Action Plan:</strong> <small>Together, we'll outline the initial steps of a personalized strategy to address your challenges. This plan is just the beginning of your journey to trading success.</small>`,
      imgUrl: "https://media.licdn.com/dms/image/C4E03AQH7JCkvreM4aw/profile-displayphoto-shrink_800_800/0/1660524148342?e=2147483647&v=beta&t=3IWf7kyoXxLk6oNV9wp0mXVbRNLNZajP9T9Vz8_9A8Y"
    })
</script>