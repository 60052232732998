<template>
		<!-- Back:Mobile -->
	<div class="mobile">
		<UiBack route="/settings" text="Back" fixed></UiBack>
	</div>

	<UiCard>
		<!-- Headline -->
		<div class="mb-8">
			<UiHeadline class="mb-4" size="m">Credits</UiHeadline>
			<UiLabel>Collect reset credits by inviting friends.</UiLabel>
		</div>
		<UiCol class="mt-4" v-if="$store.state.dashboard.balance">
			<div class="mb-2">Available Credits</div>
			<UiHeadline size="m">
				<img src="/img/icons/dollar.png" style="height: 32px;">
				{{ $store.state.dashboard.balance.balance }}
			</UiHeadline>
		</UiCol>

		<div class="mt-8 mb-2">
		<div class="mb-2">Earn Credits</div>
		<EarnCredits/>
	</div>

	<div class="mt-8 mb-2">
		<div class="mb-2">Use Credits</div>
		<UiRow gap="2">
			<UiCard style="max-width: 256px;" align="center" v-for="product in availableProducts">
				<UiHeadline size="s">{{ product.title }}</UiHeadline>
				<UiRow class="mt-2" align="center" gap="1">
					<img src="/img/icons/dollar.png" style="height: 24px;">
					<strong>{{ product.price }}</strong>
				</UiRow>
				<UiButton class="mt-4" :color="!$store.state.dashboard.balance || $store.state.dashboard.balance.balance < product.price ? 'disabled' : ''" :disabled="!$store.state.dashboard.balance || $store.state.dashboard.balance.balance < product.price" :to="product.path" full>Use Credits</UiButton>
			</UiCard>
		</UiRow>
	</div>

	<UiHeadline class="mt-8 mb-2" size="s">Recent Transactions</UiHeadline>
	<UiList style="max-width: 512px;" v-if="transactions.length">
		<UiGridTile :to="`/settings/subscriptions/${transaction.id}`" v-for="transaction in transactions">
			<UiRow justify="between">
				<div>
					<small>{{ transaction._createdAt }}</small>
					<div>{{ transaction.type }}</div>
				</div>
				<div>{{ transaction.amount }}</div>
			</UiRow>
		</UiGridTile>
	</UiList>
	<UiSkeleton style="width: 100%;max-width: 512px;border-radius: 16px;" height="44px" v-else-if="loading"></UiSkeleton>
	<UiGridTile v-else>
		No Transactions
	</UiGridTile>
</UiCard>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"
	import SubscriptionListItem from "@/components/subscription/SubscriptionListItem"
	import EarnCredits from "@/components/EarnCredits"

	const store = useStore()
	const loading = ref(false)
	const transactions = ref([])

	const subscriptions = computed(() => store.state.dashboard.subscriptions)

	loading.value = true
	store.dispatch("dashboard/getSubscriptions").then(() => {
		loading.value = false
	})

	store.dispatch("dashboard/getBalanceTransactions").then((res) => {
		transactions.value = res.data.items
	})

	const basicBenefits = ['Evaluation Dashboard']
	const proBenefits = ['Evaluation Dashboard', 'Pro Trading Room', 'News & Events', 'Journal', 'Performance', 'Economical Calendar', 'Risk Desk']
	const moreProBenefits = ['Pre Trading Checklist', 'Psychological Evaluation']

	const availableProducts = [
		{
			title: "Free Account Reset",
			price: 1,
			path: "/?reward_free_reset=true",
		},
	]
</script>