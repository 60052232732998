<template>
	<UiHeadline style="margin-bottom: 42px;">{{ $t('view.trading_trade.title') }}</UiHeadline>

	<UiRow align="center" gap="4" style="margin-bottom: 42px;">
		<!-- Back -->
		<UiBack :route="`/trading/accounts/${$route.params.accountId}/days/${$route.params.date}`" :text="$t('view.trading_trade.back_button')"></UiBack>
		|
		<!-- Date -->
	  <UiHeadline size="m" bold  v-if="trade">{{ trade._time }}</UiHeadline>
  </UiRow>
	
	<TradingDayTradeListItem v-bind="trade" style="margin-bottom: 71px;"></TradingDayTradeListItem>

	<div>
		<!-- <a @click="addTag">Add Tags</a> -->

		<UiRow gap="4">
			<div>
				<UiHeadline size="m" style="margin-bottom: 16px;">{{ $t('view.trading_trade.tags.title') }}</UiHeadline>
				<UiRow gap="2">
					<UiGridTile v-for="(tag, index) in tagsModel" v-if="tagsModel.length">
						<UiRow gap="1">
							<div>{{ tag }}</div>
							<a @click="removeTag(index)"><small>{{ $t('view.trading_trade.tags.delete') }}</small></a>
						</UiRow>
					</UiGridTile>
					<UiGridTile v-else>
						{{ $t('view.trading_trade.tags.no_tags') }}
					</UiGridTile>
				</UiRow>

				<div style="max-width: 512px;margin-top: 16px;">
				  	<div class="form-row">
						  <div class="form-field">
						  		<label>{{ $t('view.trading_trade.tags.new_tag') }}</label>
						  		<UiRow gap="2">
						      <input
						        type="text"
						        :placeholder="$t('view.trading_trade.tags.tag_field.placeholder')"
						        v-model="tagModel"
						        @keyup.enter="addTag"
						      />
						      <UiButton color="black" v-if="tagModel" @click="addTag">{{ $t('view.trading_trade.tags.add') }}</UiButton>
						  </UiRow> 
						      <p v-if="error" style="color: #FF2C46;margin-top: 4px">{{ error }}</p>
						  </div>
					  </div>
				  </div>
			  </div>
			</UiRow>

			<UiRow style="margin-top: 42px;">
			<div style="width: 100%;max-width: 512px;">
			  	<UiHeadline size="m" style="margin-bottom: 16px;">{{ $t('view.trading_trade.notes.title') }}</UiHeadline>
			  	<div class="form-row">
						  <div class="form-field">
						  		<UiRow gap="2">
						      <textarea
						        type="text"
						        placeholder=""
						        v-model="notesModel"
						      ></textarea>
						  </UiRow> 
						  </div>
					  </div>
			  </div>
			</UiRow>

		  <UiButton @click="updateTrade" style="margin-top: 71px;">
		  	{{ $t('view.trading_trade.update_button') }}
		  </UiButton>
	</div>
</template>

<script setup>
	import { ref, computed, watch } from "vue"
	import { useStore } from "vuex"
	import { useRoute } from "vue-router"
	import TradingDayTradeListItem from "@/components/TradingDayTradeListItem"

	const store = useStore()
	const route = useRoute()
	const tagModel = ref(null)
	const tagsModel = ref([])
	const notesModel = ref(null)

	const trade = computed(() => store.getters["dashboard/trade"])

	store.dispatch("dashboard/getTradingDayTrades", {
		accountId: route.params.accountId,
		date: route.params.date
	})

	watch(() => route.params.tradeId, () => {
		store.dispatch("dashboard/setTradingDayTrade", route.params.tradeId)
	}, {
		immediate: true
	})

	watch(() => trade.value, () => {
		if(trade.value) {
			tagsModel.value = trade.value.tags
			notesModel.value = trade.value.notes
		}
	}, {
		immediate: true,
		deep: true
	})

	const addTag = () => {
		tagsModel.value.push(tagModel.value)
		tagModel.value = null
	}

	const removeTag = (index) => {
		tagsModel.value.splice(index, 1);
	}

	const updateTrade = () => {
		store.dispatch("dashboard/updateTradingDayTrade", {
			accountId:route.params.accountId, 
			date: route.params.date,
			tradeId: route.params.tradeId,
			data: {
				tags: tagsModel.value,
				notes: notesModel.value,
			}
		})
	}
</script>