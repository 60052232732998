<template>
	<S6 title="International Volunteer Opportunities" text="Currently, we have international volunteer opportunities available at ANUO, and these roles offer remote work flexibility." :items="s6Items"></S6>
</template>

<script setup>
	import S6 from "@/components/S6"

	const s6Items = [
    {
      badge: "Creative ideas",
      date: "June 20, 2023",
      title: "Advocate",
      text: `
      	This is a great entry-level volunteer position for someone looking to be part of ANUO. Advocates can operate from anywhere in the world.
      	<br><br>Location: <strong>Ghana</strong>
      	<br>Salary: <strong>Unpaid</strong>
      	<br>Duration: <strong>6-months</strong>
      	<br>Hours: <strong>4-hours per week</strong>
      	<br>Start Date: <strong>New programs begin every month, you choose the month you wish to start</strong>
      	<br><br><strong>Key Responsibilities:</strong>
      	<br>- Serve as an ambassador for the world’s poor. Build awareness of the issues and ways people can help.
      	<br>- Manage and implement fundraising campaigns.
      	<br>- Represent ANUO – attend events and engage people in the cause.
      	<br>- Contact congressional leaders in support of key poverty-reduction programs.
      	<br><br><strong>Qualifications:</strong>
      	<br>- Excellent overall communication skills: oral, written, presentation.
      	<br>- Ability to self-manage and prioritize assignments.
      `,
      btn: {
        text: "Apply now",
        to: "/volunteer/apply"
      }
    },
    {
      badge: "Writer",
      date: "June 20, 2023",
      title: "Advocate",
      text: `
      	<br><br>Location: <strong>Worldwide</strong>
      	<br>Salary: <strong>Unpaid</strong>
      	<br>Duration: <strong>3-months</strong>
      	<br>Hours: <strong>12-hours per week</strong>
      	<br>Start Date: <strong>New programs begin every month, you choose the month you wish to start</strong>
      	<br>- Write one article per week. Writing will focus on quality, but also improving search ranking.
      	<br>- Assist with advocacy and fundraising.
      	<br><br><strong>Qualifications:</strong>
      	<br> Strong research and writing skills. Must be able to work independently and meet deadlines with very little supervision. Experience writing SEO-friendly content is helpful, but not required.
      	<br><br><strong>Benefits:</strong>
      `,
      btn: {
        text: "Apply now",
        to: "/volunteer/apply"
      }
    }
  ]
</script>