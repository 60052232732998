import io from "socket.io-client";
// import store from "../store";

let config = {};

// if (store.state.user.user) {
//   config.query = "userId=" + store.state.user.user.id;
// }

const socket = io.connect(process.env.VUE_APP_SOCKET_URL, config);

export default socket;
