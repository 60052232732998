<template>
  <LayoutUser>
    <div class="password-forgot-view container">
        <div class="form card grid gap-1-5">
          <UiHeadline size="m">Enter a new Password</UiHeadline>

          <Form :schema="formSchema" v-model="model" @submit="submit" :errors="errors" :submitting="submitting"></Form>
        </div>
    </div>
  </LayoutUser>
</template>

<script setup>
  import { ref } from "vue"
  import { useStore } from "vuex"
  import { useRouter, useRoute } from "vue-router"
  import S2 from "@/components/S2"
  import { LayoutUser } from "@lib/components"

  const store = useStore()
  const router = useRouter()
  const route = useRoute()
  const model = ref({
    password: "",
    repeatPassword: ""
  })
  const submitting = ref(false)
  const errors = ref(null)

  const submit = () => {
    if (submitting.value) return
    submitting.value = true;
    store.dispatch("auth/setNewPassword", {
      ...model.value,
      hash: route.query.h
    })
    .then((res) => {
      submitting.value = false;

      router.push('/password/reset/success')
    })
    .catch((err) => {
      errors.value = err.response.data.errors;
      submitting.value = false;
    });
  }

  const formSchema = ref(`{
  "rows": [
    {
      "columns": [
        {
          "fields": [
            {
              "key": "password",
              "type": "password",
              "label": "Password",
              "name": "password",
              "placeholder": "",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "repeatPassword",
              "type": "password",
              "label": "Repeat Password",
              "name": "repeatPassword",
              "placeholder": "",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "submit",
              "label": "Submit"
            }
          ]
        }
      ]
    }
  ]
}
`);
</script>

<style scoped>
  .password-forgot-view {
    min-height: calc(100vh - var(--user-layout_padding-top));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .password-forgot-view .form {
    width: 100%;
    max-width: 500px;
    margin: auto;
  }

  .password-forgot-view .submit {
    margin-top: 24px;
  }
</style>