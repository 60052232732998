<template>
		<!-- Page Title -->
    <!--<UiHeadline style="margin-bottom: 42px;">Account</UiHeadline>-->
    <!-- TabBar -->
    <div class="sidebar" style="margin-bottom:42px;" v-if="false">
			<router-link :to="item.route" style="border-radius: 16px;padding: 10px;cursor: pointer;display: block;" v-for="item in navItems">
	          <div style="display: flex;align-items: center;gap: 8px;">
	            <div class="nav-icon">
	            	<template v-if="item.icon">
	            		<Icon :icon="item.icon" :size="24"></Icon>
	            	</template>
	            	<template v-else>{{ item.name[0] }}</template>
	            </div>
	            <div style="font-size: 19px;font-weight: 500;line-height: normal;letter-spacing: -1.14px;">{{ item.name }}</div>
	          </div>
	        </router-link>
	        <UiSyncSpinner :syncing="syncing" :status="syncingStatus"></UiSyncSpinner>
		</div>

   	<div>
   		<!-- Router -->
   		<router-view></router-view>
   		<template v-if="error">{{ $t(error) }}</template>
    </div>
</template>

<script setup>
	import { ref, computed, onUnmounted } from "vue"
	import { useStore } from "vuex"
	import { useRoute, useRouter } from "vue-router"

	const store = useStore()
	const route = useRoute()
	const router = useRouter()
	const syncing = ref(false)
	const syncingStatus = ref(null)
	const loading = ref(true)
	const error = ref(null)

	const navItems = ref([
		{
			name: "Overview",
			icon: "trading",
			route: `/trading/accounts/${route.params.accountId}/overview`
		},
		{
			name: "Trading Days",
			icon: "trading",
			route: `/trading/accounts/${route.params.accountId}/days`
		},
		{
			name: "Risk",
			icon: "trading",
			route: `/trading/accounts/${route.params.accountId}/risk`
		},
	])

	store.dispatch("dashboard/setAccount", route.params.accountId)

	if (!store.state.dashboard.accounts.length) store.dispatch("dashboard/getTradingAccounts")

	const fetchAccount = async () => {
		return new Promise(resolve => {
			syncingStatus.value = 'Fetching Account'
			store.dispatch("dashboard/getTradingAccount", route.params.accountId).then((res) => {

		      resolve()
		    }).catch((err) => {
		    	error.value = err.response.data.key
		    })
		})
	}

	const fetch = () => {
			fetchAccount()

			syncing.value = true
			syncingStatus.value = 'Syncing with Broker'
	    store.dispatch("dashboard/syncTradingAccount", route.params.accountId).then((res) => {
	      fetchAccount().then(() => {
	      	syncingStatus.value = "Account up to date"
	      	syncing.value = false
	      })
	    }).catch(() => {
	    	syncingStatus.value = "Update failed, please try again in 10 minutes"
	    	syncing.value = false
	    })
	  }

	  fetch()


	// const interval = setInterval(() => {
	// 	fetch()
	// }, 10000)

	// onUnmounted(() => {
	// 	clearInterval(interval);
	// })

	 const onSelectDate = (date) => {
		router.push(`/trading/accounts/${account.value.id}/days/${date}`)
	}
</script>

<style scoped>
	.sidebar {
		flex: none;
		height: fit-content;
		display: flex;
		gap: 4px;
	}
	.sidebar a {
		color:#000;
		border-radius: 16px;
	}
	.sidebar a.router-link-active {
		color: var(--color-brand);
		background: #F6F7F7;
	}
	.sidebar .nav-icon {
		width: 36px;height: 36px;border-radius: 10px;background: #fff;font-size: 19px;font-weight: 500;display: flex;justify-content: center;align-items: center;
	}
	.expanded {
		width: auto;
		padding-right: 24px;
	}
	.darkmode .sidebar {
		background: #2F3235;
	}
	.darkmode .sidebar a {
		color: #fff;
	}
	.darkmode .sidebar .nav-icon {
		background: transparent;
		color: #fff;
	}
</style>