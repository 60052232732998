<template>
	<div>
		<div class="aspect-ratio">
		    <div class="content">
		        <Slide :slide="slide" v-if="slide" :canPlay="!isPlaying" @click="play"></Slide>
		    </div>
		</div>
		<div class="slides-preview">
			<div class="slides-preview-scroll">
				<Slide :slide="slide" v-if="slide" v-for="(slide, index) in presentation.slides" @click="currentSlide = index" isPreview></Slide>
			</div>
		</div>
		<br>
		<div>
			{{ script }}
		</div>
	</div>
</template>

<script setup>
	import { ref, computed } from "vue"
	import Slide from "./Slide"

	const currentSlide = ref(0)
	const isPlaying = ref(false)

	const props = defineProps({
		presentation: Object
	})

	const slide = computed(() => {
		return props.presentation.slides[currentSlide.value];
	})

	const script = computed(() => {
		return props.presentation.slides[currentSlide.value].script;
	})

	const play = () => {
		isPlaying.value = true
	}

	const presentations = ref(JSON.parse(`{
  "week": 1,
  "title": "Welcome to Week 1 of Our 7-Week Trading Program",
  "slides": [
    {
      "title": "Welcome to Week 1 of Our 7-Week Trading Program",
      "content": "Laying the Foundation for Your Trading Success",
      "visual_hint": "trading_or_number_7_image",
      "script": "Welcome to the first week of our transformative 7-week trading program! This week is all about laying the foundation for your success as a trader."
    },
    {
      "title": "Trading Simulation",
      "content": "Trade in a risk-free simulated environment. Apply your live market strategies here.",
      "visual_hint": "simulation_platform_screenshot",
      "script": "Throughout this week, you’ll be engaging in a simulated trading environment. This is your chance to trade just as you would in the live markets, but without the financial risk."
    },
    {
      "title": "Psychological Assessment",
      "content": "Complete a detailed trading psychology assessment. Understand your unique trading mindset.",
      "visual_hint": "assessment_form_image",
      "script": "Alongside your trading activities, you’ll complete a comprehensive psychological assessment. Understanding your trading psychology is crucial. It helps us tailor our approach to suit your unique strengths and challenges."
    },
    {
      "title": "Daily Performance Reporting",
      "content": "Reflect on your trading decisions daily. Record successes and areas for improvement.",
      "visual_hint": "trading_journal_image",
      "script": "Your daily task is to report your trading performance. This isn’t just about numbers; it’s about reflection. What worked? What didn’t? Why did you make certain decisions?"
    },
    {
      "title": "Consistency is Key",
      "content": "Dedicate daily time for trading and review. Discipline is crucial for success.",
      "visual_hint": "calendar_or_schedule_graphic",
      "script": "Remember, consistency is key. Make sure to set aside dedicated time each day for trading and self-review. This disciplined approach will pay off in the weeks to come."
    },
    {
      "title": "Embarking on Your Journey",
      "content": "Commit to your growth as a trader. Prepare for deeper insights in Week 2.",
      "visual_hint": "inspirational_growth_image",
      "script": "We are excited to embark on this journey with you. Stay committed, stay reflective, and let’s make this first week a strong start to your trading evolution. See you in Week 2, where we dive deeper into your trading strategy and psychology."
    },
    {
      "title": "See You in Week 2",
      "content": "Diving Deeper into Trading Strategy and Psychology",
      "visual_hint": "program_logo_or_week2_teaser",
      "script": "As we conclude Week 1, take a moment to reflect on your journey so far and get ready for Week 2. We'll dive deeper into your trading strategy and psychology, building on the solid foundation you've started this week."
    }
  ]
}
`))
</script>

<style>
	.slide {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		position: relative;
	}

	.slide h1 {
		background: #fff;
    padding: 0.5rem 1rem;
	}

	.slide p {
		background: #fff;
		padding: .5rem;
		display: inline-block;
	}

	.slide .play-button {
		height: 32px;
		width: 32px;
		background: lightgray;
		border-radius: 999px;
		cursor: pointer;
	}

	.slides-preview {
		overflow: auto;
	}

	.slides-preview .slide {
		height: 128px;
	    width: 200px;
	    flex: none;
	   	font-size: 10px; 
	   	border: 1px solid #f3f4f6;
    	border-radius: 16px;
	}

	.slides-preview .slide:hover {
		box-shadow: 0 4px 8px rgba(0,12,43,.05);
		cursor: pointer;
		background: #fafafa;
	}

	.slides-preview .slide h1 {
		font-size: 12px;
	}

	.slides-preview-scroll {
		display: flex;
		gap: .5rem;
		padding-bottom: .25rem;
	}
</style>