<template>
	<UiBack :route="`/trading/accounts/${$route.params.accountId}`"></UiBack>
		<!-- Page Title -->
    <UiHeadline style="margin-bottom: 42px;">Onboarding</UiHeadline>

    <UiCol gap="2" v-if="account">
	    <UiGridTile full>
	    	<UiRow justify="between">
	    		<UiHeadline size="m">1. Pass Evaluation</UiHeadline>
	    		<div>
	    			<UiBadge>Done</UiBadge>
	    		</div>
	    	</UiRow>
	    </UiGridTile>
	    <UiGridTile full>
	    	<UiRow justify="between">
	    		<div>
	    			<UiHeadline size="m" style="margin-bottom: 16px;">2. Onboarding</UiHeadline>
	    			<div style="display: flex;justify-content: space-between;align-items: center;" v-if="!account.fundingStatus.interview">
				    	<div>
					    	<!-- Headline -->
					    	<div style="display: flex;gap: 4px;align-items: center;margin-bottom: 16px;">
							    <div style="color: #000;font-size: 20px;font-style: normal;font-weight: 500;line-height: normal;">
							      Congratulations on completing your evaluation challenge! Before you proceed to the final step of receiving your live trading account, there's an important process we need you to complete: Onboarding.
							      <br>
							      	During the onboarding step, it's crucial that you familiarize yourself with the foundational elements of trading with us. We require that you thoroughly read and understand the following sections on our website:
							      	<br><br>
									<a href="https://purdia.com/rules/funded" target="_blank">Trading Rules</a>: Understand the dos and don'ts of trading on our platform to ensure a smooth and compliant trading experience.
									<br>
									<a href="https://purdia.com/knowledgebase/funding-process-part-two" target="_blank">How It Works</a>: Get a clear overview of the trading process, platform features, and tools available to maximize your trading potential.
									<br>
									<a href="https://purdia.com/faq" target="_blank">FAQ</a>: Find answers to common questions and clarify any doubts you might have about trading, accounts, and our policies.
									<br><br>
									This information is designed to help you navigate through your trading journey effectively and to ensure that you are fully informed about the operational aspects of our platform.
									<br><br>
									By clicking the button below, you confirm that you have read, understood, and agree to abide by the Trading Rules, operational procedures described in the How It Works section, and have reviewed the FAQ. This step is mandatory to move forward to the signing of the agreement and to activate your live trading account.
							    </div>
							</div>
							<UiButton size="l" target="_blank" @click="onConfirmRules">
								I Confirm
							</UiButton>
						</div>
					</div>
	    		</div>
	    		<div>
	    			<UiBadge v-if="account.fundingStatus.interview">Completed</UiBadge>
	    		</div>
	    	</UiRow>
	    </UiGridTile>
	    <UiGridTile full>
	    	<UiRow justify="between">
	    		<div>
	    			<UiHeadline size="m">3. Sign Trader Contract</UiHeadline>
	    			<FundedContract v-if="account.fundingStatus.interview && !account.fundingStatus.agreement"></FundedContract>
	    		</div>
	    		<div>
	    			<UiBadge v-if="account.fundingStatus.agreement">Signed</UiBadge>
	    		</div>
	    	</UiRow>
	    </UiGridTile>
	</UiCol>
</template>

<script setup>
	import { computed } from "vue"
	import { useStore } from "vuex"
	import { useRoute } from "vue-router"
	import FundedContract from "@/components/FundedContract"

	const store = useStore()
	const route = useRoute()

	const account = computed(() => store.getters['dashboard/account'])

	store.dispatch("dashboard/setAccount", route.params.accountId)

	const onConfirmRules = () => {
		store.dispatch("dashboard/confirmFundedRules", {
			accountId: route.params.accountId
		}).then(() => {
			store.dispatch("dashboard/getTradingAccount", route.params.accountId)
		})
	}
</script>