<template>
  <UiGridTile>
    This feature is only available in our Pro Plan and for Live Traders.
  </UiGridTile>
	<UiCol gap="1" class="content" v-if="trades.length && false">
		<div class="list-item">
			<div style="display:flex;">
				<div style="width: 100%;max-width: 128px;">
					Date
				</div>
				<div style="width: 100%;max-width: 128px;">
					Symbol
				</div>
				<div style="width: 100%;max-width: 128px;">
					P&L
				</div>
				<div style="width: 100%;max-width: 128px;">
					Tags
				</div>
				<div style="width: 100%;max-width: 128px;">
					Volume
				</div>
			</div>
		</div>
		<div class="list-item" v-for="trade in trades" clickable>
			<div style="display:flex;">
				<div style="width: 100%;max-width: 128px;">
					{{ trade._date }}
				</div>
				<div style="width: 100%;max-width: 128px;">
					{{ trade.symbol }}
				</div>
				<div :class="{'win':trade.pnl > 0,'loss':trade.pnl < 0,}" style="width: 100%;max-width: 128px;">
					{{ trade.pnl }}
				</div>
				<div style="width: 100%;max-width: 128px;">
					-
				</div>
				<div style="width: 100%;max-width: 128px;">
					{{ trade.qty }}
				</div>
			</div>
		</div>
	</UiCol>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"
	import { useRoute } from "vue-router"

	const store = useStore()
	const route = useRoute()

	const trades = computed(() => store.state.dashboard.trades)

	store.dispatch("dashboard/getTradingAccountTrades", {
		accountId: route.params.accountId
	}).then((res) => {
    }).catch(() => {})
</script>

<style scoped>
	.win {
		color: #30d720;
	}
	.loss {
		color: #FF2C46;
	}
</style>