<template>
  <UiBack :route="`/trading/accounts/${$route.params.accountId}`"></UiBack>
  <!-- Page Title -->
  <UiHeadline style="margin-bottom: 42px;">Onboarding</UiHeadline>
  <div style="display: flex;justify-content: space-between;align-items: center;">
    <div>
      <!-- Headline -->
      <div style="display: flex;gap: 4px;align-items: center;margin-bottom: 71px;">
        <div style="color: #000;font-size: 24px;font-style: normal;font-weight: 500;line-height: normal;">
          Schedule call to complete Onboarding
        </div>
      </div>
      <UiButton href="https://calendly.com/purdia-capital/trading-desk-onboarding" size="l" target="_blank">
        Schedule Onboarding Call
      </UiButton>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue"
import FileUpload from 'primevue/fileupload';
const fileID = ref(null)
const onUploadID = (event) => {
  const file = event.files[0];
  console.log(file)
  fileID.value = file
}

</script>
