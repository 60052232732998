<template>
	<div class="support-ticket">
		<UiRow justify="between" align="center">
			<UiHeadline size="m" icon="clipboard-list">
				<div>Support Ticket</div>
			</UiHeadline>
			<UiBadge :type="STATUS_COLORS[status]">{{ $t(_status) }}</UiBadge>
		</UiRow>
		<div style="margin-top: 8px;">{{ subject }}</div>
		<UiRow justify="between" align="center" style="margin-top: 8px;">
			<div>Ticket #{{ id }}</div>
		</UiRow>
	</div>
</template>

<script setup>
	import { ref } from "vue"

	const STATUS_COLORS = ref({
		2: 'primary',
		3: 'primary',
		4: 'success',
	})

	const props = defineProps({
		id: String,
		subject: String,
		status: String,
		_status: String,
		_createdAt: String,
	})
</script>

<style scoped>
	.support-ticket {
	}
</style>