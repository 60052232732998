<template>
  <div class="marketing-coming-soon container">
  	<!-- Title -->
    <h1>The Ultimate Blueprint for <span>Profitable Trading.</span></h1>

    <!-- Subtitle -->
    <p>You need a Solid Plan. Coming Soon.</p>

    <!-- Form -->
    <div class="form card grid gap-1-5">
      <Form :schema="formSchema" v-model="model" @submit="submit"></Form>
    </div>
  </div>
</template>

<script setup>
  import { ref } from "vue"
  import { useStore } from "vuex"
  import { useRoute } from "vue-router"
  import S2 from "@/components/S2"
  import DonationForm from "@/components/DonationForm"
  import Form from "@/components/Form"

  const store = useStore()
  const route = useRoute()

  const model = ref({
    email: "",
  })
  const submitting = ref(false)
  const errors = ref(null)

  const submit = () => {
    if (submitting.value) return
    store.dispatch("auth/emailRegister", {...model.value, source: "uni_email_signup"})
    .then(() => {
      submitting.value = false;

      window.location = "/waiting-list"
    })
    .catch((err) => {
      errors.value = err.response.data.errors;
      submitting.value = false;
    });
  }

  const formSchema = ref(`{
  "rows": [
    {
      "columns": [
        {
          "fields": [
            {
              "type": "email",
              "label": "",
              "name": "email",
              "placeholder": "Enter your E-Mail address",
              "required": true
            }
          ]
        },
        {
          "fields": [
            {
              "type": "submit",
              "label": "Join Waiting List",
              "classes": "btn-cta"
            }
          ]
        }
      ]
    }
  ]
}
`);
</script>

<style>
  .marketing-coming-soon .form {
  	margin: auto;
  	max-width: 450px;
  	margin-top: 2rem;
  }

  .marketing-coming-soon .form .btn {
  	margin: 0;
  }
</style>