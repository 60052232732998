<template>
  <div class="s-centered container">
      <UiCard v-if="course">
        <Presentation :presentation="course.data"></Presentation>
      </UiCard>
  </div>
</template>

<script setup>
  import { ref } from "vue"
  import { useRoute } from "vue-router"
  import Presentation from "@/components/presentation/Presentation"
  import http from "@/services/http";

  const route = useRoute()
  const course = ref(null)

  http.get(`/v1/crm/${route.params.id}`).then(res => {
    course.value = res.data.item
  })
</script>