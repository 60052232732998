<template>
	<div>
		<UiSkeleton height="512px" v-if="loading && !tradingDays.length"></UiSkeleton>
		<UiCol gap="2" class="content" v-else-if="tradingDays.length">
			<UiGridTile class="list-item">
				<div style="display:flex;">
					<div style="width: 100%;max-width: 128px;">
						Date
					</div>
					<div style="width: 100%;max-width: 128px;">
						PNL
					</div>
					<div style="width: 100%;max-width: 128px;">
						Trades
					</div>
				</div>
			</UiGridTile>
			<UiGridTile class="list-item" v-for="item in tradingDays" @click="$emit('select', item.id)" clickable>
				<div style="display:flex;">
					<div style="width: 100%;max-width: 128px;">
						{{ item._date }}
					</div>
					<div style="width: 100%;max-width: 128px;">
						{{ item._pnlWithFees }}
					</div>
					<div style="width: 100%;max-width: 128px;">
						{{ item.tradesCount }}
					</div>
				</div>
			</UiGridTile>
		</UiCol>
		<UiGridTile v-else>
			{{ $t('trading_days.no_trading_days') }}
		</UiGridTile>
		<UiSyncSpinner :syncing="syncing" :status="syncingStatus" style="margin-top: 16px;"></UiSyncSpinner>
	</div>
	<UiModal title="Trade" v-if="$route.params.date" size="l" @close="$router.push(`/trading/accounts/${$route.params.accountId}`)">
		<TradingDay></TradingDay>

		<UiModal title="Trade" v-if="$route.params.tradeId" size="l" @close="$router.push(`/trading/accounts/${$route.params.accountId}/days/${$route.params.date}`)">
			<TradingDayTrade></TradingDayTrade>
		</UiModal>
	</UiModal>
</template>

<script setup>
	import { ref, computed, getCurrentInstance } from "vue"
	import { useStore } from "vuex"
	import TradingDay from "@/components/TradingDay"
	import TradingDayTrade from "@/components/TradingDayTrade"

	const instance = getCurrentInstance();
  	const t = instance.appContext.config.globalProperties.$t;

	const props = defineProps({
		accountId: String,
	})

	const syncing = ref(false)
	const syncingStatus = ref(null)
	const store = useStore()
	const loading = ref(true)

	const tradingDays = computed(() => store.state.dashboard.tradingDays)

	const fetchTradingDays = async () => {
		return new Promise(resolve => {
			syncingStatus.value = t('trading_days.api.fetching')
			store.dispatch("dashboard/getTradingDays", props.accountId).then((res) => {
		      tradingDays.value = res.data
		      loading.value = false
		      resolve()
		    }).catch(() => {})
		})
	}

	const fetch = () => {
		fetchTradingDays()

		syncing.value = true
		syncingStatus.value = t('trading_days.api.syncing')
	    store.dispatch("dashboard/syncTradingAccountTradingDays", props.accountId).then((res) => {
	      fetchTradingDays().then(() => {
	      	syncingStatus.value = t('trading_days.api.completed')
	      	syncing.value = false
	      })
	    }).catch(() => {
	    	syncingStatus.value = t('trading_days.api.failed')
	      	syncing.value = false
	    })
	}

	fetch()
</script>