<template>
  <div class="nav-wrapper">
    <div class="nav-admin-spectator" v-if="$store.state.auth.adminAccessToken">
      Admin Spectator Mode - <span @click="onSwitchToAdmin">Switch to admin</span>
    </div>
  	<div class="user-nav nav">
      <UiRow align="center" gap="4">
        <router-link to="/">
          <img src="https://cdn.purdia.com/images/logo.svg" style="height: 27px;">
        </router-link>
          <!-- Pro Navigation -->
          <template v-if="$store.state.dashboard.config.pro_navigation_enabled">
            <!-- Clock -->
            <ClockAnalog :timezones="$store.state.dashboard.config.navigation_clock_timezones"></ClockAnalog>
            <!-- Divider for Market open countdown -->
            <div v-if="$store.state.dashboard.config.navigation_clock_timezones.length">|</div>
            <div>
              <div>
                <!--<TradingMarketOpen></TradingMarketOpen>-->
              </div>
            </div>
          </template>
      </UiRow>

          <!-- Menu -->
          <ul class="nav-menu">
            <li v-for="item in items">
                <router-link :class="{'highlighted':item.highlighted}" :to="item.route" v-if="item.route">
                  <!-- <div class="nav-item-icon" v-if="item.icon">
                    <Icon :icon="item.icon" size="20" color="#fff"></Icon>
                  </div>  -->
                  {{ item.name }}<UiBadge type="danger" v-if="item.notification">{{ item.notification }}</UiBadge>
                  <UiBadge type="white" v-if="item.label">{{ item.label }}</UiBadge>
                </router-link>
                <a :href="item.href" v-else-if="item.href">{{ item.name }}</a>
              </li>
          </ul>

          

          <!-- Auth -->
          <ul v-if="$store.state.auth.authenticated">
            <!-- Pro Navigation -->
            <template v-if="false">
              <li class="user-item">
                <UiDropdown v-if="$store.state.auth.authenticated" :items="accountDropdownItems">
                  <div>
                    <div><small>Trading Account</small></div> 
                    <div>PUR3849</div>
                  </div>
                </UiDropdown>
              </li>
            </template>
            <li>
              <router-link to="/settings/points">
                <NavItemBalance></NavItemBalance>
              </router-link>
            </li>
            <li class="user-item">
              <UiDropdown v-if="$store.state.auth.authenticated" :items="accountDropdownItems">
                <UiGridTile v-if="false">
                  <UiCol align="center">
                    <!-- Medals -->
                    <!-- <img src="/img/medal-3.png" style="height: 24px;" v-if="$store.state.user.user.level >= 7">
                    <img src="/img/medal-2.png" style="height: 24px;" v-else-if="$store.state.user.user.level >= 4">
                    <img src="/img/medal-1.png" style="height: 24px;" v-else-if="$store.state.user.user.level >= 1"> --> 
                    <NavItemBalance></NavItemBalance>
                  </UiCol>
                </UiGridTile>
                <UiRow align="center">
                  <!-- Manager Avatar -->
                  <UiAvatar size="40px" :imgUrl="$store.state.user.user.manager.profileImageUrl" style="margin-right: -16px;" v-if="$store.state.user.user.manager"></UiAvatar>

                  <!-- User Avatar -->
                  <UiAvatar class="user-item-avatar" :imgUrl="$store.state.user.user.profileImageUrl"></UiAvatar>
                </UiRow>
                <span class="desktop">{{ $t('navigation.my_account') }}</span>
              </UiDropdown>
            </li>
          </ul>
          <div class="nav-auth" v-else>
            <UiButton to="/login">Login</UiButton>
          </div>
      </div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance } from "vue"
  import { Chart, Clock } from "@lib/components"
  import ClockAnalog from "@/components/ClockAnalog"
  import TradingMarketOpen from "@/components/TradingMarketOpen"
  import NavItemBalance from "@/components/NavItemBalance"
  import { useStore } from "vuex"

  const instance = getCurrentInstance();
  const t = instance.appContext.config.globalProperties.$t;
  const store = useStore()

  if (store.state.auth.authenticated) {
    store.dispatch("dashboard/getBalance")
  }


  const props = defineProps({
    items: Array
  })

  const accountDropdownItems = ref([
    {
      name: t('navigation.my_account.profile'),
      route: "/profile"
    },
    // {
    //   name: t('navigation.my_account.bonus'),
    //   route: "/bonus"
    // },
    {
      name: t('navigation.my_account.settings'),
      route: "/settings"
    },
    {
      name: t('navigation.my_account.sign_out'),
      route: "/signout"
    }
  ])

  const onSwitchToAdmin = () => store.dispatch("admin/switchToAdmin")
</script>

<style>
  .nav-wrapper {
    top: 0px;
    left: 0px;
    z-index: 16;
    position: var(--user-navigation_position);
    width: 100%;
  }
  .user-nav {
    height: var(--var_navigation-height);
    box-shadow: var(--user-navigation_shadow);
    border-bottom: var(--user-navigation_border-bottom);
    border-radius: var(--user-navigation_border-radius);
    padding-left: 256px;
    background: var(--user-navigation_background);
    backdrop-filter: var(--user-navigation_backdrop-filter);
  }

  .nav {
    z-index: 16;
    width: 100%;
    max-width: var(--user-navigation_width);
    margin: auto;
    padding: var(--user-navigation_padding);
    padding-top: var(--user-navigation_padding-top);
  }

  .nav ul {
    color: var(--nav-item_color);
    font-size: var(--user-navigation_font-size);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.38px;
    gap: 4px;
  }

  .nav ul li {
    display: flex;
    align-items: center;
  }

  .nav ul a {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--nav-item_color);
    border-radius: var(--nav-item_border-radius);
    padding: var(--nav-item_padding);
  }

  .nav ul a:hover {
    background: #f0f0f0;
  }

  .nav ul li a.router-link-active {
    border-bottom: var(--nav-item-active_border-bottom);
    color: var(--nav-item-active_color);
    background: var(--nav-item-active_background);
  }

  .nav ul li a.highlighted {
    background: linear-gradient(315deg, rgb(0, 153, 255) 0%, var(--token-ee053477-e115-4fec-a5f5-cdc637ed6ddc, rgb(0, 204, 255)) /* {"name":"Sky"} */ 100%);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.286) 0px 0.722625px 0.722625px -1.25px, rgba(0, 0, 0, 0.255) 0px 2.74624px 2.74624px -2.5px, rgba(0, 0, 0, 0.1) 0px 12px 12px -3.75px;
    opacity: 1;
    color: #fff;
  }
  
  .nav ul li a.highlighted .ui-badge-white {
    background: #fff;
    color: #06b0ff;
    border-radius: 8px;
  }

  .nav ul li a.highlighted.router-link-active {
    border-bottom: var(--nav-item-active_border-bottom);
    color: var(--nav-item-active_color);
    background: var(--nav-item-active_background);
    box-shadow: none;
  }

  .nav .nav-menu {
    display: none;
  }

  .nav .user-item-avatar {
    outline: 2px solid var(--user-navigation_background);
  }

  @media (min-width: 768px) {
    .nav .nav-menu {
      display: flex;
    }

    .nav ul {
      gap: 24px;
    }
  }

  .nav-widget-title {
    color: var(--nav-widget-title_color);
  }

  .nav-item-icon {
    background: linear-gradient(315deg,#205dff 0%,#20c4ff 100%);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }

  .nav-admin-spectator {
    background: var(--color-brand);
    color: #fff;
    text-align: center;
    font-size: 14px;
    padding: 8px;
  }
</style>