<template>
	<div>
    <!-- <UiHeadline style="margin-bottom: 42px;">What's new?</UiHeadline>
        <div style="border-radius: 16px; background: rgb(246, 247, 247); flex: 0 0 auto;padding: 10px;margin-bottom: 64px;display: inline-block;">
          <div style="color: rgb(0, 0, 0);  font-size: 16px; font-style: normal; font-weight: 500; line-height: normal; letter-spacing: -0.32px;">
            - This is new<br>
            - And this
          </div>
        </div> -->
	       <!-- Page Title -->
        <!-- <UiHeadline style="margin-bottom: 42px;">Dashboard</UiHeadline> -->

          <!-- Row -->
          <div style="display:flex;gap: 72px;margin-top: 8px;padding: 8px;">
            <!-- Col -->
            <div style="width: 100%;">

              <!-- Support Ticket -->
              <div style="margin-bottom: 52px;" v-if="tickets.length">
                <UiGridTile full v-for="ticket in tickets">
                  <SupportTicket v-bind="ticket"></SupportTicket>
                </UiGridTile>
              </div>

              <!-- Trading Accounts -->
              <UiGridTile full>
                <TradingAccountsList></TradingAccountsList>
              </UiGridTile>
          </div>

          <!-- Col -->
          <div class="desktop" style="width: 342px;flex: none;">
            <UiHeadline class="mb-2" size="m">
              {{ $t('view.dashboard.sidebar.title') }}
            </UiHeadline>
            <UiCol gap="2">
              <router-link :to="item.route" v-for="item in quickAccessSidebar">
                <UiGridTile clickable>
                  <UiRow gap="2" align="center">
                    <Icon :icon="item.icon" size="24" v-if="item.icon"></Icon> <div>{{ item.name }}</div>
                    <UiBadge v-if="item.isPro">Pro</UiBadge>
                  </UiRow>
                </UiGridTile>
              </router-link>
            </UiCol>

            <!-- <div style="color: #000;font-size: 24px;font-style: normal;font-weight: 500;line-height: normal;margin-bottom: 16px;margin-top: 32px;">
              Data
            </div>

            <router-link to="/trading/marketdata">
              <MarketDataList></MarketDataList>
            </router-link> -->

            <!-- Checklist -->
            <template v-if="store.state.dashboard.version == '0.2.0'">
              <div style="color: #000;font-size: 24px;font-style: normal;font-weight: 500;line-height: normal;margin-bottom: 16px;margin-top: 42px;">
                Checklist
              </div>
              <router-link to="/checklist">
                <TradingChecklist></TradingChecklist>
              </router-link>
            </template>
          </div>
        </div>
    </div>

    <div v-if="false">
      <UiHeadline size="m" style="margin-bottom: 16px;">Strategies</UiHeadline>
      <UiListItem>Support and Resistance <small>Technical analysis</small></UiListItem>

      <UiHeadline size="m" style="margin-bottom: 16px;">Indicators & Tools</UiHeadline>
      <UiListItem>Footprint Chart</UiListItem>

      <UiHeadline size="m" style="margin-bottom: 16px;">Setups</UiHeadline>
      <UiListItem></UiListItem>

      <UiHeadline size="m" style="margin-bottom: 16px;">Pre-Trade Routine</UiHeadline>
      <UiList>
        <UiListItem>Check Daily Chart if (anything has changed, especially after longer break of trading)</UiListItem>
        <UiListItem>Identify Daily Trend</UiListItem>
        <UiListItem>1h Chart + Identify trend and Key Levels</UiListItem>
        <UiListItem>5min Chart + Identify Key Levels</UiListItem>
        <UiListItem>1min Chart + Look for setup for potential entries</UiListItem>
      </UiList>

      <UiHeadline size="m" style="margin-bottom: 16px;">Trading Session</UiHeadline>
      <UiList>
        <UiListItem>Trading Hours | <a>9:30am - 10:30am</a></UiListItem>
      </UiList>

      <UiHeadline size="m" style="margin-bottom: 16px;">Products</UiHeadline>
      <UiList>
        <UiListItem>NQ</UiListItem>
      </UiList>

      <UiHeadline size="m" style="margin-bottom: 16px;">Setup 1 (Double Top) Entry Criterea</UiHeadline>
      <UiList>
        <UiListItem>Price clearly rejected twice</UiListItem>
      </UiList>

      <UiHeadline size="m" style="margin-bottom: 16px;">Trade Management</UiHeadline>
      <UiList>
        <UiListItem>Place Stop below resistance level</UiListItem>
        <UiListItem>Place Stop above support level</UiListItem>
      </UiList>

      <UiHeadline size="m" style="margin-bottom: 16px;">Risk Management</UiHeadline>
      <UiList>
        <UiListItem>Max Position Size | <a>1-2 Micro</a></UiListItem>
        <UiListItem>Risk per trade | <a>1%</a></UiListItem>
        <UiListItem>Monthly loss limit | <a>$1000</a></UiListItem>
        <UiListItem>Daily loss limit | <a>$100</a></UiListItem>
      </UiList>

      <UiHeadline size="m" style="margin-bottom: 16px;">Capital Management</UiHeadline>
      <UiList>
        <UiListItem>Account size | <a>$10000</a></UiListItem>
      </UiList>
    </div>


    <UiModal title="Success" v-if="$route.query.reset_status === 'success'">
      <div>Your reset was successfull</div>

      <UiButton :to="`/trading/accounts/${$route.query.account_id}`">Go to Account</UiButton> 
    </UiModal>
</template>

<script setup>
	import { ref, computed, getCurrentInstance } from "vue"
	import { useStore } from "vuex"
  import { Chart } from "@lib/components"
  import TradingChecklist from "../../components/TradingChecklist"
  import TradingAccountsList from "../../components/TradingAccountsList"
  import TradingAccountsBalance from "../../components/TradingAccountsBalance"
  import MarketDataList from "../../components/MarketDataList"
  import SupportTicket from "@/components/Support/Ticket"

  const instance = getCurrentInstance();
  const t = instance.appContext.config.globalProperties.$t;

	const store = useStore()

  const modules = computed(() => store.state.dashboard.modules)
  const tickets = computed(() => store.state.dashboard.tickets)

  store.dispatch("dashboard/getOpenTickets").then(() => {}).catch(() => {})

  const quickAccessSidebar = ref([
    {
      name: t('view.dashboard.sidebar.navigation.platform_credentials'),
      icon: "key",
      route: "/trading/platform"
    },
    {
      name: t('view.dashboard.sidebar.navigation.manage_subscriptions'),
      icon: "folder",
      route: "/settings/subscriptions"
    },
    ...(store.state.dashboard.version == '0.2.0') ? [
      {
        name: "Trading Plan",
        icon: "trading",
        route: "/trading/plan",
        isPro: true
      },
      {
        name: "Journal",
        icon: "",
        route: "/trading/journal",
        isPro: true
      },
      {
        name: "Performance Analytics",
        icon: "",
        route: "/trading/performance",
        isPro: true
      },
      {
        name: "Trading Room",
        icon: "",
        route: "/trading/room",
        isPro: true
      },
      {
        name: "Risk Desk",
        icon: "",
        route: "/trading/risk",
        isPro: true
      },
    ] : []
  ])
</script>

<style scoped>
  .modules a {
    color: var(--color-brand);
  }
</style>