<template>
	<!-- Back:Mobile -->
	<div class="mobile">
		<UiBack route="/settings" text="Back" fixed></UiBack>
	</div>
		
	<UiCard>
		<!-- Headline -->
		<UiHeadline class="mb-8" size="s">Select your files</UiHeadline>
		<UiUploadFile v-model="file"></UiUploadFile>
		<Form class="mt-4" :schema="uploadSettingsForm" v-model="uploadSettingsModel"></Form>
		<UiButton class="mt-8" @click="upload" :loading="uploading">Upload trade data</UiButton>
	</UiCard>
</template>

<script setup>
	import { ref, computed, watch } from "vue"
	import { useStore } from "vuex"
	import { useRouter, useRoute } from "vue-router"
	import moment from "moment"

	const store = useStore()
	const router = useRouter()
	const route = useRoute()
	const model = ref({})
	const uploadSettingsModel = ref({})
	const file = ref(new FormData())
	const submitting = ref(false)
	const uploading = ref(false)

	const availableTimezones = moment.tz.names().map(t => ({
		value: t,
		label: t,
	}))

	const uploadSettingsForm = ref(`{
	  "rows": [
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "select",
	              "label": "Select Timezone",
	              "name": "timezone",
	              "placeholder": "",
	              "required": false,
	              "options": ${JSON.stringify(availableTimezones)}
	            }
	          ]
	        }
	      ]
	    }
	  ]
	}`)

	const submit = () => {
		if (submitting.value) return
		submitting.value = true
		store.dispatch('dashboard/addTradingAccount', {
			...model.value
		}).then(res => {
			submitting.value = false
			router.push(`/settings/import/${res.data.tradingAccount.id}`)
		}).catch(() => {
			submitting.value = false
		})
	}

	const upload = () => {
		if (uploading.value) return
		uploading.value = true
		store.dispatch('dashboard/uploadTradeDataToTradingAccount', {
			timezone: uploadSettingsModel.value.timezone,
			accountId: route.params.accountId,
			files: file.value
		}).then(res => {
			uploading.value = false
			router.push(`/trading/accounts/${route.params.accountId}`)
		}).catch(() => {
			uploading.value = false
		})
	}
</script>