<template>
	<div class="messages" gap="1" ref="chatBody">
		<UiSpinner status="Loading messages" v-if="loading"></UiSpinner>
		<template v-else>
			<ChatMessage v-bind="message" v-for="message in groupedMessages"></ChatMessage>
		</template>
	<!-- <div class="message-group" :class="{'is-last-message':message.isLastInGroup}" v-for="message in groupedMessages">
			<div class="message-avatar">
				<div class="message-avatar-wrapper" v-if="message.isLastInGroup">
					<img :src="message.member.profileImageUrl" alt="" class="sc-ellfGf edxkse" style="width: 100%;height: auto;">
				</div>
			</div>
			<div class="message-group-messages">
				<div class="chat-message-wrapper">
					<div class="chat-message">{{ message.message }}</div>
				</div>
			</div>
		</div> -->
	</div>
	<UiSpinner status="Reconnecting Chat" v-if="!$store.state.chat.socketConnected"></UiSpinner>
</template>

<script setup>
	import { ref, watch, computed } from "vue"
	import { useStore } from "vuex"
	import socket from "@lib/services/socket";
	import ChatMessage from "./ChatMessage"

	const store = useStore()
	const chatBody = ref(null);
	const socketConnected = ref(false)
	const loading = ref(false)

	const props = defineProps({
		chatId: String
	})

	const messages = computed(() => store.state.chat.messages)

	const groupedMessages = computed(() => {
		const newMessages = messages.value.map(message => ({ ...message }));

	    for (let i = 0; i < newMessages.length; i++) {
	        // Set the flag to true by default
	        newMessages[i].isLastInGroup = true;

	        // Check if the next message exists and if it's from the same member
	        if (i < newMessages.length - 1 && newMessages[i].member.id === newMessages[i + 1].member.id) {
	            newMessages[i].isLastInGroup = false;
	        }
	    }
	    return newMessages;
	})

	const chatScroll = () => {
	  setTimeout(() => {
	    chatBody.value.scrollTop = chatBody.value.scrollHeight;
	  }, 1);
	}

	loading.value = true
	const fetch = () => {
		store.dispatch("chat/getChat", {
			chatId: props.chatId
		}).then(res => {
			loading.value = false
		})
	}

	const initChatSocket = () => {
		socket.emit("chat-join", {
	    	room: props.chatId,
	  	});

		socket.off("chat-message");

	    socket.on("chat-message", (data) => {
	      if (data.message.member.id !== store.state.user.user.id) {
	        messages.value.push(data.message);
	      }
	    });
	}

	const listenSocket = () => {
		if (!socketConnected.value) {
			if (socket.connected) {
				initChatSocket();

			    socketConnected.value = true
			}
			
		    return
		}

		if (!socket.connected) {
			socketConnected.value = false
		}
	}

    watch(() => messages.value.length, () => {
    	chatScroll();
    }, {
    	immediate: true
    })

    watch(() => props.chatId, () => {
    	if(props.chatId) {
    		fetch();
    		listenSocket();
    		initChatSocket();
    	}
    }, {
    	immediate: true
    })

    listenSocket();

    setInterval(() => {
	  listenSocket();
	}, 1000);
</script>

<style scoped>
	.messages {
		overflow: auto;
	    height: 100%;
	    display: flex;
	    flex-flow: column;
	    gap: 2px;
	}
</style>