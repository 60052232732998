<template>
	<LayoutMain>
		<div class="s-centered">
			<div class="container text-center">
				<h1>Thank You for Joining Our Waiting List!</h1>
				<br>
				<p>
					You're now on the list! We're excited to have you on board.
					<!--<br>You'll be among the first to know when our trading program opens up. We'll send you an exclusive early access invitation.-->
				</p>
				<br>
				<br>
				<br><br>
				<UiCard style="max-width: 400px;margin: auto;" v-if="false">
					<img src="/img/DALL_E_2023-12-19_10.44.02_-_A_3D-rendered_wide_image_of_a_more_normal-looking_book_with_a_blue_cover__set_against_a_completely_white_background._The_book_appears_simple_yet_elega-removebg-preview.png" style="max-width: 256px;">
					<br>
					<br>
					<p>In the meantime, here's a <strong>free eBook</strong> on 'Top Trading Strategies' to get you started!</p>
					<br><br>
					<router-link class="btn btn-s" to="/">Download E-Book</router-link>
				</UiCard>
			</div>
		</div>
	</LayoutMain>
</template>