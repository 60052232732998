<template>
	<UiRow gap="4">
		<UiGridTile v-for="plan in plans" grow full>
			<UiHeadline size="m" style="margin-bottom: 16px">{{ plan.name }}</UiHeadline>

			<p>
				<div v-for="benefit in plan.benefits">{{ benefit.name }}</div>
			</p>
		</UiGridTile>
	</UiRow>
</template>

<script setup>
	import { ref } from "vue"

	const plans = ref([
		{
			name: "Basic Affiliate",
			description: "",
			benefits: [
				{
					name: "15% Commission per referral"
				},
				{
					name: "0% Recurring subscription commission"
				},
				{
					name: "0% Commission on resets"
				},
			]
		},
		{
			name: "Partner",
			description: "",
			benefits: [
				{
					name: "20% Commission per referral"
				},
				{
					name: "10% Recurring subscription commission"
				},
				{
					name: "10% Commission on resets"
				},
			]
		},
		{
			name: "Partner Pro",
			description: "",
			benefits: [
				{
					name: "All benefits from *Partner"
				},
				{
					name: "50% Split of successfull trader payouts"
				},
				{
					name: "10% Commission on trader in your group"
				},
			]
		},
	])
</script>