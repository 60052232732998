<template>
  <UiCol gap="2">
    <template v-if="fetching && !playbooks.length">
      <UiSkeleton height="52.5px"></UiSkeleton>
      <UiSkeleton height="52.5px"></UiSkeleton>
      <UiSkeleton height="52.5px"></UiSkeleton>
    </template>
    <template v-else-if="playbooks.length">
      <router-link :to="`/trading/accounts/${route.params.accountId}/list/${route.params.listKey}/${playbook.id}`" v-for="playbook in playbooks">
        <UiGridTile full clickable>
          {{ playbook.name }}
        </UiGridTile>
      </router-link>
    </template>
    <UiGridTile clickable v-else>
      No Items
    </UiGridTile>
    <div>
      <UiButton color="gray" :loading="creating" @click="create">Add</UiButton>
    </div>
  </UiCol>
</template>

<script setup>
  import { ref } from "vue"
  import { useStore } from "vuex"
  import { useRoute, useRouter } from "vue-router"

  const store = useStore()
  const route = useRoute()
  const router = useRouter()
  const creating = ref(false)
  const fetching = ref(false)
  const playbooks = ref(false)

  const fetch = () => {
    fetching.value = true
    store.dispatch('dashboard/listItems', {
      listKey: route.params.listKey,
      params: {
        refId: route.params.accountId
      }
    }).then(res => {
      fetching.value = false
      playbooks.value = res.data.items
    }).catch(() => {
      fetching.value = false
    })
  }

  fetch()

  const create = () => {
    if (creating.value) return
    creating.value = true
    store.dispatch('dashboard/createItem', {
      listKey: route.params.listKey,
      data: {
        accountId: route.params.accountId
      }
    }).then(res => {
      creating.value = false
      router.push(`/trading/accounts/${route.params.accountId}/list/${route.params.listKey}/${res.data.item.id}`)
    }).catch(() => {
      creating.value = false
    })
  }
</script>