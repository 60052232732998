<template>
	<LayoutUser>
		<div class="container" style="margin-top: 46px;">
			<UiHeadline size="m" bold class="mb-2">Trading Desk</UiHeadline>
			<UiGridTile>
				You haven't joined a trading desk yet
			</UiGridTile>
			
			<div class="mt-4">
				<UiButton size="l">Join Desk</UiButton>
			</div>
		</div>
	</LayoutUser>
</template>

<script setup>
	import { useStore } from "vuex"
	import { LayoutUser } from "@lib/components"

	const store = useStore()

	store.dispatch("dashboard/getDesk").then((res) => {
      
    })
</script>