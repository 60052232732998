<template>
	<div class="user-layout">
		<!-- Navigation -->
		<UserNavigation :items="navItems"></UserNavigation>

	  	<!-- Body -->
	  	<slot></slot>

	  	<!-- <div style="color: rgb(0, 0, 0); font-size: 16px; font-style: normal; font-weight: 500; line-height: normal; letter-spacing: -0.32px; margin-bottom: 18px;width: 100%;display: flex;justify-content: center;margin-top: 142px;">
	  		<router-link to="/updates" style="color: #c2c9cb;">Version 2.0</router-link>
	  	</div> -->
  	</div>
</template>

<script setup>
	import { ref, computed, getCurrentInstance } from "vue"
	import { useStore } from "vuex"
	import UserNavigation from "./UserNavigation"

	const instance = getCurrentInstance();
    const t = instance.appContext.config.globalProperties.$t;

	const store = useStore()

	const navItems = computed(() => store.state.auth.authenticated ? [
		{
			name: t('navigation.dashboard'),
			route: "/",
		},
		{
			name: t('navigation.community'),
			route: "/community",
		},
		// {
		// 	name: t('navigation.instant_funding'),
		// 	route: "/instant-funding",
		// 	highlighted: true,
		// 	label: 'New'
		// },
		// {
		// 	name: 'Trading Desk',
		// 	route: "/trading-desk",
		// 	icon: "trading"
		// },
		...store.state.user.user.isBusiness ? [
			{
				name: t('navigation.partner'),
				route: "/partner"
			}
		] : [],
		// {
		// 	name: t('navigation.education'),
		// 	route: "/education"
		// },
		// {
		// 	name: t('navigation.members'),
		// 	route: "/members",
		// },
		// {
		// 	name: t('navigation.calendar'),
		// 	route: "/calendar",
		// 	notification: 1
		// },
	] : [
		{
			name: t('navigation.evaluations'),
			href: "https://purdia.com/evaluation"
		},
		{
			name: t('navigation.faq'),
			href: "https://purdia.com/faq"
		},
		])
</script>

<style>
	.user-layout {
		min-height: 100vh;
		padding-top: var(--user-layout_padding-top);
	}
	.user-layout .container {
		max-width: 900px;
	}
</style>