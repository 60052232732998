<template>
	<div v-if="availableMarketDataPlans">
		<UiRow gap="2">
	      <UiGridTile v-for="plan in availableMarketDataPlans">
	        {{ plan.title }}
	      </UiGridTile>
	    </UiRow>
	</div>
</template>

<script setup>
	import { computed } from "vue"
	import { useStore } from "vuex"

	const store = useStore()

	const marketData = computed(() => store.state.dashboard.marketData)
	const availableMarketDataPlans = computed(() => store.getters["dashboard/availableMarketDataPlans"])

	store.dispatch("dashboard/getMarketDataPlans").then().catch(() => {})
  	store.dispatch("dashboard/getMarketData").then().catch(() => {})
</script>