<template>
	<S6 title="Blog" text="Your contribution matters, and we value your trust in our mission. Rest assured that your donation will be utilized responsibly and efficiently." :items="s6Items"></S6>
</template>

<script setup>
	import S6 from "@/components/S6"

	const s6Items = [
	    {
	      badge: "Creative ideas",
	      date: "June 20, 2023",
	      title: "Fundraising 101: Essential Tips for Getting Started",
	      text: "Our mission is to combat the water crisis and improve the health and well-being of communities in need.",
	      btn: {
	        text: "Read More",
	        to: "/"
	      }
	    }
	  ]
</script>