<template>
  <UiCard v-if="$route.query.setting_up">
    <UiSpinner status="Setting up your Affiliate Dashboard" @completed="onSetupCompleted"></UiSpinner>
  </UiCard>
  <UiCol gap="4" v-else-if="loading && !partner">
    <UiSkeleton height="107px"></UiSkeleton>
    <UiSkeleton height="816.5px"></UiSkeleton>
  </UiCol>
  <UiCol gap="4" v-else-if="partner">
    <UiCard>
      <UiRow justify="between">
        <div>
          <UiHeadline size="m" style="margin-bottom: 16px;">Affiliate Link</UiHeadline>
          <a>{{ partner.affiliateLink }}</a>
        </div>

        <UiRow align="center" gap="2" v-if="partner.partnerCode">
          <div>Code</div>
          <UiGridTile>{{ partner.partnerCode }}</UiGridTile>
        </UiRow>
      </UiRow>
    </UiCard>

    <UiCard>
      <UiHeadline size="m" style="margin-bottom: 16px;">Balance</UiHeadline>

      <UiRow gap="16">
        <div>
          <UiHeadline size="s">Available (Total)</UiHeadline>

          <!-- Balance --> 
          <div v-if="partner" style="font-size: 48px;font-style: normal;font-weight: 700;line-height: normal;letter-spacing: -2.88px;">
            {{ partner.estimatedNextPayment }}
          </div>
        </div>
        <div>
          <UiHeadline size="s">MRR</UiHeadline>

          <!-- Balance --> 
          <div v-if="partner" style="font-size: 48px;font-style: normal;font-weight: 700;line-height: normal;letter-spacing: -2.88px;">
            $0.00
          </div>
        </div>
      </UiRow>

      <UiHeadline size="m" style="margin-top: 59px;">Affiliate</UiHeadline>
      <!-- Stats -->
      <div style="margin-top: 16px;display:grid;grid-template-columns: 1fr 1fr 1fr 1fr;gap: 19px;">
        <div v-for="stat in partner.stats">
          <!-- Label -->
          <div style="color: #000;font-size: 16px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -0.32px;margin-bottom: 18px;" v-html="stat.title"></div>

          <!-- Value -->
          <div style="color: #000;font-size: 28px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -1.68px;">{{ stat.value }}</div>

          <!-- Info -->
          <div style="color: #000;font-size: 17px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: 0.17px;margin-top: 3px;" v-if="stat.info" v-html="stat.info">
          </div>
        </div>
      </div>

      <UiHeadline size="m" style="margin-top: 59px;">Trader Payouts</UiHeadline>
      <!-- Stats -->
      <div style="margin-top: 16px;display:grid;grid-template-columns: 1fr 1fr 1fr 1fr;gap: 19px;">
        <div v-for="stat in partner.traderPayoutsStats">
          <!-- Label -->
          <div style="color: #000;font-size: 16px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -0.32px;margin-bottom: 18px;" v-html="stat.title"></div>

          <!-- Value -->
          <div style="color: #000;font-size: 28px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -1.68px;">{{ stat.value }}</div>

          <!-- Info -->
          <div style="color: #000;font-size: 17px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: 0.17px;margin-top: 3px;" v-if="stat.info" v-html="stat.info">
          </div>
        </div>
      </div>

      <UiHeadline size="m" style="margin-top: 59px;">Desk Stats</UiHeadline>
      <!-- Stats -->
      <div style="margin-top: 16px;display:grid;grid-template-columns: 1fr 1fr 1fr 1fr;gap: 19px;">
        <div v-for="stat in partner.deskStats">
          <!-- Label -->
          <div style="color: #000;font-size: 16px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -0.32px;margin-bottom: 18px;" v-html="stat.title"></div>

          <!-- Value -->
          <div style="color: #000;font-size: 28px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -1.68px;">{{ stat.value }}</div>

          <!-- Info -->
          <div style="color: #000;font-size: 17px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: 0.17px;margin-top: 3px;" v-if="stat.info" v-html="stat.info">
          </div>
        </div>
      </div>

      <UiButton color="black" href="mailto:info@purdia.com" style="margin-top: 71px;">Payout</UiButton>
    </UiCard>
  </UiCol>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"
  import { useRouter } from "vue-router"

	const store = useStore()
  const router = useRouter()
	const partner = computed(() => store.state.dashboard.business)
  const loading = ref(false)

	const fetch = async () => {
		return new Promise(resolve => {
			store.dispatch("dashboard/getBusiness").then((res) => {
        loading.value = false
    		resolve()
    	})
		})
	}

  loading.value = true

	fetch()

  const onSetupCompleted = () => {
    router.push({name:"partner"})
  }
</script>