<template>
  <UiCol gap="2">
    <template v-if="fetching && !playbooks.length">
      <UiSkeleton height="52.5px"></UiSkeleton>
      <UiSkeleton height="52.5px"></UiSkeleton>
      <UiSkeleton height="52.5px"></UiSkeleton>
    </template>
    <template v-else-if="playbooks.length">
      <router-link :to="`/trading/accounts/${route.params.accountId}/playbooks/${playbook.id}`" v-for="playbook in playbooks">
        <UiGridTile full clickable>
          {{ playbook.name }}
        </UiGridTile>
      </router-link>
    </template>
    <UiGridTile clickable v-else>
      No Playbooks
    </UiGridTile>
    <div>
      <UiButton color="gray" :loading="creating" @click="createPlayBook">Add Playbook</UiButton>
    </div>
  </UiCol>
</template>

<script setup>
  import { ref } from "vue"
  import { useStore } from "vuex"
  import { useRoute, useRouter } from "vue-router"

  const store = useStore()
  const route = useRoute()
  const router = useRouter()
  const creating = ref(false)
  const fetching = ref(false)
  const playbooks = ref(false)

  const fetch = () => {
    fetching.value = true
    store.dispatch('dashboard/getPlaybooks', {
      accountId: route.params.accountId,
    }).then(res => {
      fetching.value = false
      playbooks.value = res.data.playbooks
    }).catch(() => {
      fetching.value = false
    })
  }

  fetch()

  const createPlayBook = () => {
    if (creating.value) return
    creating.value = true
    store.dispatch('dashboard/createPlayBook', {
      accountId: route.params.accountId
    }).then(res => {
      creating.value = false
      router.push(`/trading/accounts/${route.params.accountId}/playbooks/${res.data.playbook.id}`)
    }).catch(() => {
      creating.value = false
    })
  }
</script>