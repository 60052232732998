<template>
	<UiHeadline class="mb-16 text-center">Instant Funding</UiHeadline>

	<UiRow align="center" gap="16">
		<div v-if="false">
			<UiHeadline class="mb-4" size="m">How it works</UiHeadline>
			<iframe width="560" height="315" src="https://www.youtube.com/embed/-FcBePLEdB0?si=0iguj3YtICNSCMf0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style="border-radius: 16px;"></iframe>
		</div>

		<!-- Features -->
		<div>
		  <UiHeadline size="m" bold class="mb-2">Work with us.</UiHeadline>

		  <UiCol gap="1" style="max-width: 512px;">
		    <UiGridTile v-for="feature in features">
		      <UiRow gap="2" align="center">
		        <img src="/img/check.svg">
		        <div>{{ feature.name }}</div>
		      </UiRow>
		    </UiGridTile>
		  </UiCol>
		</div>

		<UiGridTile color="white">
			<div>
				<UiHeadline class="mb-4" size="m">Let's talk</UiHeadline>
				<p>Schedule a call to talk about it first.</p>
				<UiButton size="l" class="mt-8" href="https://calendly.com/purdia-capital/30min" target="_blank">Schedule Call</UiButton>
			</div>
		</UiGridTile>
	</UiRow>
</template>

<script setup>
	import { ref } from "vue"

	const features = ref([
		{
	      name: "Coaching"
	    },
		{
	      name: "No Evaluation Phase"
	    },
	    {
	      name: "One-time Payment"
	    },
	    {
	      name: "1 Free Reset each Month"
	    },
	    {
	      name: "$36,000 Drawdown"
	    },
	    {
	      name: "Funded for 12 Months"
	    },
	    {
	      name: "Payout after 10 trading days"
	    }
	])
</script>