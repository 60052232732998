<template>
	<div class="container">
		<UiRow>
			<UiSidebar class="desktop" :items="navigationItems"></UiSidebar>
			<div style="width: 100%;">
				<router-view></router-view>
			</div>
		</UiRow>
	</div>
</template>

<script setup>
	import { ref, getCurrentInstance } from "vue"
	import { useStore } from "vuex"
	import { SidebarLayout } from "@lib/components"
	
	const instance = getCurrentInstance();
  const t = instance.appContext.config.globalProperties.$t;

	const store = useStore()

	const signOut = () => {
		store.dispatch("auth/signOut").then(() => {
			window.location = "/"
		})
	}

	const navigationItems = ref([
		{
          name: t('view.settings.sidebar.account'),
          icon: "folder",
      	  key: "settings",
          route: "/settings/profile"
        },
        {
          name: t('view.settings.sidebar.subscriptions'),
          icon: "folder",
      	  key: "settings",
          route: "/settings/subscriptions"
        },
        {
          name: t('view.settings.sidebar.credits'),
          icon: "folder",
      	  key: "points",
          route: "/settings/points"
        },
        {
          name: t('view.settings.sidebar.affiliate'),
          icon: "folder",
      	  key: "points",
          route: "/settings/affiliate"
        },
        ...store.state.user.user.isAdmin ? [
        	{
	          name: 'Import Account',
	          icon: "folder",
	      	  key: "settings",
	          route: "/settings/import"
	        }
        ] : [],
        {
          name: t('view.settings.sidebar.configuration'),
          icon: "folder",
      	  key: "settings",
          route: "/settings/configuration"
        },
	])
</script>