<template>
  <LayoutUser>
    <!-- Account -->
    <div style="margin-top: 46px;">
      <!--- Container -->
      <div style="width: 100%;max-width: 1170px;margin: auto;">
        <!-- Router -->
        <router-view></router-view>
      </div>
    </div>
  </LayoutUser>
</template>

<script setup>
  import { ref, computed, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { LayoutUser } from "@lib/components"

  const account = ref(null)
  const store = useStore()
  const router = useRouter()
  const route = useRoute()
  const tradingDays = ref([])
  const buildingBlocks = ref([
    {
      title: "Capital"
    },
    {
      title: "Risk"
    },
    {
      title: "Issues"
    },
    {
      title: "Plan"
    },
  ])

  store.dispatch("dashboard/getModules")
</script>

<style scoped>
  .dashboard {
    padding-top: 128px;
  }
  .dashboard .avatar {
    height: 96px;
    border-radius: 999px;
  }
  .dashboard-nav {
    display: none;
  }

  @media (min-width: 768px) {
    .dashboard-nav {
      display: flex;
    }
  }
</style>