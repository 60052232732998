<template>
	<UiHeadline class="mb-2" size="m">{{ $t('view.trading_day.title') }}</UiHeadline>

		<UiRow align="center" gap="4" style="margin-bottom: 42px;">
			<!-- Back -->
			<UiBack :route="`/trading/accounts/${$route.params.accountId}/days`" :text="$t('view.trading_day.back_button')"></UiBack>
	  </UiRow>

    <UiRow gap="8">
    	<div>
		    <!-- Subtitle -->
		    <div>
		    	{{ $t('view.trading_day.pnl') }}
		    </div>

		    <!-- Balance -->
		    <UiSkeleton height="61px" width="165px" v-if="loading && !tradingDay"></UiSkeleton>
		    <UiHeadline size="s" style="display: flex;gap: 9px;" v-else-if="tradingDay">
		      <div>
		        {{ tradingDay._pnlWithFees }}
		      </div>
		    </UiHeadline>
	    </div>
	    <div>
	    	<div>Date</div>
	    	<!-- Date -->
				<UiSkeleton height="27.5px" width="118px" v-if="loading && !tradingDay"></UiSkeleton>
	    	<UiHeadline size="s"  v-else-if="tradingDay">{{ tradingDay._date }}</UiHeadline>
	    </div>
    </UiRow>

    <!-- Trades -->
    <div class="mt-8">
    	<UiCard title="Report" style="margin-bottom: 48px;" collapsible expanded>
    		<Form :schema="schema" v-model="model" @submit="submit" :submitting="saving"></Form>
    	</UiCard>

    	<div style="color: #000;font-size: 24px;font-style: normal;font-weight: 500;line-height: normal;margin-bottom: 16px;">
      {{ $t('view.trading_day.trades.title') }}
    	</div>

    	<UiSkeleton height="512px" v-if="loadingTrades"></UiSkeleton>
    	<div data-v-e7b971b8="" class="content" v-else>
    		<div style="display: flex;flex-direction: column;gap: 2px;">
    			<TradingDayTradeListItem v-for="trade in trades" v-bind="trade" @click="onClickTrade(trade.id)" :account-id="route.params.accountId"></TradingDayTradeListItem>
    		</div>
    	</div>
    </div>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"
	import { useRoute, useRouter } from "vue-router"
	import TradingDayTradeListItem from "@/components/TradingDayTradeListItem"

	const store = useStore()
	const route = useRoute()
	const router = useRouter()
	const showLogin = ref(false)
	const trades = ref([])
	const tradingDayReport = ref(null)
	const showReport = ref(false)
	const model = ref({})
	const loading = ref(true)
	const loadingTrades = ref(true)
	const saving = ref(false)

	const onClickTrade = (tradeId) => {
		//router.push(`/trading/accounts/${route.params.accountId}/days/${route.params.tradingDayId}/${tradeId}`)
	}
	
	const schema = ref(`{
	  "rows": [
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "textarea",
	              "label": "What went well?",
	              "name": "went_well",
	              "placeholder": ""
	            }
	          ]
	        },
	        {
	          "fields": [
	            {
	              "type": "textarea",
	              "label": "What went wrong?",
	              "name": "went_wrong",
	              "placeholder": ""
	            }
	          ]
	        }
	      ]
	    },
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "textarea",
	              "label": "What will I improve on?",
	              "name": "improve",
	              "placeholder": ""
	            }
	          ]
	        }
	      ]
	    }
	    ,{
      "columns": [
        {
          "fields": [
            {
              "type": "submit",
              "label": "Save Report"
            }
          ]
        }
      ]
    }]}`)

	const account = computed(() => store.state.dashboard.account)
	const tradingDay = computed(() => store.getters["dashboard/tradingDay"])

	store.dispatch('dashboard/getPlaybooks', {accountId: route.params.accountId})

	store.dispatch("dashboard/setTradingDay", route.params.tradingDayId)

	store.dispatch("dashboard/getTradingDay", {
		accountId: route.params.accountId,
		tradingDayId: route.params.tradingDayId
	}).then((res) => {
      model.value = res.data.report
      loading.value = false

        store.dispatch("dashboard/getTradingDayTrades", {
				accountId: route.params.accountId,
				date: res.data.date
			}).then((res) => {
		      trades.value = res.data.trades
		      loadingTrades.value = false
		    })
    })

  

	const submit = () => {
		saving.value = true
		store.dispatch("dashboard/updateTradingDayReport", {
			accountId: route.params.accountId,
			tradingDayId: route.params.tradingDayId,
			data: model.value
		}).then((res) => {
      saving.value = false
    })
	}
</script>