<template>
	<LayoutUser>
		<div>
        <UiRow gap="2">
  <!-- Account -->
  <div style="width: 512px; height: 100%;" v-if="false">
    <UiGridTile full grow>
      <UiHeadline size="s">Pre Session</UiHeadline>
      <Form :schema="formSchema" v-model="model" @submit="submit" :errors="errors" :submitting="submitting"></Form>
      <div style="position: absolute;bottom: 0;width: 100%;left: 0;padding: 16px;">
        <UiButton full round>Next</UiButton>
      </div>
    </UiGridTile>
  </div>
  <UiCol gap="2" style="height: 100%;width: 100%;max-width:1200px;flex: inherit;margin-top: -1px;">

      <UiRow class="tabs" align="center" justify="center">
        <router-link class="tab" v-for="tab in tabs" :to="tab.route" :class="{'tab-active':tab.active}">
          <UiRow align="center" gap="2">
            <div class="tab-icon" v-if="tab.icon">
              <Icon :icon="tab.icon" size="16" color="#215dff"></Icon>
            </div>
            {{ tab.name }} 
            <div class="tab-close" v-if="false">
              <Icon icon="close" size="12"></Icon>
            </div>
          </UiRow>
        </router-link>
      </UiRow>


      <router-view></router-view>
  </UiCol>
  <div style="width: 512px;height: 100%;">
    <UiGridTile full grow>
      <UiHeadline size="s">Pre Trade</UiHeadline>
      <Form :schema="tradeReportFormSchema" v-model="model" @submit="submit" :errors="errors" :submitting="submitting"></Form>
      <div style="position: absolute;bottom: 0;width: 100%;left: 0;padding: 16px;">
        <UiButton full round>Next</UiButton>
      </div>
    </UiGridTile>
  </div>
</UiRow>
		</div>
	</LayoutUser>
  <Intro v-if="loading" :sound-enabled="introSoundEnabled"></Intro>
</template>

<script setup>
	import { ref, computed } from "vue"
  import { useStore } from "vuex"
  import { useRoute, useRouter } from "vue-router"
	import { LayoutUser, Intro } from "@lib/components"
  import TradingChart from "@/components/TradingChart"
  const sound = new Audio('/sounds/pro-intro.mp3');

  const route = useRoute()
  const router = useRouter()
  const store = useStore()
  const loading = ref(true)
  const introSoundEnabled = computed(() => store.state.dashboard.interacted)

  setTimeout(() => {
    if (introSoundEnabled.value) {
      sound.play();
    }

    setTimeout(() => {
      loading.value = false
      router.push('/desk')
    }, 1200)
  }, 0)

  const tabs = ref([
    {
      name: "Desk",
      icon: "folder",
      route: "/desk"
    },
    {
      name: "Trading",
      icon: "trading",
      route: "/desk/trading"
    },
    {
      name: "News",
      icon: "trading",
      route: "/desk/news"
    },
    {
      name: "Calendar",
      icon: "folder",
      route: "/desk/calendar"
    },
    {
      name: "Market",
      icon: "trading",
      route: "/desk/market"
    },
  ])

  const formSchema = ref(`{
  "rows": [
    {
      "columns": [
        {
          "fields": [
            {
              "key": "mental_feel",
              "type": "textarea",
              "label": "How do you feel?",
              "name": "mental_feel",
              "placeholder": "",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "mental_sleep",
              "type": "select_group",
              "label": "How was your sleep?",
              "name": "mental_sleep",
              "required": true,
              "options": [{"value": "good","name": "Good"},{"value": "bad","name": "Bad"}]
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "mental_stress",
              "type": "select",
              "label": "Stress Level",
              "name": "password",
              "required": true,
              "default": "low",
              "options": [{"value": "low","label": "Low to no stress"},{"value": "moderate","label": "Slightly stressed"},{"value": "high","label": "Strongly stressed"}]
            }
          ]
        }
      ]
    }
  ]
}
`);

  const tradeReportFormSchema = ref(`{
  "rows": [
    {
      "columns": [
        {
          "fields": [
            {
              "key": "analysis",
              "type": "textarea",
              "label": "Analysis",
              "name": "analysis",
              "placeholder": "",
              "required": false
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "idea",
              "type": "textarea",
              "label": "Idea",
              "name": "idea",
              "placeholder": "",
              "required": false
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "strategy",
              "type": "text",
              "label": "Strategy",
              "name": "strategy",
              "placeholder": "",
              "required": false
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "stop_level",
              "type": "number",
              "label": "Stop Loss Level",
              "name": "stop_level",
              "placeholder": "",
              "required": false
            }
          ]
        },
        {
          "fields": [
            {
              "key": "take_profit_level",
              "type": "number",
              "label": "Take Profit Level",
              "name": "take_profit_level",
              "placeholder": "",
              "required": false
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "risk",
              "type": "number",
              "label": "Risk %",
              "name": "stop_level",
              "placeholder": "",
              "required": false
            }
          ]
        },
        {
          "fields": [
            {
              "key": "risk",
              "type": "number",
              "label": "Pos. Size",
              "name": "stop_level",
              "placeholder": "",
              "required": false
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "key_levels",
              "type": "textarea",
              "label": "Key Levels",
              "name": "key_levels",
              "placeholder": "",
              "required": false
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "key_levels",
              "type": "textarea",
              "label": "Entry Critera",
              "name": "key_levels",
              "placeholder": "",
              "required": false
            }
          ]
        }
      ]
    }
  ]
}
`);
</script>

<style scoped>
  .tabs {
    border-bottom: 1px solid #e7e7e7;
  }
  .tab {
    padding: 4px 16px;
    display: inline-block;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.14px;
    position: relative;
    background: #fff;
    border-radius: 0px;
    cursor: pointer;
    color: #6b7280;
  }
  .tab svg {
    fill: #6b7280;
  }
  .tab:hover {
    background: rgb(246, 247, 247);
  }
  .tab:hover .tab-close {
    display: flex;
  }
  .tab.router-link-exact-active {
    background: rgb(246, 247, 247);
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    color: #000;
  }
  .tab.router-link-exact-active:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: -1px;
    left: 0px;
    display: block;
    background: var(--color-brand);
  }
  .tab-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: 28px;
    width: 28px;
    background: rgb(246, 247, 247);
  }
  .tab.router-link-exact-active .tab-icon {
    background: #fff;
  }
  .tab-close {
    border-radius: 8px;
    height: 18px;
    width: 18px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .tab-close:hover {
    background: #e0e0e0;
  }
</style>