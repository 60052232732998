<template>
	<!-- Page Title -->
    <UiHeadline style="margin-bottom: 42px;">The right path</UiHeadline>

    <!-- Courses -->
    <UiHeadline size="m" style="margin-bottom: 16px;">Courses</UiHeadline>
    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; gap: 19px;">
		<router-link :to="`/education/courses/${item}`" v-for="item in 4">
			<img src="/img/course-thumbnail.png" style="width: 100%;">
		</router-link>
	</div>
	<UiButton color="black" style="margin-top: 16px;" to="/education/courses">View All Courses</UiButton>

	<!-- Live -->
	<UiHeadline size="m" style="margin-bottom: 16px;margin-top: 71px;">Live</UiHeadline>
	<div>
    	<iframe width="560" height="315" src="https://www.youtube.com/embed/cOe1frOZjpY?si=9mlwZhjRDPTrO7q6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
	</div>
	<UiButton color="black" style="margin-top: 16px;" to="/education/courses">View Live Trading Session</UiButton>
</template>