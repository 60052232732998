<template>
	<div style="display: flex;gap: 71px;">
		<div style="width: 342px;flex: none;background: #F6F7F7;border-radius: 16px;">
			<router-link to="/dashboard" style="border-radius: 16px;padding: 10px;cursor: pointer;display: block;" v-for="topic in topics">
	          <div style="display: flex;align-items: center;gap: 8px;">
	            <div style="width: 36px;height: 36px;border-radius: 10px;background: #fff;"></div>
	            <div style="font-size: 19px;font-weight: 500;line-height: normal;letter-spacing: -1.14px;">{{ topic.name }}</div>
	          </div>
	        </router-link>
		</div>
		<div style="width: 100%;">
			<div style="color: #000;font-size: 24px;font-style: normal;font-weight: 500;line-height: normal;margin-bottom: 16px;">Knowledgebase</div>
			<UiHeadline style="margin-bottom: 42px;">Market Order</UiHeadline>
		</div>
	</div>
</template>

<script setup>
	import { ref } from "vue"

	const topics = ref([
    {
        "name": "Market Order",
        "description": "An order to buy or sell a security immediately at the best available current price.",
        "category": "Trading Orders"
    },
    {
        "name": "Limit Order",
        "description": "An order to buy or sell a security at a specific price or better.",
        "category": "Trading Orders"
    },
    {
        "name": "Stop Loss Order",
        "description": "An order placed to sell a security when it reaches a certain price, used to limit potential losses.",
        "category": "Risk Management"
    },
    {
        "name": "Take Profit Order",
        "description": "An order to sell a security when it reaches a predetermined profit target.",
        "category": "Risk Management"
    },
    {
        "name": "Leverage",
        "description": "The use of borrowed funds to increase one's trading position beyond what would be available from their cash balance alone.",
        "category": "Risk Management"
    },
    {
        "name": "Margin Trading",
        "description": "Trading with borrowed capital, allowing traders to open larger positions with a smaller amount of actual funds.",
        "category": "Risk Management"
    },
    {
        "name": "Daily Loss Limit",
        "description": "A predefined cap on the amount a trader is willing to lose in a trading day to limit excessive risk.",
        "category": "Risk Management"
    },
    {
        "name": "Volume",
        "description": "The total number of shares or contracts traded for a specific security or market during a given period.",
        "category": "Market Analysis"
    },
    {
        "name": "The DOM (Depth of Market)",
        "description": "A tool that shows the number of buy and sell orders for a security at different prices, indicating market depth.",
        "category": "Market Analysis"
    },
    {
        "name": "Footprint Chart",
        "description": "A detailed chart that shows each individual trade or tick and its size for a given period, providing a deeper market view.",
        "category": "Market Analysis"
    },
    {
        "name": "Support Level",
        "description": "A price level where a downtrend can be expected to pause or rebound due to concentrated demand.",
        "category": "Technical Analysis"
    },
    {
        "name": "Resistance Level",
        "description": "A price level where an uptrend can be expected to pause or reverse due to concentrated selling.",
        "category": "Technical Analysis"
    },
    {
        "name": "Technical Analysis",
        "description": "The study of past market data, primarily price and volume, to forecast future price movements.",
        "category": "Technical Analysis"
    },
    {
        "name": "Fundamental Analysis",
        "description": "Analyzing a company’s financial statements and health, its management and competitive advantages, and its competitors and markets.",
        "category": "Fundamental Analysis"
    }
]

)
</script>