<template>
	<div class="user-layout">
		<!-- Navigation -->
		<CheckoutNavigation :items="navItems"></CheckoutNavigation>

		<!-- Body -->
	  	<slot></slot>
  	</div>
</template>

<script setup>
	import { ref, computed, getCurrentInstance } from "vue"
	import { useStore } from "vuex"
	import CheckoutNavigation from "@/components/CheckoutNavigation"

	const instance = getCurrentInstance();
    const t = instance.appContext.config.globalProperties.$t;

	const store = useStore()

	const navItems = []
</script>

<style>
	.user-layout {
		padding-top: var(--user-layout_padding-top);
	}
	.user-layout .container {
		max-width: 900px;
	}
</style>