<template>
  <UiHeadline style="margin-bottom: 42px;">Calendar</UiHeadline>
  <div class="weekly-calendar">
    <div v-for="day in weekDays" :key="day.format()" class="day">
      <div class="day-head">
        {{ day.format('dddd') }}
        <strong>{{ day.format('D') }}</strong>
      </div>
      <div class="day-body">
        <div class="day-body-head">Performance</div>
        <div class="day-body-inner">
          <Checklist :items="days[day.format('DD-MM-YYYY')]"></Checklist>
        </div>
      </div>
      <!-- <div class="day-body">
        <div class="day-body-head">Economic</div>
        <div class="day-body-inner">
          <Checklist :items="[{name: 'test'}]"></Checklist>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import moment from 'moment';
import { Checklist } from '@lib/components'

const props = defineProps({
  entries: {
    type: Array,
    default: () => []
  }
})

// Current week start date
const weekStart = ref(moment().startOf('isoWeek'));

// Computed property for days of the week
const weekDays = computed(() => {
  return Array.from({ length: 7 }).map((_, index) =>
    moment(weekStart.value).add(index, 'days')
  );
});

const days = computed(() => {
  const output = {};
    props.entries.forEach(item => {
        const key = item.date;
        if (!output[key]) {
            output[key] = [];
        }
        output[key].push(item);
    });
    return output;
})
</script>

<style>
.weekly-calendar {
  display: flex;
  justify-content: space-evenly;
  gap: 8px;
}
.day {
  width: 100%;
}

.day-head {
  text-align: center;
  border-radius: 16px;
  background: #F6F7F7;
  padding: 10px;
} 

.day-body {
  margin-top: 16px;
}

.day-body-head {
  margin-bottom: 8px;
}

.day-body-inner {
  text-align: center;
  border-radius: 16px;
  background: #F6F7F7;
  padding: 10px;
} 
</style>
