<template>
	<UiBack route="/education/courses"></UiBack>
	<div class="row gap-1">
			<UiThumbnails>
				{{ thumbnails.length }} Sections
				<div v-for="(thumbnail, index) in thumbnails">
					<UiLocked :text="null" :unlocked="index === 0" @click-locked="onLocked">
						<UiThumbnail v-bind="thumbnail"></UiThumbnail>
						<UiProgressbar :progress="0"></UiProgressbar>
					</UiLocked>
				</div>
			</UiThumbnails>
			<div style="width: 100%;">
				<div v-for="(item, index) in 5">
					<UiLocked :text="null" :unlocked="index === 0" @click-locked="onLocked">
						<UiCard title="Week 1 Introduction: Personalized Trade Data Analysis and Mindset Assessment" collapsible expanded>
							<!-- <Presentation></Presentation> -->
						    <UiVideo :thumbnail="index === 0 ? '/img/thumbnail-week-1-intro.png' : ''" youtube="https://www.youtube.com/embed/uaGU7GaRzdo?controls=1&rel=0&showinfo=0&modestbranding=1&autoplay=1" description="Introduction to Our 7-Week Trading Program"></UiVideo>
						</UiCard>
					</UiLocked>
					<br>
				</div>
			</div>
		</div>
		<UiModal v-if="showModal" @close="showModal = false">
			<div style="text-align: center;">
				<h1>Unlock Full Course Access</h1>
				<br>
				<p>
					You're currently viewing the free version of the course. Get full access to all lectures and materials by purchasing the complete course.
				</p>
				<br>
				<br>
				<a href="https://buy.stripe.com/8wM02Y42l6wx56UcMS" class="btn">Purchase Full Course</a>
			</div>
		</UiModal>
</template>

<script setup>
	import { ref } from "vue"
	import Presentation from "@/components/presentation/Presentation"

	const showModal = ref(false)

	const thumbnails = ref([
		{
			title: "Week 1: Personalized Trade Data Analysis and Mindset Assessment",
			subtitle: "Laying the Foundation for Your Trading Success"
		},
		{
			title: "Week 2: Tailoring Solutions to Your Trading Challenges",
			subtitle: ""
		},
		{
			title: "Week 3: Crafting Your Personal Improvement Plan",
			subtitle: ""
		},
		{
			title: "Week 4: Crafting Your Personal Improvement Plan (Part 2)",
			subtitle: ""
		},
		{
			title: "Week 5: Progress Review and Risk Strategy Refinement",
			subtitle: ""
		},
		{
			title: "Week 6: Progress Review and Risk Strategy Refinement (Part 2)",
			subtitle: ""
		},
		{
			title: "Week 7: Comprehensive Trading Strategy Assessment",
			subtitle: ""
		},
	])

	const onLocked = () => {
		showModal.value = true
	}
</script>