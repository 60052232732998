<template>
  <div>
    <div>
      <div v-for="(row, rowIndex) in parsedSchema.rows" :key="rowIndex" class="form-row">
        <div v-for="(column, colIndex) in row.columns" :key="colIndex" class="form-column">
          <div v-for="(field, fieldIndex) in column.fields" :key="fieldIndex" class="form-field" :class="`form-field-${field.type}`">
            <template v-if="field.type === 'textarea'">
              <label :for="field.name">{{ field.label }}</label>
              <textarea
                :id="field.name"
                :name="field.name"
                :placeholder="field.placeholder"
                v-model="formData[field.name]"
                :required="field.required"
                :class="field.classes"
                v-if="mode === 'form'"
              ></textarea>
              <div v-else>{{ formData[field.name] }}</div>
            </template>
            <template v-else-if="field.type === 'text'">
              <label :for="field.name">{{ field.label }}</label>
              <input
                :type="field.type"
                :id="field.name"
                :name="field.name"
                :placeholder="field.placeholder"
                v-model="formData[field.name]"
                :required="field.required"
                :class="field.classes"
                @input="onInput"
                v-if="mode === 'form'"
                @keyup.enter="$emit('enter')"
              />
              <div v-else>{{ formData[field.name] }}</div>
            </template>
            <template v-else-if="field.type === 'select'">
              <label :for="field.name">{{ field.label }}</label>
              <select
                :id="field.name"
                :name="field.name"
                v-model="formData[field.name]"
                :required="field.required"
                :class="field.classes"
                v-if="mode === 'form'"
              >
                <option
                  v-for="(option, optionIndex) in field.options"
                  :key="optionIndex"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
              <div v-else>{{ formData[field.name] }}</div>
            </template>
            <template v-else-if="field.type === 'submit'">
              <UiButton
                class="btn btn-l submit"
                :type="field.type"
                :id="field.name"
                :name="field.name"
                :placeholder="field.placeholder"
                :class="field.classes"
                @click="submitForm"
                :loading="submitting"
                v-if="mode === 'form'"
              >
              {{ field.label }}
              </UiButton>
              <div v-else>{{ formData[field.name] }}</div>
            </template>
            <template v-else-if="field.type === 'checkbox'">
              <label :for="field.name" v-if="mode === 'form'">
                <input
                  :type="field.type"
                  :id="field.name"
                  :name="field.name"
                  :placeholder="field.placeholder"
                  v-model="formData[field.name]"
                  :required="field.required"
                  :class="field.classes"
                />
                <span v-html="$t(field.label)"></span>
              </label>
              <div v-else>{{ formData[field.name] }}</div>
            </template>
            <template v-else-if="field.type === 'toggle_switch'">
              <label :for="field.name">{{ field.label }}</label>
              <UiToggleSwitch v-model="formData[field.name]" v-if="mode === 'form'"></UiToggleSwitch>
              <div v-else>{{ formData[field.name] }}</div>
            </template>
            <template v-else-if="field.type === 'select_group'">
              <label :for="field.name" v-if="field.label">{{ field.label }}</label>
              <UiSelectGroup :name="field.name" :options="field.options" v-model="formData[field.name]" v-if="mode === 'form'"></UiSelectGroup>
              <div v-else>{{ formData[field.name] }}</div>
            </template>
            <template v-else-if="field.type === 'paragraph'">
              <label :for="field.name" v-if="field.label">{{ field.label }}</label>
              <p>{{ field.text }}</p>
            </template>
            <template v-else-if="field.type === 'headline'">
              <UiHeadline size="m">{{ field.text }}</UiHeadline>
            </template>
            <!-- <template v-else-if="field.type === 'richtext'">
              <label :for="field.name">{{ field.label }}</label>
              <Editor v-model="formData[field.name]" :class="field.classes"></Editor>
            </template> -->
            <template v-else>
              <label :for="field.name">{{ field.label }}</label>
              <input
                :type="field.type"
                :id="field.name"
                :name="field.name"
                :placeholder="field.placeholder"
                v-model="formData[field.name]"
                :required="field.required"
                :class="field.classes"
                v-if="mode === 'form'"
                @keyup.enter="$emit('enter')"
              />
              <div v-else>{{ formData[field.name] }}</div>
            </template>

            <!-- Field Error -->
            <template v-if="errors && errors[field.name]">
              <p style="color: #FF2C46;margin-top: 4px;">{{ $t(`errors.form.${errors[field.name][0]}`) }}</p>
            </template>
          </div>
        </div>
      </div>
      
      <template v-if="errors">
        <p v-if="errors.form" style="color: #FF2C46;">{{ $t(`errors.form.${errors.form}`) }}</p>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, watch, watchEffect, computed } from 'vue';
// import Editor from 'primevue/editor';

export default {
  props: {
    schema: String,
    modelValue: {
      type: Object,
      default: () => ({})
    },
    errors: Object,
    submitting: Boolean,
    mode: {
      type: String,
      default: 'form'
    }
  },
  setup(props, { emit }) {
    const parsedSchema = computed(() => JSON.parse(props.schema));
    const formData = ref({ ...props.modelValue });

    watch(() => formData.value, () => {
      emit('update:modelValue', formData.value);
    }, {
      immediate: true
    });

    watch(() => props.modelValue, () => {
      formData.value = props.modelValue
    })

    const submitForm = () => {
      emit('submit')
    };

    const onInput = () => {
      console.log(formData.value)
      emit('update:modelValue', formData.value);
    }

    return {
      parsedSchema,
      formData,
      submitForm
    };
  }
};
</script>

<style>
/* Add your CSS styles for the form elements here */
.form-row {
  margin-bottom: 10px;
}

.form-column {
  flex: 1;
}

.form-field {
  margin-bottom: 10px;
}

textarea {
  width: 100%;
  height: 100px; /* You can adjust the height as needed */
}

@media (min-width: 768px) {
  .form-row {
    display: flex;
    gap: 1rem;
  }
}
</style>
