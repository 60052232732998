import store from "../../src/store"

import AdminView from "./Admin/AdminView"
import AdminOverviewView from "./Admin/AdminOverviewView"
import AdminModuleView from "./Admin/AdminModuleView"
import TermsAndConditionsView from "./TermsAndConditionsView"
import EmailVerificationView from "./EmailVerificationView"
import PrivacyView from "./PrivacyView"
import WaitingListView from "./WaitingListView"

const guard = (to, from, next, redirectRoute = "/login") => {
  if (store.state.auth.authenticated) {
    next();
  } else {
    store
      .dispatch("auth/authenticate", {
        root: true,
      })
      .then(() => {
        store.state.auth.authenticated ? next() : next(redirectRoute);
      })
      .catch(() => {
        next(redirectRoute);
      });
  }
};

const auth = (to, from, next, redirectRoute = "/auth") => {
  if (!store.state.auth.authenticated && store.state.auth.accessToken) {
    store
      .dispatch("auth/authenticate", {
        root: true,
      })
      .then(() => {
        return store.state.auth.authenticated ? next() : next(redirectRoute);
      })
      .catch(() => {});
  } else {
    next();
  }
};

const loggedIn = (to, from, next) => {
  store
    .dispatch("auth/authenticate", {
      root: true,
    })
    .then(() => (store.state.auth.authenticated ? next("/") : next()))
    .catch(() => next());
};

export default [
	{
      path: '/admin',
      component: AdminView,
      beforeEnter: auth,
      children: [
        {
          path: '',
          name: 'admin',
          component: AdminOverviewView,
          beforeEnter: auth,
        },
        {
          path: 'm/:moduleKey',
          name: 'admin_module',
          component: AdminModuleView,
          beforeEnter: auth,
        },
      ]
    },
    {
      path: '/terms-and-conditions',
      component: TermsAndConditionsView,
      beforeEnter: auth
    },
    {
      path: '/privacy',
      component: PrivacyView,
      beforeEnter: auth
    },
    {
      path: '/email-verification',
      component: EmailVerificationView,
      beforeEnter: auth
    },
    {
      path: '/waiting-list',
      component: WaitingListView,
      beforeEnter: auth
    }
]