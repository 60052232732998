<template>
	<div class="list-item" style="display:flex;justify-content: space-between;">
		<div style="display:flex;">
			<div style="width: 128px;">
				<div class="label"><small>Time</small></div>
				<div>{{ _time }}</div>
				<div>
					<small>{{ _duration }}</small>
				</div>
				<div class="label"><small>{{ symbol }}</small></div>
			</div>
			<div style="width: 96px;">
				<div class="label"><small>PNL</small></div>
				<div :class="{'trade-win':pnl > 0,'trade-loss':pnl < 0,}">{{ pnl }}</div>
				<div class="label"><small>Points</small></div>
				<div>{{ points.toFixed(2) }}</div>
			</div>
			<div style="width: 128px;">
				<div><small><span  class="label">Qty:</span> {{ qty }}</small></div>
				<div><small>{{ direction }}</small></div>
			</div>
			<div style="width: 128px;">
				<div class="label"><small>Entry</small></div>
				<div>{{ _entryPrice }}</div>
				<div class="label"><small>Exit</small></div>
				<div>{{ _exitPrice }}</div>
			</div>
		</div>
		<UiCol gap="1" justify="end">
			<Dropdown v-model="model.playbook" :options="playbooks" optionLabel="label" optionValue="value" placeholder="Select Playbook" class="w-full md:w-14rem" @change="onChange"/>
			<MultiSelect v-model="model.playbook" display="chip" :options="playbooks" optionLabel="label" optionValue="value" placeholder="Tags"
        :maxSelectedLabels="3" class="w-full md:w-20rem" />
			<UiCol v-for="tag in tags">
				<UiBadge>{{ tag }}</UiBadge>
			</UiCol>
		</UiCol>
	</div>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"
	import Dropdown from 'primevue/dropdown';
	import MultiSelect from 'primevue/multiselect';

	const store = useStore()
	const selectedPlaybook = ref()
	const saving = ref()
	const model = ref({
		playbook: null
	})

	const playbooks = computed(() => store.getters["dashboard/availablePlaybooks"])

	const props = defineProps({
		id: String,
		_time: String,
		symbol: String,
		direction: String,
		pnl: String,
		qty: String,
		_entryPrice: Number,
		_exitPrice: Number,
		_duration: String,
		tags: Array,
		playbook: String,
		accountId: String,
		points: Number
	})

	model.value = {
		playbook: props.playbook
	}

	const save = () => {
		if (saving.value) return
	    saving.value = true
	    store.dispatch('dashboard/updateTrade', {
	      accountId: props.accountId,
	      tradeId: props.id,
	      data: {
	      	playbook: model.value.playbook.id
	      }
	    }).then(res => {
	      saving.value = false
	    }).catch(() => {
	      saving.value = false
	    })
	}

	const onChange = () => {
		save()
	}
</script>