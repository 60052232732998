<template>
	<!-- Back:Mobile -->
	<div class="mobile">
		<UiBack route="/" text="Back to Dashboard" fixed></UiBack>
	</div>

	<UiCard>
		<!-- Headline -->
		<UiHeadline class="mb-8" size="m">{{ $t('view.settings.title') }}</UiHeadline>

		<div class="mobile">
			<UiSidebar :items="navigationItems"></UiSidebar>
		</div>

		<!-- Form -->
		  <div style="max-width: 512px;margin-top: 42px;">
		  	<div class="form-row">
				  <div class="form-field">
				      <label>{{ $t('view.settings.language') }}</label>
				      <select
				        type="number"
				        placeholder="0.00"
				        v-model="currentLanguage"
				        @change="changeLanguage"
				      >
				      	<option v-for="lang in languages" :value="lang.value">{{ lang.name }}</option>
				  	</select>
				  </div>
			  </div>
		  </div>
	</UiCard>
</template>

<script setup>
	import { ref, computed, watch, getCurrentInstance } from "vue"
	import { useStore } from "vuex"
	import { useI18n } from 'vue-i18n';
	import cookie from "@lib/services/cookie";

	const { locale } = useI18n();
	const store = useStore();
	const currentLanguage = ref(null)

	const instance = getCurrentInstance();
  const t = instance.appContext.config.globalProperties.$t;

	const languages = ref([
		{
			name: "English",
			value: "en"
		},
		// {
		// 	name: "Spanish",
		// 	value: "es"
		// },
		// {
		// 	name: "German",
		// 	value: "de"
		// },
		// {
		// 	name: "French",
		// 	value: "fr"
		// },
		// {
		// 	name: "Chinese",
		// 	value: "zh"
		// },
		// {
		// 	name: "Russian",
		// 	value: "ru"
		// },
	])

	watch(() => store.state.dashboard.config.language, () => {
		currentLanguage.value = store.state.dashboard.config.language
	},{
		immediate: true
	}) 

    const changeLanguage = (lang) => {
      cookie.set("lang", currentLanguage.value);
      update()
      window.location.reload()
    }

   const update = () => {
		store.dispatch('user/updateConfig', {...store.state.dashboard.config, language: currentLanguage.value})
		store.dispatch('dashboard/updateConfig', {...store.state.dashboard.config, language: currentLanguage.value})
	}

	const navigationItems = ref([
		{
          name: t('view.settings.sidebar.account'),
          icon: "folder",
      	  key: "settings",
          route: "/settings/profile"
        },
        {
          name: t('view.settings.sidebar.subscriptions'),
          icon: "folder",
      	  key: "settings",
          route: "/settings/subscriptions"
        },
        {
          name: t('view.settings.sidebar.credits'),
          icon: "folder",
      	  key: "points",
          route: "/settings/points"
        },
        {
          name: t('view.settings.sidebar.affiliate'),
          icon: "folder",
      	  key: "points",
          route: "/settings/affiliate"
        },
        ...store.state.user.user.isAdmin ? [
        	{
	          name: 'Import Account',
	          icon: "folder",
	      	  key: "settings",
	          route: "/settings/import"
	        }
        ] : [],
        {
          name: t('view.settings.sidebar.configuration'),
          icon: "folder",
      	  key: "settings",
          route: "/settings/configuration"
        },
	])
</script>