<template>
	<UiSkeleton height="512px" width="512px" v-if="loading && !desk"></UiSkeleton>
	<template v-else-if="desk">
		<Form :schema="formSchema" v-model="model" @submit="submit" :errors="errors" :submitting="submitting" style="max-width: 512px;"></Form>
	</template>
	<template v-else>
		<UiGridTile>Trading desk not setup</UiGridTile>
		<p style="margin-top: 8px;">Send an email to info@purdia.com to request trading desk setup.</p>
	</template>
</template>

<script setup>
import { ref, computed } from "vue"
import { useStore } from "vuex"

const store = useStore()
const model = ref({
	name: '',
	streamUrl: ""
})
const submitting = ref(false)
const errors = ref()
const loading = ref(false)
const desk = computed(() => store.state.dashboard.partnerDesk)

const fetch = async () => {
	return new Promise(resolve => {
		store.dispatch("dashboard/getPartnerDesk").then((res) => {
	    	loading.value = false
	    	const { name, description, streamUrl } = res.data.desk

	    	model.value = {
	    		name,
	    		description,
	    		streamUrl
	    	}
			resolve()
		}).catch(err => {

		})
	})
}

loading.value = true

fetch()

const submit = () => {

}


const formSchema = ref(`{
  "rows": [
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "key": "name",
	              "type": "text",
	              "label": "Desk Name",
	              "name": "name",
	              "placeholder": "",
	              "required": true
	            }
	          ]
	        }
	      ]
	    },
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "key": "description",
	              "type": "textarea",
	              "label": "Description",
	              "name": "description",
	              "required": false
	            }
	          ]
	        }
	      ]
	    },
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "key": "streamUrl",
	              "type": "text",
	              "label": "Stream",
	              "name": "streamUrl",
	              "placeholder": "URL",
	              "required": false
	            }
	          ]
	        }
	      ]
	    },
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "submit",
	              "label": "Save"
	            }
	          ]
	        }
	      ]
	    }
	  ]
	}
`);
</script>