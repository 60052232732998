<template>
	<TradingDays :account-id="route.params.accountId" @select="onSelectDate" style="width: 100%;"></TradingDays>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"
	import { useRoute, useRouter } from "vue-router"
	import TradingDays from "@/components/TradingDays"

	const store = useStore()
	const route = useRoute()
	const router = useRouter()

	const account = computed(() => store.state.dashboard.account)

	const onSelectDate = (date) => {
		router.push(`/trading/accounts/${route.params.accountId}/days/${date}`)
	}
</script>