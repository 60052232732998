<template>
	<SidebarLayout :items="items" title="Bonus"></SidebarLayout>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"
	import { SidebarLayout } from "@lib/components"

	const items = ref([
		{
			name: "Invite Friend",
			path: "/bonus",
			icon: "trading"
		}
	])
</script>