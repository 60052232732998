<template>
	<UiBack :route="`/trading/accounts/${$route.params.accountId}`"></UiBack>
		<!-- Page Title -->
    <UiHeadline style="margin-bottom: 42px;">Contract</UiHeadline>

    <div style="display: flex;justify-content: space-between;align-items: center;">
    	<div>
	    	<!-- Headline -->
	    	<div style="display: flex;gap: 4px;align-items: center;margin-bottom: 71px;">
			    <div style="color: #000;font-size: 24px;font-style: normal;font-weight: 500;line-height: normal;">
			      Sign Contract
			    </div>
			</div>

			<div style="max-width: 512px; margin-top: 71px;">
				<Form :schema="formSchema" v-model="model" @submit="submit" :errors="errors"></Form>
			</div>

			<p>
				Please take a moment to read and sign the Funded Trader Contract. By signing this agreement, you acknowledge and agree to the terms and conditions outlined within. It is important to carefully review the document before proceeding.
			</p>
		</div>
	</div>
</template>

<script setup>
	import { ref } from 'vue'
	import { useStore } from 'vuex'
	import { useRoute } from 'vue-router'

	const route = useRoute()
	const store = useStore()

	const model = ref({
		idNumber: "",
		ssn: "",
		signature: "",
		cancelUrl: `https://${window.location.host}/trading/accounts/${route.params.accountId}?activation_fee=canceled`,
		successUrl: `https://${window.location.host}/trading/accounts?activation_fee=paid`
	})
	const submitting = ref(false)
  	const errors = ref(null)

	const submit = () => {
	    if (submitting.value) return
	    store.dispatch("dashboard/signTradingAccountContract", {
	    	accountId: route.params.accountId,
	    	data: {
	    		...model.value,
	    	}
	    })
	    .then((res) => {
	      submitting.value = false;
	      window.location = res.data.url;
	    })
	    .catch((err) => {
	      errors.value = err.response.data.errors;
	      submitting.value = false;
	    });
	  }

		const formSchema = ref(`{
		  "rows": [
		    {
		      "columns": [
		        {
		          "fields": [
		            {
		              "type": "text",
		              "label": "State or Passport ID Number",
		              "name": "idNumber",
		              "required": true
		            }
		          ]
		        }
		       ]},
		       {
		      "columns": [
		        {
		          "fields": [
		            {
		              "type": "text",
		              "label": "SSN (for US citizen)",
		              "name": "ssn",
		              "required": true
		            }
		          ]
		        }
		       ]},
		       {
		      "columns": [
		        {
		          "fields": [
		            {
		              "type": "text",
		              "label": "Signature (Full Name)",
		              "name": "signature",
		              "required": true
		            }
		          ]
		        }
		       ]},
		       {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "submit",
	              "label": "Sign Contract"
	            }
	          ]
	        }
	      ]
	    }
	       ]
	   }`
        );
</script>