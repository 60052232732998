<template>
		<!-- Back:Mobile -->
	<div class="mobile">
		<UiBack route="/settings" text="Back" fixed></UiBack>
	</div>

	<UiCard>
		<!-- Headline -->
		<UiHeadline class="mb-8" size="m">Subscriptions</UiHeadline>

		<!-- Subscriptions List -->
	<UiList style="max-width: 512px;" v-if="subscriptions.length">
		<router-link :to="`/settings/subscriptions/${subscription.id}`" v-for="subscription in subscriptions">
			<SubscriptionListItem v-bind="subscription"></SubscriptionListItem>
		</router-link>
	</UiList>
	<UiSkeleton style="width: 100%;max-width: 512px;border-radius: 16px;" height="44px" v-else-if="loading"></UiSkeleton>
	<UiGridTile v-else>
		<span v-html="$t('view.settings.subscriptions.no_subscriptions')"></span>
	</UiGridTile>

	<div class="mt-16" style="max-width: 512px;" v-if="false">
		<UiHeadline size="m" class="mb-2">Membership</UiHeadline>

		<UiRow gap="4">
			<UiGridTile full>
				<UiHeadline size="m">Basic</UiHeadline>
				<div>Free</div>
				<UiCol gap="1" class="mt-4">
					<UiRow gap="2" v-for="benefit in basicBenefits">
						<UiBadge size="s"><Icon icon="check" size="16"/></UiBadge> 
						<div>{{ benefit }}</div>
					</UiRow>
				</UiCol>
			</UiGridTile>
			<UiGridTile full>
				<UiHeadline size="m">Pro</UiHeadline>
				<div>1 Year Licence</div>

				<UiCol gap="1" class="mt-4">
					<UiRow gap="2" v-for="benefit in proBenefits">
						<UiBadge size="s"><Icon icon="check" size="16"/></UiBadge> 
						<div>{{ benefit }}</div>
					</UiRow>
				</UiCol>

				<UiRow justify="end" class="mt-4">
					$299/{{ $t('view.settings.subscriptions.one_time') }}
				</UiRow>

				<UiButton size="s" class="mt-4" full>Upgrade to Pro</UiButton>
			</UiGridTile>
		</UiRow>
	</div>
</UiCard>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"
	import SubscriptionListItem from "@/components/subscription/SubscriptionListItem"

	const store = useStore()
	const loading = ref(false)

	const subscriptions = computed(() => store.state.dashboard.subscriptions)

	loading.value = true
	store.dispatch("dashboard/getSubscriptions").then(() => {
		loading.value = false
	}).catch(() => {
		loading.value = false
	})

	const basicBenefits = ['Evaluation Dashboard']
	const proBenefits = ['Evaluation Dashboard', 'Pro Trading Room', 'Discord', 'News & Events', 'Journal', 'Performance', 'Economical Calendar', 'Risk Desk']
	const moreProBenefits = ['Pre Trading Checklist', 'Psychological Evaluation']
</script>