<template>
  <LayoutMain>
    <div class="container">
      <S2 badge="Premier Program" title="7-Week Trading Mastery" text="Your Pathway to Trading Excellence">
        <UiVideo thumbnail="/img/program-thumbnail.png" youtube="https://www.youtube.com/embed/Pnl8uey1iXQ?si=yPJzq1ziE2YNuy8N?controls=1&rel=0&showinfo=0&modestbranding=1&autoplay=1"></UiVideo>
        <p>
          "Embark on a journey that transforms your trading from the ground up. Our 7-Week Trading Mastery program is meticulously designed to guide you through every aspect of trading. From mastering market analysis to fine-tuning risk management and exploring the psychological aspects of trading, this program is your stepping stone to becoming a confident and skilled trader."
        </p>
        <br>
        <router-link class="btn btn-cta btn-l" to="/sign-up">Start 7-Week Training</router-link>
      </S2>

      <div v-for="item in items">
        <ListItem1 @donate="show = true" v-bind="item"></ListItem1>
        <br>
      </div>
      <br>
      <router-link class="btn btn-cta btn-l" to="/sign-up">Start 7-Week Training</router-link>
      <br><br><br><br><br>
    </div>
  </LayoutMain>
</template>

<script setup>
  import { ref } from "vue"
  import S2 from "@/components/S2"
  import ListItem1 from "@/components/ListItem1"

  const items = ref([
    {
      title: "Week 1: Personalized Trade Data Analysis and Mindset Assessment",
      description: "In our first week, we'll dive into a personalized analysis of your trade data and conduct a comprehensive assessment of your trading mindset. Understanding your unique trading style and psychological approach is key to shaping your path to success.",
      // tags: ["Beginner", "Free"]
    },
    {
      title: "Week 2: Tailoring Solutions to Your Trading Challenges",
      description: "Week 2 is all about focusing on your unique trading challenges. We'll identify key areas for improvement by reviewing your trade performance and psychological assessment, ensuring our approach is tailored just for you.",
    },
    {
      title: "Weeks 3-4: Crafting Your Personal Improvement Plan",
      description: "During weeks 3 and 4, we'll craft a bespoke improvement plan that resonates with your trading goals. We'll also guide you to curated educational resources, providing the knowledge and skills crucial for your development.",
    },
    {
      title: "Week 5-6: Progress Review and Risk Strategy Refinement",
      description: "Weeks 5 and 6 are focused on progress evaluation. We'll refine your improvement plan and focus on developing robust risk management strategies, ensuring you're equipped to navigate the complexities of trading effectively.",
    },
    {
      title: "Week 7: Comprehensive Trading Strategy Assessment",
      description: "In our final week, we'll conduct an in-depth analysis of your trading and risk management strategies. This comprehensive evaluation will highlight areas for ongoing growth, setting the stage for your continued success in trading.",
    },
  ])
</script>