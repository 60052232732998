<template>
	<table v-if="module">
		<tr>
			<th v-for="field in module.fields">
				{{ field.name }}
			</th>
		</tr>
		<tr v-for="item in items">
			<td v-for="field in module.fields">
				{{ item.data[field.key] }}
			</td>
		</tr>
	</table>
</template>

<script setup>
  import { ref } from "vue"
  import { useStore } from "vuex"
  import { useRoute } from "vue-router"

  const store = useStore()
  const route = useRoute()
  const module = ref(null)
  const items = ref([])

  store.dispatch("admin/getCrmModuleItems", {
  	reference: route.params.moduleKey
  }).then((res) => {
  	items.value = res.data.items
  	module.value = res.data.module
  })
</script>
