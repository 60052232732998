<template>
	<div class="ui-label">
		<slot></slot>
	</div>
</template>

<style scoped>
	.ui-label {
		color: #6b7280;
	}
</style>