<template>
  <TradingChart></TradingChart>
  <UiRow gap="2" style="height: 100%;max-height: 256px;">
      <UiGridTile full grow>
        <div>Risk Desk</div>
        <UiRow gap="2">
          <UiButton round color="black" size="s">Lock Account</UiButton>
          <UiButton round color="danger" size="s">Exit all Market</UiButton>
        </UiRow>
      </UiGridTile>
      <UiGridTile full grow>
        <div>Events</div>
        <UiListItem v-for="item in 2">
          AAPL 03 FEB Long Call 1 4.7500
        </UiListItem>
      </UiGridTile>
    </UiRow>
</template>

<script setup>
	import { ref } from "vue"
	import TradingChart from "@/components/TradingChart"

	const formSchema = ref(`{
  "rows": [
    {
      "columns": [
        {
          "fields": [
            {
              "key": "mental_feel",
              "type": "textarea",
              "label": "How do you feel?",
              "name": "mental_feel",
              "placeholder": "",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "mental_sleep",
              "type": "select_group",
              "label": "How was your sleep?",
              "name": "mental_sleep",
              "required": true,
              "options": [{"value": "good","name": "Good"},{"value": "bad","name": "Bad"}]
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "mental_stress",
              "type": "select",
              "label": "Stress Level",
              "name": "password",
              "required": true,
              "default": "low",
              "options": [{"value": "low","label": "Low to no stress"},{"value": "moderate","label": "Slightly stressed"},{"value": "high","label": "Strongly stressed"}]
            }
          ]
        }
      ]
    }
  ]
}
`);

	const tradeReportFormSchema = ref(`{
  "rows": [
  	{
      "columns": [
        {
          "fields": [
            {
              "key": "analysis",
              "type": "textarea",
              "label": "Analysis",
              "name": "analysis",
              "placeholder": "",
              "required": false
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "idea",
              "type": "textarea",
              "label": "Idea",
              "name": "idea",
              "placeholder": "",
              "required": false
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "strategy",
              "type": "text",
              "label": "Strategy",
              "name": "strategy",
              "placeholder": "",
              "required": false
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "stop_level",
              "type": "number",
              "label": "Stop Loss Level",
              "name": "stop_level",
              "placeholder": "",
              "required": false
            }
          ]
        },
        {
          "fields": [
            {
              "key": "take_profit_level",
              "type": "number",
              "label": "Take Profit Level",
              "name": "take_profit_level",
              "placeholder": "",
              "required": false
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "risk",
              "type": "number",
              "label": "Risk %",
              "name": "stop_level",
              "placeholder": "",
              "required": false
            }
          ]
        },
        {
          "fields": [
            {
              "key": "risk",
              "type": "number",
              "label": "Pos. Size",
              "name": "stop_level",
              "placeholder": "",
              "required": false
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "key_levels",
              "type": "textarea",
              "label": "Key Levels",
              "name": "key_levels",
              "placeholder": "",
              "required": false
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "key_levels",
              "type": "textarea",
              "label": "Entry Critera",
              "name": "key_levels",
              "placeholder": "",
              "required": false
            }
          ]
        }
      ]
    }
  ]
}
`);
</script>