<template>
	<UiRow v-if="activeDataPlans">
      <UiGridTile v-for="plan in activeDataPlans">
        {{ plan.title }}
      </UiGridTile>
    </UiRow>
</template>

<script setup>
	import { computed } from "vue"
	import { useStore } from "vuex"

	const store = useStore()

	const marketData = computed(() => store.state.dashboard.marketData)
	const marketDataPlans = computed(() => store.getters["dashboard/availableMarketDataPlans"])
	const activeDataPlans = computed(() => marketData.value.map(m => marketDataPlans.value.find(p => m.marketDataSubscriptionPlanId === p.id)))

	store.dispatch("dashboard/getMarketDataPlans").then(() => {
		store.dispatch("dashboard/getMarketData").then().catch(() => {})
	}).catch(() => {})
</script>