<template>
	<div>
		<router-link :to="`/trading/accounts/${account.id}`" v-for="account in accounts" style="background: #F6F7F7;border-radius: 16px; padding: 10px;margin-bottom: 8px;display: block;">
			<!-- Account Name -->
			<div style="margin-bottom: 16px;display: flex;justify-content: space-between;">
		        <div style="color: #000;font-size: 24px;font-style: normal;font-weight: 500;line-height: normal;">
		          {{ account.name }}
		        </div>
		        <div style="display:flex;gap: 8px;">
		        	<template v-if="accountsSyncStatus.find(s => s.accountId === account.id && s.status == 'failed')">
		        		<UiBadge type="danger">Broker Synchronization</UiBadge>	
		        	</template>
		        	<UiBadge type="danger" v-if="account.failed">Failed</UiBadge>
		        	<UiBadge type="success" v-if="account.passed">Passed</UiBadge>
		        </div>
	        </div>

	        <!-- Evaluation Name -->
	        <div style="color: #000;font-size: 17px;font-style: normal;font-weight: 500;line-height: normal;" v-if="account.evaluationName">
	        	{{ account.evaluationName }}
	        </div>

	        <!-- Balance -->
            <div style="display: flex;gap: 9px;justify-content: space-between;">
              <div style="color: #000;font-size: 48px;font-style: normal;font-weight: 700;line-height: normal;letter-spacing: -2.88px;">
                ${{ account.balance }}
              </div>
              <div><UiButton v-if="account.failed">Reset Account</UiButton></div>
            </div>
	      </router-link>
	</div>
	<UiSyncSpinner :syncing="syncing" :status="syncingStatus" style="margin-top: 24px;"></UiSyncSpinner>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"

	const store = useStore()
	const syncing = ref(false)
	const syncingStatus = ref(null)
	const accountsSyncStatus = ref([])

	const accounts = computed(() => store.state.dashboard.accounts)

	const fetchAccounts = async () => {
		return new Promise(resolve => {
			syncingStatus.value = 'Fetching Accounts'
			store.dispatch("dashboard/getTradingAccounts").then((res) => {
	      resolve()
	    })
		})
	}

	syncing.value = true
	syncingStatus.value = 'Syncing Accounts'
	store.dispatch("dashboard/syncTradingAccounts").then((res) => {
		accountsSyncStatus.value = res.data.syncStatus
    fetchAccounts().then(() => {
    	syncingStatus.value = "Account up to date"
    	syncing.value = false
    })
  }).catch(() => {
  	syncingStatus.value = "Update failed, please try again in 10 minutes"
    syncing.value = false
  })
</script>