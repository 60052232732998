<template>
  <div class="tradingview-widget-container" ref="container" style="height: 100%; width: 100%;">
    <div class="tradingview-widget-container__widget" style="height: calc(100% - 32px); width: 100%;"></div>
    <div class="tradingview-widget-copyright">
      <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
        <span class="blue-text">Track all markets on TradingView</span>
      </a>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useStore } from "vuex"

export default {
  setup() {
    const container = ref(null);
    const store = useStore()

    onMounted(() => {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = JSON.stringify({
        "autosize": true,
        "symbol": "CME_MINI:NQ1!",
        "interval": "60",
        "timezone": "Etc/UTC",
        "theme": store.state.dashboard.config.theme_mode === 'darkmode' ? "dark" : "light",
        "backgroundColor": store.state.dashboard.config.theme_mode === 'darkmode' ? "rgb(18 20 24)" : "",
        "style": "1",
        "locale": "en",
        "enable_publishing": false,
        "allow_symbol_change": true,
        "support_host": "https://www.tradingview.com"
      });
      container.value.appendChild(script);
    });

    onUnmounted(() => {
      if (container.value && container.value.firstChild) {
        container.value.removeChild(container.value.firstChild);
      }
    });

    return { container };
  }
};
</script>

<style>
/* Your CSS styles here */
</style>
