<template>
	<Form :schema="formSchema" v-model="model" @submit="submit"></Form>
</template>

<script setup>
	import { ref } from "vue"
  	import { useStore } from "vuex"

	const store = useStore()
	  const model = ref({
	    email: "",
	    password: ""
	  })
	  const submitting = ref(false)
	  const errors = ref(null)

	  const submit = () => {
	    if (submitting.value) return
	    store.dispatch("auth/login", model.value)
	    .then(() => {
	      submitting.value = false;
	    })
	    .catch((err) => {
	      errors.value = err.response.data.errors;
	      submitting.value = false;
	    });
	  }

	  const formSchema = ref(`{
	  "rows": [
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "feedback",
	              "label": "What features can we add to improve?",
	              "name": "feedback",
	              "placeholder": "",
	              "required": true
	            }
	          ]
	        }
	      ]
	    },
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "submit",
	              "label": "Send Feedback"
	            }
	          ]
	        }
	      ]
	    }
	  ]
	}
	`);
</script>