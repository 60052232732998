<template>
	<Form v-model="model" :schema="schema"></Form>
</template>

<script setup>
	import { ref } from 'vue';
	const model = ref(null)

  	const schema = ref(`{
  "rows": [
    {
      "columns": [
        {
          "fields": [
            {
              "type": "textarea",
              "label": "Sleep Quality and Energy Level",
              "name": "sleep_energy",
              "placeholder": "Rate the quality of your last night's sleep and your current energy level."
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "textarea",
              "label": "Current Mood and Stress Level",
              "name": "mood_stress",
              "placeholder": "Describe your current mood and assess your level of stress."
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "textarea",
              "label": "Confidence and Decision-Making Ability",
              "name": "confidence_decision_making",
              "placeholder": "Assess your level of confidence and ability to make clear decisions today."
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "textarea",
              "label": "Focus, Concentration, and Emotional Stability",
              "name": "focus_concentration_emotional_stability",
              "placeholder": "Evaluate your current level of focus, concentration, and emotional stability."
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "textarea",
              "label": "Mindfulness and Self-Awareness",
              "name": "mindfulness_self_awareness",
              "placeholder": "Reflect on your present moment awareness and recognition of emotional triggers."
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "textarea",
              "label": "Stress Management Techniques",
              "name": "stress_management",
              "placeholder": "List stress management techniques you are prepared to use and rate your level of calmness."
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "textarea",
              "label": "Overall Mindset Assessment",
              "name": "mindset_assessment",
              "placeholder": "Identify your mindset strengths, areas for improvement, and overall mindset readiness for trading."
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "submit",
              "label": "Submit Mindset Check"
            }
          ]
        }
      ]
    }
  ]
}
`)
</script>