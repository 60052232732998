<template>
	<UiList>
		<router-link :to="`/settings/subscriptions/${affiliate.id}`" v-for="affiliate in affiliates">
			<UiListItem>
				<div style="display: flex;justify-content: space-between;">
					<div>{{ affiliate.fullname }}</div>
					<!-- <div style="color: #000;">{{ subscription.price }}<small>/month</small></div> -->
				</div>
			</UiListItem>
		</router-link>
	</UiList>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"

	const store = useStore()

	const affiliates = computed(() => store.state.admin.affiliates)

	store.dispatch("admin/getAffiliates")
</script>