<template>
	<LayoutAdmin>
		<SidebarLayout :items="items"></SidebarLayout>
	</LayoutAdmin>
</template>

<script setup>
	import { LayoutAdmin, SidebarLayout } from "@lib/components"

	import { ref } from "vue"

	const items = ref([
		{
			name: "",
			path: "/a/m/affiliates",
			icon: "affiliates"
		},
		{
			name: "Users",
			path: "/a/m/users",
			icon: "users"
		},
		{
			name: "",
			path: "/a/m/accounts",
			icon: "accounts"
		},
	])
</script>