<template>
    <Form :schema="tradingPlanSchema" v-model="model" @submit="submit"></Form>
</template>

<script setup>
	import { ref } from 'vue';
	import { useStore } from "vuex"
  	import Modal from "@/components/Modal"

  	const store = useStore()
  	const show = ref(false)
  	const model = ref(null)

  	if (store.state.user.user.modules) {
  		model.value = store.state.user.user.modules['trading_plan']
  	}

  	const submit = async () => {
		return new Promise(resolve => {
			store.dispatch("dashboard/updateUserModule", {
				data: model.value,
				moduleKey: 'trading_plan'
			}).then((res) => {}).catch((err) => {})
		})
	}

  	const tradingPlanSchema = ref(`{
	    "rows": [
	      {
	        "columns": [
	          {
	            "fields": [
	              {
	                "type": "text",
	                "label": "Short-Term Trading Goals",
	                "name": "short_term_goals",
	                "placeholder": "Enter your short-term trading goals"
	              },
	              {
	                "type": "text",
	                "label": "Long-Term Trading Goals",
	                "name": "long_term_goals",
	                "placeholder": "Enter your long-term trading goals"
	              }
	            ]
	          }
	        ]
	      },
	      {
	        "columns": [
	          {
	            "fields": [
	              {
	                "type": "number",
	                "label": "Risk Tolerance Percentage",
	                "name": "risk_tolerance",
	                "placeholder": "Enter risk tolerance percentage (e.g., 2%)"
	              },
	              {
	                "type": "text",
	                "label": "Preferred Trading Instruments",
	                "name": "trading_instruments",
	                "placeholder": "List your preferred trading instruments (e.g., stocks, forex)"
	              }
	            ]
	          }
	        ]
	      },
	      {
	        "columns": [
	          {
	            "fields": [
	              {
	                "type": "textarea",
	                "label": "Market Analysis Methodology",
	                "name": "market_analysis_methodology",
	                "placeholder": "Describe your market analysis methodology"
	              },
	              {
	                "type": "textarea",
	                "label": "Trading Strategies",
	                "name": "trading_strategies",
	                "placeholder": "Detail your entry and exit criteria for trades"
	              }
	            ]
	          }
	        ]
	      },
	      {
	        "columns": [
	          {
	            "fields": [
	              {
	                "type": "textarea",
	                "label": "Performance Review and Improvement Plan",
	                "name": "performance_review",
	                "placeholder": "Describe your approach to reviewing and improving trading performance"
	              },
	              {
	                "type": "text",
	                "label": "Trading Schedule",
	                "name": "trading_schedule",
	                "placeholder": "Outline your typical trading schedule"
	              }
	            ]
	          }
	        ]
	      },
	      {
	        "columns": [
	          {
	            "fields": [
	              {
	                "type": "textarea",
	                "label": "Personal Development and Education Plans",
	                "name": "personal_development",
	                "placeholder": "Detail your plans for personal development and ongoing education in trading"
	              },
	              {
	                "type": "textarea",
	                "label": "Stress and Emotional Management Techniques",
	                "name": "stress_management",
	                "placeholder": "Describe your techniques for managing stress and emotions while trading"
	              }
	            ]
	          }
	        ]
	      },
	      {
	        "columns": [
	          {
	            "fields": [
	              {
	                "type": "textarea",
	                "label": "Contingency Plans for Market Volatility and Technical Issues",
	                "name": "contingency_plans",
	                "placeholder": "Outline your plans for handling market volatility and technical issues"
	              },
	              {
	                "type": "submit",
	                "label": "Update Trading Plan"
	              }
	            ]
	          }
	        ]
	      }
	    ]
	  }
	  `)
</script>