<template>
	<SidebarLayout :items="items" title="Trading" expanded>
	</SidebarLayout>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"
	import { useRoute, useRouter } from "vue-router"
	import { SidebarLayout } from "@lib/components"

	const store = useStore()
	const route = useRoute()
	const router = useRouter()

	const account = computed(() => store.getters['dashboard/account'])

	if (route.params.accountId === 'undefined') router.push('/')

	const items = computed(() => [
		{
			name: "Overview",
			path: `/trading/accounts/${route.params.accountId}`,
			icon: "clipboard-list",
		},
		{
			name: "Journal",
			path: `/trading/accounts/${route.params.accountId}/days`,
			icon: "pencil"
		},
		{
			name: "Trades",
			path: `/trading/accounts/${route.params.accountId}/trades`,
			icon: "trading",
			proFeature: true
		},
		{
			name: "Calendar",
			path: `/trading/accounts/${route.params.accountId}/calendar`,
			icon: "calendar",
			proFeature: true
		},
		{
			name: "Reports",
			path: `/trading/accounts/${route.params.accountId}/reports`,
			icon: "chart-square-bar",
			proFeature: true
		},
		{
			name: "Risk",
			path: `/trading/accounts/${route.params.accountId}/risk`,
			icon: "adjustments",
			proFeature: true
		},
		{
			name: "Playbook",
			path: `/trading/accounts/${route.params.accountId}/playbooks`,
			icon: "folder",
			proFeature: true
		},
		{
			name: "Tags",
			path: `/trading/accounts/${route.params.accountId}/list/tags`,
			icon: "folder",
			proFeature: true
		},
		...(account.value && account.value.type == 'imported') ? [
			{
				name: "Import Trades",
				path: `/trading/accounts/${route.params.accountId}/import`,
				icon: "cloud-upload",
				proFeature: true
			},
		]:[],
		{
			name: "Settings",
			path: `/trading/accounts/${route.params.accountId}/settings`,
			icon: "gear",
		},
	])
</script>