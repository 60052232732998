<template>
	<div class="container">
		Courses
		<br>
		<router-link class="product" :to="`/courses/${course.id}`" v-for="course in courses">
			{{ course.data.title }}
		</router-link>
	</div>
</template>

<script setup>
	import { ref } from "vue"
	import http from "@/services/http";

	const courses = ref([])

	http.get("/v1/crm/list", {
		params: {
			reference: "courses"
		} 
	}).then(res => {
		courses.value = res.data.items
	})
</script>

<style>
	.product {
		height: 128px;
	    width: 200px;
	    flex: none;
	    font-size: 10px;
	    border: 1px solid #f3f4f6;
	    border-radius: 16px;
	}
</style>