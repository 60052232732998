<template>
		<Splitter style="display: flex;flex-wrap: nowrap;height: calc(-73px + 100vh);position: fixed;top: 73px;">
    <SplitterPanel>
        <Splitter layout="vertical">
            <SplitterPanel class="flex align-items-center justify-content-center" :size="10">
            	<UiRow gap="8px" style="margin-bottom: 10px;">
					<div style="width: 100%;display: flex;align-items: center;justify-content: space-between;">
						<UiRow gap="2">
							<div class="form-field" style="width:100%;max-width: 512px;margin: 0;" v-on:keyup.enter="onEnter">
							      <!-- <label>Withdrawal amount (USD)</label> -->
							      <input
							        type="text"
							        placeholder="Find"
							        v-model="searchTerm"
							      />
							      <p v-if="error" style="color: #FF2C46;margin-top: 4px">{{ error }}</p>
							  </div>

							  <!-- Filters -->
							  <UiRow gap="2">
							  	<div v-for="filter in filters" @click="onSelectFilter(filter.key)">
							  		<UiGridTile>{{ filter.name }}</UiGridTile>
							  	</div>
							  </UiRow>
							</UiRow>

						  <!-- Total -->
						  <div>
							<div v-if="pagination" style="display:flex;">
								Total: {{ pagination.totalItems }}
							</div>
						</div>
					</div>
				</UiRow>
            </SplitterPanel>
            <SplitterPanel :size="85">
            	<ScrollPanel style="width: 100%; height: 100%">

                <UiList>
		<div v-for="item in items">
			<UiListItem @click="item.showModal = true">
				<div style="display: flex;justify-content: space-between;color: #000;">
					<div style="display:flex;">
						<div style="width: 256px;">{{ item.name }}</div>
						<div v-html="item.description" style="width: 256px;"></div>
						<div style="width: 128px;">{{ item.status }}</div>
						<div style="width: 128px;">{{ item.info }}</div>
					</div>
					<div style="width: 156px;text-align: right;">{{ item.date }}</div>
					<!-- <div style="color: #000;">{{ subscription.price }}<small>/month</small></div> -->
				</div>
			</UiListItem>

			<!-- User Modal -->
			<UiModal v-if="item.showModal" @close="item.showModal = false">
				<a @click="onLoginAs(item.id)">Login as {{ item.name }}</a>
			</UiModal>
		</div>
	</UiList>
</ScrollPanel>
            </SplitterPanel>
           <SplitterPanel class="flex align-items-center justify-content-center" :size="10">
           		<Paginator :rows="10" :totalRecords="120" :rowsPerPageOptions="[10, 20, 30]"></Paginator>
           </SplitterPanel>
        </Splitter>
    </SplitterPanel>
</Splitter>
</template>

<script setup>
	import { ref, computed, watch } from "vue"
	import { useStore } from "vuex"
	import { useRoute } from "vue-router"
	import cookie from "@lib/services/cookie";
	import Paginator from 'primevue/paginator';
	import Splitter from 'primevue/splitter';
	import SplitterPanel from 'primevue/splitterpanel';
	import ScrollPanel from 'primevue/scrollpanel';


	const showModal = ref(false)
	const searchTerm = ref(null)
	const store = useStore()
	const route = useRoute()

	const pagination = ref(null)
	const filters = ref([])
	const items = computed(() => store.state.admin.moduleItems)

	const fetch = (filterKey) => {
		store.dispatch("admin/getModule", {
			moduleKey: route.params.moduleKey,
			data: {
				filterKey
			}
		}).then(res => {
			pagination.value = res.data.pagination
			filters.value = res.data.filters
		})
	}

	watch(() => route.params.moduleKey, () => {
		fetch()
	},{
		immediate: true
	})

	const onClick = () => {
		showModal.value = true
	}

	const onSelectFilter = (filterKey) => {
		fetch(filterKey)
	}

	const onEnter = () => {
		store.dispatch("admin/getModule", {
			moduleKey: route.params.moduleKey,
			data: {
				search: {
					name: searchTerm.value
				}
			}
		})
	}

	const onLoginAs = (userId) => {
		store.dispatch("admin/authAs", {
			userId,
		}).then(res => {
			const adminAuthToken = cookie.get('access_token')
			cookie.set('admin_access_token', adminAuthToken)
			cookie.set('access_token', res.data.user.token)
			window.location.replace("/");
		})
	}
</script>