<template>
		<!-- Back:Mobile -->
	<div class="mobile">
		<UiBack route="/settings" text="Back" fixed></UiBack>
	</div>
	<UiCard>
		<!-- Headline -->
		<UiHeadline class="mb-8" size="m">{{ $t('view.settings.affiliate.title') }}</UiHeadline>
		
		<UiButton @click="submit" v-if="!$store.state.user.user.isBusiness" :loading="loading">Become an Affiliate</UiButton>
		<p v-else>You are a member of our Affiliate Partner Program</p>
	</UiCard>
</template>

<script setup>
	import { ref } from "vue"
	import { useRouter } from "vue-router"
	import { useStore } from "vuex"

	const router = useRouter()
	const store = useStore()
	const loading = ref(false)

	const submit = () => {
		if (loading.value) return
		loading.value = true
		store.dispatch('dashboard/becomeAffiliate').then(() => {

			setTimeout(() => {
				loading.value = false
				store.dispatch('auth/authenticate')
				router.push({name:"partner",query:{setting_up:true}})
			}, 2000)
		})
	}
</script>