<template>
	<SidebarLayout :items="items" title="Partner" expanded>
	</SidebarLayout>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"
	import { SidebarLayout } from "@lib/components"

	const items = ref([
		{
			name: "Overview",
			path: "/partner",
			icon: ""
		},
		// {
		// 	name: "Trading Desk",
		// 	path: "/partner/desk",
		// 	icon: ""
		// },
		// {
		// 	name: "Products",
		// 	path: "/partner/products",
		// 	icon: ""
		// },
		// {
		// 	name: "Plans",
		// 	path: "/partner/plans",
		// 	icon: ""
		// }
	])
</script>