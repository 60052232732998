import http from "@lib/services/http";
import cookie from "@lib/services/cookie";
import socket from "@lib/services/socket";
import config from "@lib/store/modules/config";

const Api = {
  getChat(payload) {
    return http.get(`/v1/chat/${payload.chatId}`);
  },
};

const chat = {
  namespaced: true,
  state: {
    supportChatId: cookie.get("support_chat_id") ? cookie.get("support_chat_id") : null,
    chat: {},
    messages: [],
    socketConnected: false
  },
  getters: {
    
  },
  mutations: {
    init: (state, payload) => {
      socket.emit("session-start", {
        sessionId: cookie.get("session_id"),
      });
      
      const listenSocket = () => {
        if (socket.connected) {
          state.socketConnected = true
        } else {
          state.socketConnected = false
        }
      }

      listenSocket();

      setInterval(() => {
        listenSocket();
      }, 1000);
    },
    updateChat: (state, payload) => {
      state.chat = payload
    },
    updateMessages: (state, payload) => {
      state.messages = payload
    },
    addMessage: (state, payload) => {
      state.messages.push(payload)
    },
    setSupportChat: (state, payload) => {
      state.supportChatId = payload
    },
  },
  actions: {
    init: (state, payload) => state.commit("init"),
    getChat: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.getChat(payload)
          .then((res) => {
            state.commit("updateChat", res.data);
            state.commit("updateMessages", res.data.messages);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    addMessage: (state, payload) => state.commit("addMessage", payload),
    setSupportChat: (state, payload) => state.commit("setSupportChat", payload)
  },
};

export default chat;