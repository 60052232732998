<template>
	<UiHeadline style="margin-bottom: 42px;">{{ $t('view.trading_day.title') }}</UiHeadline>

		<UiRow align="center" gap="4" style="margin-bottom: 42px;">
			<!-- Back -->
			<UiBack :route="`/trading/accounts/${$route.params.accountId}`" :text="$t('view.trading_day.back_button')"></UiBack>
			|
			<!-- Date -->
			<UiSkeleton height="27.5px" width="118px" v-if="loading && !tradingDay"></UiSkeleton>
		  <UiHeadline size="m" bold  v-else-if="tradingDay">{{ tradingDay._date }}</UiHeadline>
	  </UiRow>

    <div style="display: flex;justify-content: space-between;">
    	<div>
		    <!-- Subtitle -->
		    <div style="color: #000;font-size: 17px;font-style: normal;font-weight: 500;line-height: normal;">
		    	{{ $t('view.trading_day.pnl') }}
		    </div>

		    <!-- Balance -->
		    <UiSkeleton height="61px" width="165px" v-if="loading && !tradingDay"></UiSkeleton>
		    <div style="display: flex;gap: 9px;" v-else-if="tradingDay">
		      <div style="color: #000;font-size: 48px;font-style: normal;font-weight: 700;line-height: normal;letter-spacing: -2.88px;">
		        {{ tradingDay._pnlWithFees }}
		      </div>
		    </div>
	    </div>
	    <div v-if="$store.state.user.user.isAdmin">
	    	<UiButton @click="showReport = !showReport">{{ $t('view.trading_day.journal.button') }}</UiButton>
	    </div>
    </div>

    <!-- Trades -->
    <div style="margin-top: 71px;">
    	<UiCard title="Trading Day Report" style="margin-bottom: 48px;" v-if="showReport">
    		<Form :schema="schema" v-model="model" @submit="submit"></Form>
    	</UiCard>

    	<div style="color: #000;font-size: 24px;font-style: normal;font-weight: 500;line-height: normal;margin-bottom: 16px;">
      {{ $t('view.trading_day.trades.title') }}
    	</div>

    	<UiSkeleton height="512px" v-if="loadingTrades"></UiSkeleton>
    	<div data-v-e7b971b8="" class="content" v-else>
    		<div style="display: flex;flex-direction: column;gap: 2px;">
    			<TradingDayTradeListItem v-for="trade in trades" v-bind="trade" @click="onClickTrade(trade.id)"></TradingDayTradeListItem>
    		</div>
    	</div>
    </div>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"
	import { useRoute, useRouter } from "vue-router"
	import TradingDayTradeListItem from "@/components/TradingDayTradeListItem"

	const store = useStore()
	const route = useRoute()
	const router = useRouter()
	const showLogin = ref(false)
	const trades = ref([])
	const tradingDayReport = ref(null)
	const showReport = ref(false)
	const model = ref({})
	const loading = ref(true)
	const loadingTrades = ref(true)

	const onClickTrade = (tradeId) => {
		router.push(`/trading/accounts/${route.params.accountId}/days/${route.params.date}/${tradeId}`)
	}
	
	const schema = ref(`{
	  "rows": [
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "textarea",
	              "label": "Pre-Trade Analysis",
	              "name": "pre_trade_analysis",
	              "placeholder": "Document the rationale behind the trade. This could include technical analysis, fundamental analysis, news events, market sentiment, or other factors that influenced your decision."
	            }
	          ]
	        }
	      ]
	    },
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "textarea",
	              "label": "Emotional State",
	              "name": "emotional_state",
	              "placeholder": "Reflect on your emotional state before, during, and after the trade. Emotions can significantly impact trading decisions, so it's important to recognize and manage them."
	            }
	          ]
	        }
	      ]
	    },
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "textarea",
	              "label": "Lessons Learned and Reflections",
	              "name": "lessons_learned_and_reflections",
	              "placeholder": "After the trade is completed, it's helpful to write down what you learned, whether the trade was successful or not. Reflect on what worked well and what could be improved."
	            }
	          ]
	        }
	      ]
	    },
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "textarea",
	              "label": "Charts and Screenshots",
	              "name": "charts_and_screenshots",
	              "placeholder": "If applicable, include charts or screenshots of the trade setup, including any technical indicators or patterns used for making trading decisions."
	            }
	          ]
	        }
	      ]
	    },
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "textarea",
	              "label": "Market Conditions",
	              "name": "market_conditions",
	              "placeholder": "Note any relevant market conditions or events that might have impacted the trade or the market as a whole."
	            }
	          ]
	        }
	      ]
	    },
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "textarea",
	              "label": "Adjustments or Notes for Future Trades",
	              "name": "adjustments_notes_for_future_trades",
	              "placeholder": "Based on the trade's outcome and your reflections, note any adjustments or strategies you plan to implement in future trades."
	            }
	          ]
	        }
	      ]
	    },
	    {
      "columns": [
        {
          "fields": [
            {
              "type": "submit",
              "label": "Save Report"
            }
          ]
        }
      ]
    }]}`)

	const account = computed(() => store.state.dashboard.account)
	const tradingDay = computed(() => store.getters["dashboard/tradingDay"])

	store.dispatch("dashboard/setTradingDay", route.params.date)

	store.dispatch("dashboard/getTradingDay", {
		accountId: route.params.accountId,
		date: route.params.date
	}).then((res) => {
      tradingDay.value = res.data
      loading.value = false
    })

    store.dispatch("dashboard/getTradingDayTrades", {
		accountId: route.params.accountId,
		date: route.params.date
	}).then((res) => {
      trades.value = res.data.trades
      loadingTrades.value = false
    })

	store.dispatch("dashboard/getTradingDayReport", {
		accountId: route.params.accountId,
		date: route.params.date
	}).then((res) => {
		if (res.data.tradingDayReport) {
			tradingDayReport.value = res.data.tradingDayReport
      model.value = tradingDayReport.value.report
		}
    })

	const submit = () => {
		store.dispatch("dashboard/updateTradingDayReport", {
			accountId: route.params.accountId,
			date: route.params.date,
			data: model.value
		}).then((res) => {
      
    })
	}
</script>