<template>
	<LayoutUser class="support-chat-layout">
		<div class="support-chat">
				<UiRow class="head" align="center" justify="between">
					<div>{{ $t('support_chat.title') }}</div>
				</UiRow>
				<UiGridTile grow v-if="false">
	        <UiRow gap="2" align="center">
	          <div class="status-indicator"></div>
	          <UiHeadline size="s" bold>{{ $t('support_chat.offline.title') }}</UiHeadline>
	        </UiRow>
	        <p class="text-sm">{{ $t('support_chat.offline.message') }}</p>
	      </UiGridTile>
				<Chat :chat-id="supportChatId" v-else-if="store.state.auth.authenticated"></Chat>
				<div class="support-chat-auth" grow v-else>
					<div>
						<p>
							{{ $t('support_chat.not_logged_in') }}
						</p>
						<UiButton class="mt-8" size="l" full @click="onClickSignUp">{{ $t('support_chat.sign_up') }}</UiButton>
						<UiButton class="mt-4" size="l" color="link" full @click="onClickLogin">{{ $t('support_chat.login') }}</UiButton>
					</div>
				</div>
				<ChatMessageBox :chat-id="supportChatId" chat-type="support" v-if="$store.state.dashboard.isServiceHours || true"></ChatMessageBox>
			</div>
			<!-- Back:Mobile -->
			<div class="mobile" v-if="store.state.auth.authenticated">
				<UiBack route="/" text="Dashboard" fixed></UiBack>
			</div>
	</LayoutUser>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"
	import { useRouter } from "vue-router"
	import socket from "@lib/services/socket";
	import cookie from "@lib/services/cookie";
	import Chat from "@/components/Chat"
	import ChatMessageBox from "@/components/ChatMessageBox"
	import { LayoutUser} from "@lib/components"

	const store = useStore()
	const router = useRouter()
	const show = ref(false)

	store.dispatch("chat/init")
	const supportChatId = computed(() => store.state.chat.supportChatId);

	if (store.state.auth.authenticated && !supportChatId.value) {
      socket.on("chat-created", (data) => {
        cookie.set("support_chat_id", data.chatId);

        store.dispatch("chat/setSupportChat", data.chatId);

        if (data.auth) {
          socket.emit("chat-messages-join", {
            userId: data.auth.user.id,
          });
        }
      });
    }

   const onClickLogin = () => {
   	show.value = false
   	router.push({name:'login'})
   }

   const onClickSignUp = () => {
   	show.value = false
   	router.push({name:'signup'})
   }
</script>

<style scoped>
	:root {
		--var_navigation-height: 73px;
	}

	.support-chat-layout {
		padding: 16px;
		padding-top: calc(var(--user-layout_padding-top) + 16px);
	}
	.support-chat {
    height: calc(100vh - (var(--var_navigation-height) * 2 + 32px));
    border: 1px solid #e7e7e7;
    right: 24px;
    bottom: 24px;
    max-width: 512px;
    border-radius: 16px;
    width: 100%;
    pointer-events: auto;
    background-color: rgb(255, 255, 255);
    z-index: 10010;
    opacity: 1;
    transform: translate(0px, 0px);
    transition: transform 300ms ease 0s, opacity 300ms ease 0s;
    visibility: visible;
    display: flex;
    flex-direction: column;
    margin: auto;
	}
	.support-chat .head {
		padding: 2px 14px;
	    font-weight: 500;
	    line-height: 1.25;
	    font-size: 1rem;
	    border: none;
	    border-bottom: 1px solid rgb(231, 231, 231);
	    height: 48px;
	}
	.chat-message-wrapper {
		display: flex;
	}
	.chat-message {
		background-color: rgb(243, 243, 243);
	    color: rgba(0, 0, 0, 0.85);
	    border-radius: 20px;
	    padding: 10px 14px;
	    font-weight: 500;
	    line-height: 1.25;
	    font-size: 1rem;
	    word-break: break-word;
	    white-space: pre-wrap;
	    box-sizing: border-box;
	}
	.message-group {
		display: flex;
		padding: 0px 12px;
	}
	.message-group-messages {
		display: flex;
		flex-flow: column;
		gap: 2px;
	}
	.message-avatar {
		display: flex;
	    align-items: flex-end;
	    max-width: 34px;
	    padding-right: 8px;
	}
	.message-avatar-wrapper {
		display: inline-flex;
	    border-radius: 50%;
	    overflow: hidden;
	    background-color: rgb(36, 28, 21);
	    color: rgba(255, 255, 255, 0.95);
	    -webkit-box-align: center;
	    align-items: center;
	    -webkit-box-pack: center;
	    justify-content: center;
	    line-height: 0;
	    font-weight: 600;
	    width: 26px;
	    height: 26px;
	    font-size: 0.875rem;
	    flex: 0 0 26px;
	}
	.support .support-chat-button {
		position: fixed;
		right: 24px;
		bottom: 24px;
		padding: 14px 20px;
	}
	.support-chat-close {
		background-color: #f6f7f7;
		width: 32px;
		height: 32px;
		border-radius: 999px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	.support-chat-close:hover {
		background: #e5e7eb;
	}
	.status-indicator {
		background: #FF2C46;
		height: 8px;
    width: 8px;
    border-radius: 50%;
	}
	.support-chat-auth {
    width: 100%;
    padding: 32px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
	}
</style>