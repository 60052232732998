<template>
	<!-- Back:Mobile -->
	<div class="mobile">
		<UiBack route="/settings" text="Back" fixed></UiBack>
	</div>
	
	<UiCard>
		<!-- Headline -->
		<UiHeadline class="mb-8" size="m">{{ $t('view.settings.profile.title') }}</UiHeadline>
	<UiCol gap="4">
		<label>
        <input
          type="file"
          multiple
          @change="handleImageUpload($event)"
          @click="$event.target.value = ''"
        />
		<UiRow align="center" gap="2">
			<UiAvatar :imgUrl="profilePicture" size="128px"></UiAvatar>
			<UiGridTile>{{ $t('view.settings.profile.change_profile_picture') }}</UiGridTile>
		</UiRow>
	</label>
		<UiCol>
			<UiLabel>{{ $t('view.settings.profile.name') }}</UiLabel>
			<UiText>{{ user.firstname }} {{ user.lastname }}</UiText>
		</UiCol>
		<UiCol>
			<UiLabel>{{ $t('view.settings.profile.username') }}</UiLabel>
			<UiText>{{ user.username }}</UiText>
		</UiCol>
		<UiCol>
			<UiLabel>{{ $t('view.settings.profile.email') }}</UiLabel>
			<UiText>{{ user.email }}</UiText>
		</UiCol>
		<UiCol>
			<UiLabel>{{ $t('view.settings.profile.address') }}</UiLabel>
			<UiText>
				{{ user.address.street1 }}<br>
				{{ user.address.postalCode }} {{ user.address.city }}<br>
				{{ user.address.state }}, {{ user.address.country }}
			</UiText>
		</UiCol>
		<UiRow>
			<UiText>
				<small>{{ $t('view.settings.profile.update_info') }}</small>
			</UiText>
		</UiRow>
	</UiCol>

	<UiCol gap="4" style="margin-top: 52px;" v-if="false">
		<UiHeadline size="m" style="margin-bottom: 16px;">Desk Manager</UiHeadline>
		<UiCol gap="2" v-if="user.manager">
			<UiRow gap="2" align="center">
				<UiAvatar :imgUrl="user.manager.profileImageUrl" size="64px"></UiAvatar>
				<div>
					<UiGridTile>{{ user.manager.fullname }}</UiGridTile>
				</div>
				<div>
					<UiGridTile>@{{ user.manager.username }}</UiGridTile>
				</div>
			</UiRow>
			<UiLabel><small>info: Reach out to your desk manager if you need any help.</small></UiLabel>
		</UiCol>
		<div v-else>
			<UiGridTile>Join a trading desk to get a manager.</UiGridTile>
		</div>
	</UiCol>
	</UiCard>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"

	const store = useStore()

	const user = computed(() => store.state.user.user)

	const newProfilePicture = ref(new FormData());
	const previewProfilePicture = ref([]);
	const profilePictureUpdated = ref(false);

	const profilePicture = computed(() =>
	  previewProfilePicture.value.length
	    ? previewProfilePicture.value[0].url
	    : user.value.profileImageUrl
	);

	const onUpdateProfilePicture = () => {
	  store
	    .dispatch("user/updateProfilePicture", {
	      files: newProfilePicture.value,
	    })
	    .then((res) => {
	      profilePictureUpdated.value = true;
	      setTimeout(() => {
	        profilePictureUpdated.value = false;
	      }, 2500);
	    });
	};

	const handleImageUpload = (e) => {
	  const newImages = [];
	  newImages.push(...e.target.files);
	  previewProfilePicture.value = Array.from(e.target.files).map((image) => ({
	    url: URL.createObjectURL(image),
	  }));
	  newImages.forEach((file) => {
	    newProfilePicture.value.append("files", file);
	  });
	  onUpdateProfilePicture();
	};
</script>

<style scoped>
	label {
		cursor: pointer;
	}
	input[type="file" i] {
		display: none;
	}
</style>