<template>
	<!-- Headline -->
	<UiHeadline style="margin-bottom: 42px;">New Report</UiHeadline>

	<Form :schema="schema"></Form>
</template>

<script setup>
	import { ref } from 'vue';

  	const schema = ref(`{
  "rows": [
    {
      "columns": [
        {
          "fields": [
            {
              "type": "date",
              "label": "Date",
              "name": "date",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "text",
              "label": "Trading Instruments",
              "name": "trading_instruments",
              "placeholder": "List the instruments you traded today (e.g., stocks, forex, commodities)",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "number",
              "label": "Total Trades",
              "name": "total_trades",
              "placeholder": "Enter the total number of trades made today"
            },
            {
              "type": "number",
              "label": "Profit/Loss",
              "name": "profit_loss",
              "placeholder": "Enter the total profit or loss for the day"
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "textarea",
              "label": "Successful Trades Analysis",
              "name": "successful_trades",
              "placeholder": "Describe the successful trades of the day and the strategies that worked"
            },
            {
              "type": "textarea",
              "label": "Unsuccessful Trades Analysis",
              "name": "unsuccessful_trades",
              "placeholder": "Describe the unsuccessful trades of the day and what didn't work"
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "textarea",
              "label": "Key Learnings and Observations",
              "name": "key_learnings",
              "placeholder": "Note any key learnings, patterns observed, or insights gained during today's trading"
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "textarea",
              "label": "Emotional and Psychological State",
              "name": "emotional_state",
              "placeholder": "Reflect on your emotional and psychological state throughout the day's trading activities"
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "textarea",
              "label": "Improvement Plans for Tomorrow",
              "name": "improvement_plans",
              "placeholder": "Outline any specific improvements or adjustments you plan to make in the next trading session"
            },
            {
              "type": "submit",
              "label": "Submit Journal Entry"
            }
          ]
        }
      ]
    }
  ]
}
`)
</script>