<template>
  <div>
    <div class="container">
      <S2 badge="About Us" title="Our Mission" text="Your donation empowers families in poverty to choose how best to improve their lives. You decide which projects and exactly with which resources you want to support.">
        <p>
          At Purdia Capital, we observed firsthand the challenges faced by traders in our proprietary trading firm. Drawing from extensive data and real-world experiences, we developed targeted tools and methods to aid struggling traders. This led to the creation of our 7-week program, a comprehensive blend of practical strategies and insights designed to elevate trading skills from struggle to success. Our mission is to transform challenges into opportunities, guiding traders towards clarity and proficiency in the dynamic world of trading.
        </p>
      </S2>
    </div>
    <!-- <S3 title="The <span>Team</span" :items="s3Items"></S3> -->
  </div>
</template>

<script setup>
  import { ref } from "vue"
  import S2 from "@/components/S2"
  import S3 from "@/components/S3"

  const s3Items = ref([
    {
      icon: "",
      title: "Jonas",
      text: "CEO & Coach<br> We are engaged in a battle against hunger and those who exploit it for their gain."
    },
    {
      icon: "",
      title: "Frank",
      text: "COO & Coach<br>You can see where your money goesTrack every single transaction of our organization"
    },
    {
      icon: "",
      title: "Yaw",
      text: "CFO & Risk manager<br>You can see where your money goesTrack every single transaction of our organization"
    },
    {
      icon: "",
      title: "Daniel",
      text: "Coach<br>You can see where your money goesTrack every single transaction of our organization"
    },
    {
      icon: "",
      title: "Brandon",
      text: "Coach<br>You can see where your money goesTrack every single transaction of our organization"
    },
    {
      icon: "",
      title: "Mike",
      text: "Customer Service<br>You can see where your money goesTrack every single transaction of our organization"
    },
    {
      icon: "",
      title: "Andrew",
      text: "Customer Service<br>You can see where your money goesTrack every single transaction of our organization"
    },
    {
      icon: "",
      title: "Peldam",
      text: "Tech<br>You can see where your money goesTrack every single transaction of our organization"
    },
    {
      icon: "",
      title: "Eniola",
      text: "Design<br>You can see where your money goesTrack every single transaction of our organization"
    }
  ])
</script>