<template>
	<UiHeadline class="mb-2" size="m">
		<UiRow v-if="fetching && !model.name">
			<UiSkeleton height="30.5"></UiSkeleton>
		</UiRow>
		<template v-else-if="model.name">
			{{ model.name }}
		</template>
		<template v-else>
			Undefined
		</template>
	</UiHeadline>
	<div class="desktop mb-8">
		<UiBack :route="`/trading/accounts/${route.params.accountId}/list/${route.params.listKey}`" text="Back"></UiBack>
	</div>

	<Form :schema="schema" v-model="model" @submit="submit" :submitting="saving"></Form>
</template>

<script setup>
	import { ref } from "vue"
	import { useStore } from "vuex"
  import { useRoute } from "vue-router"
  import formSchemas from "@/json/forms"

  const store = useStore()
  const route = useRoute()

	const model = ref({})
	const saving = ref(false)
	const fetching = ref(false)

	const fetch = () => {
		fetching.value = true
		store.dispatch('dashboard/getItem', {
	    listKey: route.params.listKey,
	    itemId: route.params.itemId,
	    params: {
        refId: route.params.accountId
      }
	  }).then(res => {
	    fetching.value = false
	    model.value = res.data.item
	  }).catch(() => {
	    fetching.value = false
	  })
	}

	fetch()

	const submit = () => {
		if (saving.value) return
    saving.value = true
    store.dispatch('dashboard/updateItem', {
      listKey: route.params.listKey,
	    itemId: route.params.itemId,
	    params: {
        refId: route.params.accountId
      },
      data: model.value
    }).then(res => {
      saving.value = false
    }).catch(() => {
      saving.value = false
    })
	}

	const schema = JSON.stringify(formSchemas[`trading-${route.params.listKey}`])
</script>