import axios from "axios";
// import cookie from "@/services/cookies";

// const accessToken = cookie.get("access_token");

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      // cookie.remove("access_token");
      window.location = "/login"
    }

    if (
      err.response.data.errors.error &&
      err.response.data.errors.error.code === "permission_denied"
    ) {
      return (window.location = "/app");
    }

    return Promise.reject(err);
  }
);

// if (accessToken) {
//   instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
// }

export default instance;
