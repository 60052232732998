<template>
	<div class="calendly-inline-widget" data-url="https://calendly.com/purdia-capital/7-week-trading-mastery?hide_gdpr_banner=1" style="min-width:320px;height:700px;"></div>
</template>

<script setup>
	import { onMounted } from "vue"

	onMounted(() => {
		const recaptchaScript = document.createElement('script')
	    recaptchaScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
	    document.head.appendChild(recaptchaScript)
	})
</script>