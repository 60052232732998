<template>
  <div class="container">
  	<div class="user-nav nav">
      <UiRow align="center" justify="center" gap="4">
        <a href="https://purdia.com/evaluation" target="_blank">
          <img src="https://cdn.purdia.com/images/logo.svg" style="height: 27px;">
        </a>
      </UiRow>
      </div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance } from "vue"
  import { Chart, Clock } from "@lib/components"
  import ClockAnalog from "@/components/ClockAnalog"
  import TradingMarketOpen from "@/components/TradingMarketOpen"

  const instance = getCurrentInstance();
  const t = instance.appContext.config.globalProperties.$t;

  const props = defineProps({
    items: Array
  })

  const accountDropdownItems = ref([
    {
      name: t('navigation.my_account.profile'),
      route: "/profile"
    },
    // {
    //   name: t('navigation.my_account.bonus'),
    //   route: "/bonus"
    // },
    {
      name: t('navigation.my_account.settings'),
      route: "/settings"
    },
    {
      name: t('navigation.my_account.sign_out'),
      route: "/signout"
    }
  ])
</script>

<style scoped>
  .nav-wrapper {
    position: relative;
  }
  .user-nav {
    height: var(--var_navigation-height);
    box-shadow: var(--user-navigation_shadow);
    border-bottom: var(--user-navigation_border-bottom);
    position: var(--user-navigation_position);
    border-radius: var(--user-navigation_border-radius);
    top: 0px;
    left: 0px;
    padding-left: 256px;
    z-index: 16;
    background: var(--user-navigation_background);
    backdrop-filter: var(--user-navigation_backdrop-filter);
  }

  .nav {
    z-index: 16;
    width: 100%;
    max-width: var(--user-navigation_width);
    margin: auto;
    padding: var(--user-navigation_padding);
    padding-top: var(--user-navigation_padding-top);
  }

  .nav ul {
    color: var(--nav-item_color);
    font-size: var(--user-navigation_font-size);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.38px;
    gap: 24px;
  }

  .nav ul li {
    display: flex;
    align-items: center;
  }

  .nav ul a {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--nav-item_color);
    border-radius: var(--nav-item_border-radius);
    padding: var(--nav-item_padding);
  }

  .nav ul a:hover {
    background: #f0f0f0;
  }

  .nav ul li a.router-link-active {
    border-bottom: var(--nav-item-active_border-bottom);
    color: var(--nav-item-active_color);
    background: var(--nav-item-active_background);
  }

  .nav ul li a.highlighted {
    background: linear-gradient(315deg, rgb(0, 153, 255) 0%, var(--token-ee053477-e115-4fec-a5f5-cdc637ed6ddc, rgb(0, 204, 255)) /* {"name":"Sky"} */ 100%);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.286) 0px 0.722625px 0.722625px -1.25px, rgba(0, 0, 0, 0.255) 0px 2.74624px 2.74624px -2.5px, rgba(0, 0, 0, 0.1) 0px 12px 12px -3.75px;
    opacity: 1;
    color: #fff;
  }
  
  .nav ul li a.highlighted .ui-badge-white {
    background: #fff;
    color: #06b0ff;
    border-radius: 8px;
  }

  .nav ul li a.highlighted.router-link-active {
    border-bottom: var(--nav-item-active_border-bottom);
    color: var(--nav-item-active_color);
    background: var(--nav-item-active_background);
    box-shadow: none;
  }

  .nav .nav-menu {
    display: none;
  }

  .nav .user-item-avatar {
    outline: 2px solid var(--user-navigation_background);
  }

  @media (min-width: 768px) {
    .nav .nav-menu {
      display: flex;
    }
  }

  .nav-widget-title {
    color: var(--nav-widget-title_color);
  }

  .nav-item-icon {
    background: linear-gradient(315deg,#205dff 0%,#20c4ff 100%);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
</style>