<template>
	<UiHeadline class="mb-2" size="m">Playbook</UiHeadline>
	<div class="desktop mb-8">
		<UiBack :route="`/trading/accounts/${route.params.accountId}/playbooks`" text="All Playbooks"></UiBack>
	</div>

	<Form :schema="schema" v-model="model" @submit="submit" :submitting="saving"></Form>
</template>

<script setup>
	import { ref } from "vue"
	import { useStore } from "vuex"
  import { useRoute } from "vue-router"

  const store = useStore()
  const route = useRoute()

	const model = ref({})
	const saving = ref(false)
	const fetching = ref(false)

	const fetch = () => {
		fetching.value = true
		store.dispatch('dashboard/getPlaybook', {
	    accountId: route.params.accountId,
	    playbookId: route.params.playbookId,
	  }).then(res => {
	    fetching.value = false
	    model.value = res.data.playbook
	  }).catch(() => {
	    fetching.value = false
	  })
	}

	fetch()

	const submit = () => {
		if (saving.value) return
    saving.value = true
    store.dispatch('dashboard/updatePlayBook', {
      accountId: route.params.accountId,
      playbookId: route.params.playbookId,
      data: model.value
    }).then(res => {
      saving.value = false
    }).catch(() => {
      saving.value = false
    })
	}

	const schema = ref(`{
	  "rows": [
	  	{
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "text",
	              "label": "Name",
	              "name": "name",
	              "placeholder": ""
	            }
	          ]
	        }
	      ]
	    },
	    {
	      "columns": [
	        {
	          "fields": [
	            {
	              "type": "textarea",
	              "label": "Overview",
	              "name": "playbook",
	              "placeholder": ""
	            }
	          ]
	        }
	      ]
	    },{
      "columns": [
        {
          "fields": [
            {
              "type": "submit",
              "label": "Save Playbook"
            }
          ]
        }
      ]
    }]}`)
</script>